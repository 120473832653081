/**
 * @generated SignedSource<<fa3a2fc178a774b264fbdd36232cacb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrganizationRole = "owner" | "admin" | "member" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminMemberGroupTableRowFragment$data = {
  readonly id: string;
  readonly organizationMembership: {
    readonly role: OrganizationRole;
    readonly email: string | null;
  };
  readonly productMembership: {
    readonly role: ProductRole;
    readonly email: string | null;
  } | null;
  readonly user: {
    readonly id: string;
    readonly fullName: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
  };
  readonly " $fragmentType": "AdminMemberGroupTableRowFragment";
};
export type AdminMemberGroupTableRowFragment$key = {
  readonly " $data"?: AdminMemberGroupTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminMemberGroupTableRowFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminMemberGroupTableRowFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "organizationMembership",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "productMembership",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarWithDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MemberGroupMembership",
  "abstractKey": null
};
})();

(node as any).hash = "ac743f9fa37d283899a479ece98df12d";

export default node;
