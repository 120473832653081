import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DeleteSubGroupButton from "@/product/common/member-group/common/edit/button/DeleteSubGroupButton"
import EditMemberGroupButton from "@/product/common/member-group/common/edit/button/EditMemberGroupButton"
import { MemberGroupMoreActionsDropdownFragment$key } from "@/product/common/member-group/common/more-actions-dropdown/__generated__/MemberGroupMoreActionsDropdownFragment.graphql"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useTheme } from "@material-ui/core/styles"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import classNames from "classnames"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  groupKey: MemberGroupMoreActionsDropdownFragment$key
  testid?: string
  classes?: {
    moreActionsButton?: string
  }
}

const MemberGroupMoreActionsDropdown: React.FC<Props> = (props) => {
  const { groupKey, testid, classes: propsClasses } = props
  const theme = useTheme()
  const classes = useStyles()
  const permissions = useActiveProductOrOrganizationPermissions()

  const memberGroup = useFragment<MemberGroupMoreActionsDropdownFragment$key>(
    graphql`
      fragment MemberGroupMoreActionsDropdownFragment on MemberGroup {
        id
        kind
        ...EditMemberGroupButtonFragment
        ...DeleteSubGroupButtonFragment
      }
    `,
    groupKey
  )

  if (!permissions.has("member_groups.manage")) return null

  return (
    <DiscoMoreActionsDropdown
      moreActionsButtonClassName={classNames(propsClasses?.moreActionsButton)}
      dropdownContainerClassName={classes.moreActionsDropdownContainer}
      testid={`${testid}.more-actions`}
      top={theme.spacing(3.5)}
    >
      <EditMemberGroupButton groupKey={memberGroup} isSubGroup>
        {(buttonProps) => (
          <DiscoDropdownItem
            {...buttonProps}
            title={"Edit"}
            testid={`${testid}.EditMemberGroupButton`}
          />
        )}
      </EditMemberGroupButton>
      {memberGroup.kind !== "default" && (
        <DeleteSubGroupButton groupKey={memberGroup}>
          {(buttonProps) => (
            <DiscoDropdownItem
              {...buttonProps}
              title={"Delete"}
              testid={`${testid}.EditMemberGroupButton`}
            />
          )}
        </DeleteSubGroupButton>
      )}
    </DiscoMoreActionsDropdown>
  )
}
const useStyles = makeUseStyles((theme) => ({
  moreActionsDropdownContainer: {
    padding: theme.spacing(1),
    width: "132px",
    "& > ul > li": {
      padding: theme.spacing(1),
      borderRadius: theme.measure.borderRadius.medium,
      justifyContent: "flex-start",
      width: "100%",
    },
  },
}))

export default MemberGroupMoreActionsDropdown
