import { useLabel } from "@/core/context/LabelsContext"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import useTrackCloneJobStarted from "@/product/clone/hooks/useTrackCloneJobStarted"
import {
  BadgeInput,
  CloneCurriculumContentModuleUsageInput,
  CloneOccurrenceOption,
  CloneProductModalContextMutation,
} from "@/product/clone/sequence/__generated__/CloneProductModalContextMutation.graphql"
import {
  CloneProductModalContextQuery,
  ProductAppKind,
} from "@/product/clone/sequence/__generated__/CloneProductModalContextQuery.graphql"
import { CloneProductModalContext_CanCloneEventsFragment$key } from "@/product/clone/sequence/__generated__/CloneProductModalContext_CanCloneEventsFragment.graphql"
import CloneCurriculumModalStepDueDates from "@/product/clone/sequence/modal/curriculum-modules/CloneCurriculumModalStepDueDates"
import CloneProductModalStepCurriculumModules from "@/product/clone/sequence/modal/curriculum-modules/CloneProductModalStepCurriculumModules"
import CloneProductModalStepEvents from "@/product/clone/sequence/modal/events/CloneProductModalStepEvents"
import CloneProductModalStepGeneralSettings from "@/product/clone/sequence/modal/general-settings/CloneProductModalStepGeneralSettings"
import CloneProductModalStepSuccess from "@/product/clone/sequence/modal/success/CloneProductModalStepSuccess"
import CloneProductFormUtils from "@/product/clone/utils/cloneProductFormUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { addDays, format } from "date-fns"
import React, { useContext } from "react"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export type CloneProductModalKind =
  | "general_settings"
  | "curriculum_modules"
  | "item_due_dates"
  | "events"
  | "success"

export type CloneProductInput = CloneProductModalContextMutation["variables"]["input"]

export type CloneCurriculumContentModuleUsage = CloneCurriculumContentModuleUsageInput & {
  name: string
  contentId: GlobalID
}
export type CloneCourseEvent = Pick<
  CloneOccurrenceOption,
  "duration" | "startDatetime" | "timezone"
> & {
  eventId: GlobalID
  occurrenceId: GlobalID
  name: string
}

type CloneProductFormStoreState = {
  /** The product to be cloned */
  sourceProduct: CloneProductModalContextQuery["response"]["sourceProduct"]
  badge: BadgeInput
  step?: CloneProductModalKind
  isCalendarOpen: boolean
  includeCurriculum: boolean
  includeEvents: boolean
  includeChatChannels: boolean
  canCloneEvents: boolean
  connectedApps: Set<ProductAppKind>
  /** when set, open EditModuleDetails modal */
  editModuleSourceId?: GlobalID
  /** when set, open EditEventDetails modal */
  editOccurrenceSourceId?: GlobalID
} & Omit<CloneProductInput, "curriculumContentModuleUsages" | "events"> & {
    curriculumContentModuleUsages?: CloneCurriculumContentModuleUsage[]
    events?: CloneCourseEvent[]
  }

export type ActiveCloneProductModalContextValue = {
  form: FormStore<CloneProductFormStoreState, CloneProductModalContextMutation>
  isOpen: boolean
  handleNext: (nextStep?: CloneProductModalKind) => void
  handleClose: () => void
}

const ActiveCloneProductModalContext =
  React.createContext<ActiveCloneProductModalContextValue | null>(null)

export function useActiveCloneProductModalContext(
  modalKind?: CloneProductModalKind
): ActiveCloneProductModalContextValue | null {
  const context = useContext(ActiveCloneProductModalContext)
  if (!context) return null
  return {
    ...context,
    isOpen: modalKind ? context.form.state.step === modalKind : false,
  }
}

interface Props {
  productId: GlobalID
  onClose: () => void
}

function CloneProductModalProviderContent(props: Props) {
  const { productId, onClose } = props
  const { sourceProduct } = useLazyLoadQuery<CloneProductModalContextQuery>(
    graphql`
      query CloneProductModalContextQuery($id: ID!) {
        sourceProduct: node(id: $id) {
          id
          ... on Product {
            name
            startDate
            badge {
              kind
              icon
              color
              emoji
              mediaUrl
            }
            productApps(includeNested: true) {
              edges {
                node {
                  id
                  kind
                }
              }
            }
            curriculum {
              id
              ...CloneProductModalStepCurriculumModulesFragment
              ...CloneCurriculumModalStepDueDatesFragment
            }
            ...CloneProductModalStepEventsFragment
            ...CloneProductModalContext_CanCloneEventsFragment
          }
        }
      }
    `,
    { id: productId },
    // Refetch each time to get the freshest data for a clone.
    { fetchPolicy: "network-only" }
  )

  const trackCloneJobStarted = useTrackCloneJobStarted()
  const connectedApps = new Set(
    Relay.connectionToArray(sourceProduct?.productApps).map((a) => a.kind)
  )
  const experienceLabel = useLabel("experience")
  const canCloneEvents = useCanCloneEvents(sourceProduct)
  const form = useFormStore<CloneProductModalContextMutation, CloneProductFormStoreState>(
    graphql`
      mutation CloneProductModalContextMutation($input: CloneCourseInput!) {
        response: cloneCourse(input: $input) {
          node {
            id
            destinationProduct {
              id
              name

              type
              slug
              status
              description
              registrationAvailability
              registrationType
              capacity
              startDate
              endDate
              badge {
                kind
                icon
                color
                emoji
                mediaUrl
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      productId,
      name: `${sourceProduct?.name || experienceLabel.singular} - Copy`,
      startDate: sourceProduct?.startDate
        ? format(addDays(new Date(), 7), "yyyy-MM-dd")
        : null,
      canCloneEvents,
      connectedApps,
      // includeCurriculum is only used to determine next step in modal sequence (not passed to mutation)
      includeCurriculum: connectedApps.has("curriculum"),
      // includeEvents will only be used to determine next step in modal sequence (not passed to mutation)
      includeEvents: canCloneEvents && connectedApps.has("events"),
      includeCollections: connectedApps.has("collection"),
      includeManagers: true,
      includeInstructors: true,
      includeChatChannels: connectedApps.has("chat_channel"),
      events: [],
      // curriculumContentModuleUsages should be used to determine whether or not to clone the curriculum
      curriculumContentModuleUsages: [],
      sourceProduct,
      step: "general_settings",
      isCalendarOpen: false,
      badge: sourceProduct!.badge!,
    }
  )

  return (
    <ActiveCloneProductModalContext.Provider
      value={{
        form,
        isOpen: false,
        handleNext,
        handleClose: onClose,
      }}
    >
      <CloneProductModalStepGeneralSettings />
      <CloneProductModalStepCurriculumModules
        curriculumKey={sourceProduct?.curriculum || null}
      />
      <CloneCurriculumModalStepDueDates
        curriculumKey={sourceProduct?.curriculum || null}
      />
      <CloneProductModalStepEvents eventKey={sourceProduct} />
      <CloneProductModalStepSuccess />
    </ActiveCloneProductModalContext.Provider>
  )

  async function handleNext(nextStep?: CloneProductModalKind) {
    if (!form.state.step || nextStep === undefined) {
      onClose()
      return
    }
    // if we can catch any errors early (prior to submit), do so
    if (!CloneProductFormUtils.validate(form)) return
    if (nextStep === "success") {
      const success = await handleSubmit()
      if (!success) return
    }
    form.state.step = nextStep
  }

  async function handleSubmit() {
    const input = CloneProductFormUtils.formatInput(form)
    const { didSave, response } = await form.submit(input)
    if (!didSave || !response?.node) return false
    // Start tracking the clone job status
    trackCloneJobStarted(response.node.id)
    return true
  }
}

export const CloneProductModalProvider = Relay.withSkeleton({
  component: CloneProductModalProviderContent,
  skeleton: () => null,
})

/** Return true if the product's events all have valid MeetingProviders to be cloned */
function useCanCloneEvents(
  productKey: CloneProductModalContext_CanCloneEventsFragment$key | null
): boolean {
  const product = useFragment<CloneProductModalContext_CanCloneEventsFragment$key>(
    graphql`
      fragment CloneProductModalContext_CanCloneEventsFragment on Product {
        allOccurrences {
          edges {
            node {
              id
              videoRoomType
              meetingProvider {
                zoomConnection {
                  details {
                    hasWebinarAddon
                  }
                }
              }
            }
          }
        }
      }
    `,
    productKey
  )

  const occurrences = Relay.connectionToArray(product?.allOccurrences)
  return occurrences.every((o) => {
    // Custom link event is always fine.
    if (o.videoRoomType === "custom-link" || o.videoRoomType === "in-person") return true
    // TO DO: change the way we validate zoom connection so we can deprecate 'details' field
    if (!o.meetingProvider?.zoomConnection.details) return false
    // Broadcast events must have the webinar addon available
    if (
      o.videoRoomType === "broadcast" &&
      !o.meetingProvider.zoomConnection.details.hasWebinarAddon
    )
      return false
    return true
  })
}
