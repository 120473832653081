import FormStore from "@/core/form/store/FormStore"
import AdminMemberGroupTableRow, {
  AdminMemberGroupTableRowSkeleton,
} from "@/product/common/member-group/common/AdminMemberGroupTableRow"
import MemberGroupMembershipMultiSelect from "@/product/common/member-group/common/MemberGroupMembershipMultiSelect"
import MembershipTypeFilter, {
  MEMEBERSHIP_TYPE_OPTIONS,
  MemberGroupTableToolbarState,
} from "@/product/common/member-group/common/MembershipTypeFilter"
import { AdminMemberGroupTableQuery$data } from "@/product/common/member-group/common/__generated__/AdminMemberGroupTableQuery.graphql"
import { UnassignedMemberGroupTableQuery$data } from "@/product/common/member-group/common/__generated__/UnassignedMemberGroupTableQuery.graphql"
import { CreateMemberGroupFormState } from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import { EditMemberGroupFormState } from "@/product/common/member-group/util/MemberGroupUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoEmptyState,
  DiscoSection,
  DiscoTableSkeletonWithHeader,
  DiscoText,
} from "@disco-ui"
import { DiscoTableFilterDropdownSkeleton } from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { DiscoTableSearchInputSkeleton } from "@disco-ui/table/header/search/DiscoTableSearchInput"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { Fragment, useMemo, useState } from "react"

interface GroupFormTableProps {
  form: FormStore<CreateMemberGroupFormState> | FormStore<EditMemberGroupFormState>
  memberGroup:
    | AdminMemberGroupTableQuery$data["memberGroup"]
    | UnassignedMemberGroupTableQuery$data["memberGroup"]
}

function GroupFormTable({ form, memberGroup }: GroupFormTableProps) {
  const [toolbarState, setToolbarState] = useState<MemberGroupTableToolbarState>({
    selectedType: MEMEBERSHIP_TYPE_OPTIONS[0],
  })
  const isMobile = useIsMobile()
  const classes = useStyles()

  const membershipsByUserId = useMemo(() => {
    const memberships = Relay.connectionToArray(memberGroup?.memberGroupMemberships)
    return ArrayUtils.mapBy(memberships, "userId")
  }, [memberGroup?.memberGroupMemberships])

  return (
    <DiscoSection className={classes.section}>
      <header className={classes.header}>
        <MemberGroupMembershipMultiSelect
          memberGroup={memberGroup}
          // Don't show selected members in the input/dropdown, only in the table
          values={[]}
          hideUserIds={toJS(form.state.selectedUserIds)}
          onChange={handleChangeMemberships}
        />

        <MembershipTypeFilter
          filterState={toolbarState}
          setFilterState={(newFilterState) =>
            setToolbarState({ ...toolbarState, ...newFilterState })
          }
        />
      </header>
      <div className={classes.tableHeader}>
        <DiscoText variant={"body-xs-600"}>{"Member"}</DiscoText>
        {!isMobile && <DiscoText variant={"body-xs-600"}>{"Role"}</DiscoText>}
        <DiscoText variant={"body-xs-600"}>{"Action"}</DiscoText>
      </div>
      <div className={classes.list}>
        {form.state.selectedUserIds.length > 0 ? (
          form.state.selectedUserIds.map((userId, index) => {
            const membership = membershipsByUserId[userId]
            if (!membership) return <Fragment key={userId} />
            return (
              <AdminMemberGroupTableRow
                key={userId}
                filter={toolbarState.selectedType?.value}
                membershipKey={membership}
                removeMember={() => removeMember(userId)}
                index={index}
              />
            )
          })
        ) : (
          <DiscoEmptyState
            testid={""}
            title={"No members in this group!"}
            subtitle={"Search and add in new members."}
          />
        )}
      </div>
    </DiscoSection>
  )

  function removeMember(userId: GlobalID) {
    form.state.selectedUserIds.replace(
      form.state.selectedUserIds.filter((id) => userId !== id)
    )
  }

  function handleChangeMemberships(memberships: GlobalID[]) {
    if (!memberships[0]) return
    form.state.selectedUserIds.push(memberships[0])
  }
}

const useStyles = makeUseStyles((theme) => ({
  section: {
    boxShadow: theme.palette.groovyDepths.insideCard,
    height: "340px",
    width: "100%",
    maxHeight: "340px",
    overflowY: "scroll",
    padding: theme.spacing(0, 2),
  },
  list: {
    marginTop: theme.spacing(1),
  },
  header: {
    display: "grid",
    gridTemplateColumns: "4fr 1fr",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  tableHeader: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "3fr 2fr 0fr",
    [theme.breakpoints.down("sm")]: {
      gridAutoColumns: "1fr 0fr",
    },
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.default,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],
  },
}))

export function GroupFormTableSkeleton() {
  const classes = useStyles()
  return (
    <DiscoTableSkeletonWithHeader
      customClasses={{ section: classes.section }}
      rows={10}
      row={<AdminMemberGroupTableRowSkeleton />}
      header={[{ value: "Members" }, { value: "Role" }, { value: "Action" }]}
      headerButtons={
        <>
          <div>
            <DiscoTableSearchInputSkeleton />
          </div>
          <DiscoTableFilterDropdownSkeleton />
        </>
      }
    />
  )
}

export default observer(GroupFormTable)
