import EventFeedbackModal from "@/occurrence/feedback/modal/EventFeedbackModal"
import {
  haveAnHourToOccurrence,
  isOccurrenceLive,
} from "@/occurrence/util/occurrenceUtils"
import { EventFeedbackButtonQuery } from "@/product/course/occurrence/__generated__/EventFeedbackButtonQuery.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoButtonSkeleton } from "@disco-ui"
import { isProduction } from "@utils/globalVariables"
import useDisclosure from "@utils/hook/useDisclosure"
import useInterval from "@utils/hook/useInterval"
import usePermissions from "@utils/hook/usePermissions"
import { removeSearchParams, useQueryParams } from "@utils/url/urlUtils"
import { isPast } from "date-fns"
import { useEffect, useState } from "react"
import { commitLocalUpdate, graphql, useLazyLoadQuery } from "react-relay"
import { useHistory, useLocation } from "react-router-dom"

/** On dev/demo, display the feedback modal right away */
export const FEEDBACK_MODAL_TIMEOUT_MS = isProduction() ? 600000 : 0 // 10 minutes

interface EventFeedbackButtonProps {
  testid?: string
  occurrenceId: GlobalID
  className?: string
}

function EventFeedbackButton({
  testid = "EventFeedbackButton",
  occurrenceId,
  className,
}: // needed to prevent more than one modal being open at once
EventFeedbackButtonProps) {
  const location = useLocation()
  const history = useHistory()
  const { fb } = useQueryParams<{ fb?: string }>()
  const { isOpen, onOpen, onClose } = useDisclosure(fb === "1")

  const { node } = useLazyLoadQuery<EventFeedbackButtonQuery>(
    graphql`
      query EventFeedbackButtonQuery($id: ID!) {
        node(id: $id) {
          ... on Occurrence {
            id
            __typename
            eventId
            datetimeRange
            collectFeedback
            didViewerAttend
            hasViewerSubmittedFeedback
            hasViewerRSVPd
            videoRoomType
            event {
              ...usePermissionsFragment
            }
          }
        }
      }
    `,
    { id: occurrenceId }
  )
  const occurrence = Relay.narrowNodeType(node, "Occurrence")
  const permissions = usePermissions(occurrence?.event)
  const canGiveFeedback = getCanGiveFeedback()

  const [eventState, setEventState] = useState<{
    isLive: boolean
    isEnded: boolean
  } | null>(null)

  useInterval(
    () =>
      setEventState(
        occurrence
          ? {
              isLive:
                haveAnHourToOccurrence(occurrence?.datetimeRange) ||
                isOccurrenceLive(occurrence?.datetimeRange),
              isEnded: isPast(new Date(occurrence.datetimeRange[1])),
            }
          : null
      ),
    5000,
    {
      shouldRunCallbackAtStart: true,
      shouldStartInterval: canGiveFeedback,
    }
  )

  const classes = useStyles()

  useEffect(() => {
    if (fb === "1") onOpen()
  }, [fb, onOpen])

  const isFeedbackPeriod = eventState?.isLive || eventState?.isEnded

  // Only display the feedback modal for learners who haven't submitted their feedback
  if (!occurrence || !canGiveFeedback || !isFeedbackPeriod) return null

  return (
    <>
      <div className={classes.container}>
        <DiscoButton
          testid={testid}
          className={className}
          onClick={handleOpenModal}
          color={"grey"}
          variant={"outlined"}
          classes={{ root: classes.root }}
        >
          {"Give Feedback"}
        </DiscoButton>
      </div>

      <EventFeedbackModal
        isOpen={isOpen}
        onClose={handleClose}
        occurrenceId={occurrence.id}
        onSubmitFeedback={handleOnSubmitFeedback}
      />
    </>
  )

  function handleOpenModal(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    onOpen()
  }
  function handleClose() {
    // cleanup the modal search params
    history.replace({
      ...location,
      search: removeSearchParams(location.search, ["fb"]),
    })
    onClose()
  }
  function handleOnSubmitFeedback() {
    commitLocalUpdate(RelayEnvironment, (store) => {
      const o = store.get(occurrenceId)
      if (!o) return
      o.setValue(true, "hasViewerSubmittedFeedback")
    })
    handleClose()
  }

  function getCanGiveFeedback(): boolean {
    if (
      permissions.has("events.manage") ||
      !occurrence?.collectFeedback ||
      occurrence.hasViewerSubmittedFeedback
    )
      return false
    // We can't reliably track attendance for in-person events, so only require RSVP in that case
    if (occurrence.videoRoomType === "in-person" && occurrence.hasViewerRSVPd) return true
    return occurrence.didViewerAttend
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  root: {
    width: "100%",
    maxWidth: 255,
  },
}))

export default Relay.withSkeleton<EventFeedbackButtonProps>({
  component: EventFeedbackButton,
  skeleton: DiscoButtonSkeleton,
})
