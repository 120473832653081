import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const MemberGroupDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "member-group-drawer-content" */ "@/product/common/member-group/common/member-group-drawer/MemberGroupDrawerContent"
    )
)

export default function MemberGroupDrawer() {
  const drawer = useGlobalDrawer("memberGroup")
  const classes = useStyles()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      onClose={drawer.close}
      size={"large"}
      containerClasses={{ drawerContainer: classes.root }}
    >
      <MemberGroupDrawerContent />
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
  },
}))
