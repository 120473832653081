/**
 * @generated SignedSource<<7c7db25cc0e7adc947425da47261f3a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductRegistrationType = "closed" | "open" | "application" | "waitlist" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CheckoutFormRegistrationErrorStep_Product$data = {
  readonly type: ProductType;
  readonly slug: string;
  readonly landingPageUrl: string | null;
  readonly registrationType: ProductRegistrationType;
  readonly waitlistUrl: string | null;
  readonly waitlistCtaLabel: string | null;
  readonly viewerMembership: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "CheckoutFormRegistrationErrorStep_Product";
};
export type CheckoutFormRegistrationErrorStep_Product$key = {
  readonly " $data"?: CheckoutFormRegistrationErrorStep_Product$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutFormRegistrationErrorStep_Product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckoutFormRegistrationErrorStep_Product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistCtaLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "66a7ada39df8fdf7a3622a525a5ec363";

export default node;
