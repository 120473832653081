import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CopyIcon from "@/core/ui/iconsax/linear/copy.svg"
import {
  useTrackCloneJobStartedQuery,
  useTrackCloneJobStartedQuery$data,
} from "@/product/clone/hooks/__generated__/useTrackCloneJobStartedQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoButton } from "@disco-ui"
import { DiscoFloatingBarContent } from "@disco-ui/floating-bar/DiscoFloatingBar"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

function useTrackCloneJobStarted() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const experienceLabel = useLabel("experience")

  const trackCloneJobStarted = useCallback(
    (cloneJobId: GlobalID) => {
      return new Promise(async () => {
        if (!cloneJobId) {
          return
        }

        let response: useTrackCloneJobStartedQuery$data | undefined

        let i = 0
        // 10 mintue threshold (5 second interval)
        while (i < 120) {
          // Poll for the clone job status
          response = await Relay.runQuery<useTrackCloneJobStartedQuery>(
            graphql`
              query useTrackCloneJobStartedQuery($id: ID!) {
                cloneJob: node(id: $id) {
                  ... on CloneJob {
                    kind
                    status
                    destinationProduct {
                      name
                      slug
                      organization {
                        ...ProductsSidebar_NavSectionsFragment
                        ...ProductsSidebarList_OrganizationFragment
                      }
                    }
                    destinationContentUsage {
                      id
                      entity
                      entityId
                      content {
                        name
                      }
                    }
                  }
                }
              }
            `,
            { id: cloneJobId },
            { fetchPolicy: "network-only" }
          )
          i++

          if (
            (response?.cloneJob?.destinationProduct ||
              response?.cloneJob?.destinationContentUsage) &&
            (response?.cloneJob?.status === "success" ||
              response?.cloneJob?.status === "error")
          ) {
            break
          }

          // Wait 5 seconds before trying again
          // eslint-disable-next-line no-new
          await new Promise((delayResolve) => setTimeout(delayResolve, 5000))
        }
        const course = response?.cloneJob?.destinationProduct
        const contentModuleUsage = response?.cloneJob?.destinationContentUsage
        if (!course && !contentModuleUsage) return
        // Show a snackbar with a link to the new course/curriculum section
        // Default message is unused
        const props = generateProps(response!)
        enqueueSnackbar("", {
          persist: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          content: (key) => (
            <DiscoFloatingBarContent
              key={key}
              onClose={() => closeSnackbar(key)}
              icon={<CopyIcon />}
              content={props?.content}
              buttons={
                <DiscoButton
                  testid={"TrackCloneJob.view-button"}
                  to={props?.to}
                  onClick={() => closeSnackbar(key)}
                >
                  {props.buttonTitle}
                </DiscoButton>
              }
            />
          ),
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return trackCloneJobStarted

  function generateProps(node: useTrackCloneJobStartedQuery$data) {
    switch (node.cloneJob?.kind) {
      case "experience": {
        const course = node.cloneJob.destinationProduct!
        return {
          content: `${course.name} was successfully duplicated!`,
          to: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug: course.slug,
          }),
          buttonTitle: `View ${experienceLabel.singular}`,
        }
      }
      case "content_module_usage": {
        const contentModuleUsage = node.cloneJob.destinationContentUsage!
        const course = node.cloneJob.destinationProduct!

        switch (contentModuleUsage.entity) {
          case "curriculum":
            return {
              content: `${contentModuleUsage.content.name} was successfully duplicated in ${course.name}!`,
              to: generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.MODULE, {
                productSlug: course.slug,
                contentModuleUsageId: contentModuleUsage.id,
              }),
              buttonTitle: "View Module",
            }
          case "collection":
            return {
              content: `${contentModuleUsage.content.name} was successfully duplicated in ${course.name}!`,
              to: generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.DETAIL, {
                productSlug: course.slug,
                collectionId: contentModuleUsage.entityId,
              }),
              buttonTitle: "View Module",
            }
        }
        throw new Error("Unknown content instance clone job")
      }
      default: {
        throw new Error("Unknown clone job")
      }
    }
  }
}

export default useTrackCloneJobStarted
