/**
 * @generated SignedSource<<e6963be047c126d29552407b25e981ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloneProductModalStepEventsFragment$data = {
  readonly allOccurrences: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly eventId: string;
        readonly name: string | null;
        readonly datetimeRange: ReadonlyArray<string>;
        readonly timezone: string;
      };
    }>;
  };
  readonly " $fragmentType": "CloneProductModalStepEventsFragment";
};
export type CloneProductModalStepEventsFragment$key = {
  readonly " $data"?: CloneProductModalStepEventsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CloneProductModalStepEventsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CloneProductModalStepEventsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceNodeConnection",
      "kind": "LinkedField",
      "name": "allOccurrences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Occurrence",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eventId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "datetimeRange",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timezone",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "a60330cca1125aa8af17db781c55b5f7";

export default node;
