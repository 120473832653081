import { useFormStore } from "@/core/form/store/FormStore"
import { DeleteSubGroupButtonFragment$key } from "@/product/common/member-group/common/edit/button/__generated__/DeleteSubGroupButtonFragment.graphql"
import { DeleteSubGroupButtonMutation } from "@/product/common/member-group/common/edit/button/__generated__/DeleteSubGroupButtonMutation.graphql"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  groupKey: DeleteSubGroupButtonFragment$key
  children: OverridableDiscoButtonChildren
}

const DeleteSubGroupButton: React.FC<Props> = (props) => {
  const { children, groupKey } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const memberGroup = useFragment<DeleteSubGroupButtonFragment$key>(
    graphql`
      fragment DeleteSubGroupButtonFragment on MemberGroup {
        id
        name
        productId
        parentMemberGroupId
      }
    `,
    groupKey
  )

  const form = useFormStore<DeleteSubGroupButtonMutation>(
    graphql`
      mutation DeleteSubGroupButtonMutation($input: DeleteMemberGroupInput!) {
        response: deleteMemberGroup(input: $input) {
          node {
            id @deleteRecord
            kind
            parentMemberGroup {
              ...AdminGroupsListRowFragment
              ...DrawerUnassignedMemberRowFragment
            }
          }
          deletedMembershipIds @deleteRecord
          errors {
            field
            message
          }
        }
      }
    `,
    {
      memberGroupId: memberGroup.id,
    }
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>
      <DiscoWarningModal
        isOpen={isModalOpen}
        onClose={closeModal}
        testid={"DeleteSubGroupButtonModal"}
        modalContentLabel={"DeleteSubGroupButtonModal"}
        title={`Delete the sub-group "${memberGroup.name}"`}
        description={
          "Are you sure you want to permanently delete this sub-group? There is no way to undo this action."
        }
        confirmationButtonProps={{
          onClick: deleteMemberGroup,
          shouldDisplaySpinner: form.isSubmitting,
          children: "Yes, delete it",
        }}
      />
    </>
  )

  async function deleteMemberGroup() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) return

    displaySuccessToast({
      message: "Sub-group deleted!",
      testid: "DeleteSubGroupButton.success-message",
    })
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

export default observer(DeleteSubGroupButton)
