/**
 * @generated SignedSource<<19abe85483cf02e5da3a67c5770e2043>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentCommentButton_ContentFragment$data = {
  readonly hasViewerCommented: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CommentUtils_useTotalCommentsCount">;
  readonly " $fragmentType": "ContentCommentButton_ContentFragment";
};
export type ContentCommentButton_ContentFragment$key = {
  readonly " $data"?: ContentCommentButton_ContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCommentButton_ContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCommentButton_ContentFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentUtils_useTotalCommentsCount"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerCommented",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "2b47e72746346fc023436e4cb242e0e7";

export default node;
