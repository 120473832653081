import CommentUtils from "@/comments/utils/CommentUtils"
import { ContentCommentButton_ContentFragment$key } from "@/product/common/content/button/__generated__/ContentCommentButton_ContentFragment.graphql"
import { ContentCommentButton_ContentUsageFragment$key } from "@/product/common/content/button/__generated__/ContentCommentButton_ContentUsageFragment.graphql"
import { RequireOnlyOne } from "@/types/util/RequireOnlyOne"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoIcon, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import classnames from "classnames"
import pluralize from "pluralize"
import { graphql, useFragment } from "react-relay"

type ContentCommentButtonProps = RequireOnlyOne<
  {
    contentKey: ContentCommentButton_ContentFragment$key
    contentUsageKey: ContentCommentButton_ContentUsageFragment$key
  },
  "contentKey" | "contentUsageKey"
> & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  hideText?: boolean
  iconColor?: string
  fillColor?: string
  textColor?: string
  backgroundColor?: string
}

function ContentCommentButton({
  contentKey,
  contentUsageKey,
  onClick,
  className,
  hideText,
  iconColor,
  textColor,
  fillColor,
  backgroundColor,
}: ContentCommentButtonProps) {
  const classes = useStyles({
    iconColor,
    fillColor,
    textColor,
  })

  const content = useFragment<ContentCommentButton_ContentFragment$key>(
    graphql`
      fragment ContentCommentButton_ContentFragment on Content {
        ...CommentUtils_useTotalCommentsCount
        hasViewerCommented
      }
    `,
    contentKey || null
  )

  const contentUsage = useFragment<ContentCommentButton_ContentUsageFragment$key>(
    graphql`
      fragment ContentCommentButton_ContentUsageFragment on ContentUsage {
        ...CommentUtils_useTotalCommentsOnUsageCount
        hasViewerCommented
      }
    `,
    contentUsageKey || null
  )

  const theme = useTheme()

  const totalCount = CommentUtils.useTotalCommentsCount(content, contentUsage)
  const hasViewerCommented =
    content?.hasViewerCommented || contentUsage?.hasViewerCommented

  return (
    <div className={classnames(classes.container, className)}>
      <DiscoButton
        onClick={onClick}
        testid={"ContentCommentButton"}
        leftIcon={
          hasViewerCommented ? (
            <DiscoIcon
              icon={"iconsax.message"}
              active
              className={classes.commentIconFill}
            />
          ) : (
            <DiscoIcon icon={"iconsax.message"} className={classes.commentIcon} />
          )
        }
        color={"grey"}
        className={classnames(classes.commentButton)}
        customButtonColor={{
          color:
            theme.palette.type === "dark"
              ? theme.palette.groovy.onDark[200]
              : theme.palette.groovy.neutral[400],
          backgroundColor: backgroundColor || theme.palette.background.paper,
          hover: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.groovy.neutral[200],
          },
        }}
      >
        <DiscoText testid={"ContentCommentButton"} variant={"body-sm"} marginLeft={1}>
          {hideText ? totalCount : pluralize("Comment", totalCount, true)}
        </DiscoText>
      </DiscoButton>
    </div>
  )
}

type StyleProps = {
  iconColor?: string
  fillColor?: string
  textColor?: string
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  commentIcon: ({
    iconColor = theme.palette.type === "dark"
      ? theme.palette.groovy.onDark[200]
      : theme.palette.groovy.neutral[400],
  }: StyleProps) => ({
    "& > path": {
      color: `${iconColor} !important`,
    },
  }),
  commentIconFill: ({ fillColor = theme.palette.primary.main }: StyleProps) => ({
    "& > path": {
      color: `${fillColor} !important`,
    },
  }),

  commentButton: ({ textColor }: StyleProps) => ({
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[200]
        : theme.palette.groovy.neutral[400],
    gap: theme.spacing(0.25),
    padding: theme.spacing(1),
    height: "32px",
    // transition set in DiscoButton was causing unideal fading in AssingmentSubmissionListRow
    transition: "unset !important",
    "& p": {
      color:
        textColor ||
        (theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[400]),
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    "&:hover svg:not($commentIconFill) path": {
      stroke: theme.palette.text.primary,
    },
    "&:hover p": {
      color: theme.palette.text.primary,
    },
  }),
}))

export function ContentCommentButtonSkeleton() {
  const classes = useStyles({})
  return (
    <div className={classes.container}>
      <DiscoIcon icon={"iconsax.message"} className={classes.commentIcon} />
      <DiscoTextSkeleton width={"50px"} />
    </div>
  )
}

export default ContentCommentButton
