import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoInput } from "@disco-ui"
import { observer } from "mobx-react-lite"
import CheckoutStore from "../../store/CheckoutStore"

interface Props {
  store: CheckoutStore
}

const CheckoutFormProfileNewUserSection = observer<Props>((props) => {
  const classes = useStyles()
  const { store } = props
  return (
    <div className={classes.container}>
      <DiscoInput
        fullWidth
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        placeholder={"First Name"}
        name={"first-name"}
        value={store.profile.firstName || ""}
        onChange={(e) => (store.profile.firstName = e.target.value)}
        data-testid={"CheckoutFormProfileNewUserSection.first-name"}
      />

      <DiscoInput
        fullWidth
        placeholder={"Last Name"}
        name={"last-name"}
        value={store.profile.lastName || ""}
        onChange={(e) => (store.profile.lastName = e.target.value)}
        data-testid={"CheckoutFormProfileNewUserSection.last-name"}
      />

      <DiscoInput
        fullWidth
        placeholder={"Email"}
        name={"email"}
        type={"email"}
        value={store.profile.email || ""}
        onChange={(e) => (store.profile.email = e.target.value)}
        data-testid={"CheckoutFormProfileNewUserSection.email"}
      />

      <DiscoInput
        fullWidth
        placeholder={"Confirm your email"}
        name={"confirm-email"}
        type={"email"}
        value={store.profile.confirmEmail || ""}
        onChange={(e) => (store.profile.confirmEmail = e.target.value)}
        error={store.isEmailConfirmationWrong}
        data-testid={"CheckoutFormProfileNewUserSection.email-confirm"}
      />
    </div>
  )
})

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default CheckoutFormProfileNewUserSection
