import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { SelectGroupMembersListItemFragment$key } from "@/product/common/member-group/modal/components/__generated__/SelectGroupMembersListItemFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import { DiscoCheckbox, DiscoText } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface SelectGroupMembersListItem {
  productMembershipKey: SelectGroupMembersListItemFragment$key
  selectedMembershipIds: GlobalID[]
  handleSelectMember: (v: boolean, membershipId: GlobalID) => void
  testid?: string
}

function SelectGroupMembersListItem({
  productMembershipKey,
  selectedMembershipIds,
  handleSelectMember,
  testid,
}: SelectGroupMembersListItem) {
  const classes = useStyles()

  const productMembership = useFragment<SelectGroupMembersListItemFragment$key>(
    graphql`
      fragment SelectGroupMembersListItemFragment on ProductMembership {
        id
        member {
          id
          avatar
          firstName
          lastName
        }
      }
    `,
    productMembershipKey
  )

  return (
    <div className={classes.selectedMembershipsListItem}>
      <DiscoCheckbox
        checked={selectedMembershipIds.includes(productMembership.id)}
        onChange={(v: boolean) => handleSelectMember(v, productMembership.id)}
        label={
          <div className={classes.selectedMembershipListItemLabel} data-testid={testid}>
            <UserAvatar
              testid={"SelectGroupMembersList.UserAvatar"}
              className={classes.avatar}
              placeholderClassName={classes.avatar}
              placeholderTextClassName={"header--h3"}
              user={{
                ...productMembership.member,
                first_name: productMembership.member.firstName,
                last_name: productMembership.member.lastName,
              }}
              size={24}
            />
            <DiscoText
              color={"text.secondary"}
            >{`${productMembership.member.firstName} ${productMembership.member.lastName}`}</DiscoText>
          </div>
        }
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  selectedMembershipsListItem: {
    display: "flex",
    padding: theme.spacing(0.625, 0),
  },
  selectedMembershipListItemLabel: {
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(1.5),
  },
}))

export default SelectGroupMembersListItem
