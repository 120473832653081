import { useFormStore } from "@/core/form/store/FormStore"
import { EditMemberGroupFormMutation } from "@/product/common/member-group/common/edit/form/__generated__/EditMemberGroupFormMutation.graphql"
import { EditMemberGroupFormQuery } from "@/product/common/member-group/common/edit/form/__generated__/EditMemberGroupFormQuery.graphql"
import MemberGroupFormFields, {
  MemberGroupFormFieldsSkeleton,
} from "@/product/common/member-group/common/MemberGroupFormFields"
import { EditMemberGroupFormState } from "@/product/common/member-group/util/MemberGroupUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  groupId: GlobalID
  onCancel: () => void
  onSave: () => void
}

const EditMemberGroupForm = observer<Props>((props) => {
  const { groupId, onCancel, onSave } = props

  const { node } = useLazyLoadQuery<EditMemberGroupFormQuery>(
    graphql`
      query EditMemberGroupFormQuery($id: ID!) {
        node(id: $id) {
          ... on MemberGroup {
            __typename
            id
            name
            color
            parentMemberGroupId
            visibility
            organization {
              defaultMemberGroup {
                id
              }
            }
            memberGroupMemberships {
              totalCount
              edges {
                node {
                  id
                  userId
                }
              }
            }
          }
        }
      }
    `,
    { id: groupId },
    { fetchPolicy: "network-only" }
  )

  const memberGroup = Relay.narrowNodeType(node, "MemberGroup")
  const activeMembers = Relay.connectionToArray(memberGroup?.memberGroupMemberships)

  const form = useFormStore<EditMemberGroupFormMutation, EditMemberGroupFormState>(
    graphql`
      mutation EditMemberGroupFormMutation($input: EditMemberGroupInput!) {
        response: editMemberGroup(input: $input) {
          node {
            id
            ...AdminGroupsListRowFragment
            ...MemberGroupsListFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: memberGroup?.id || "",
      name: memberGroup?.name,
      color: memberGroup?.color,
      memberGroupId:
        memberGroup?.parentMemberGroupId ||
        memberGroup?.organization?.defaultMemberGroup.id ||
        memberGroup?.id,
      selectedUserIds: activeMembers.map((am) => am.userId),
      visibility: memberGroup?.visibility,
    }
  )

  if (!memberGroup) return null

  return (
    <Form
      testid={"EditMemberGroupForm"}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onCancel}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            testid={"MemberGroupFormFields.submit"}
            form={form}
            disabled={!form.isChanged || form.isSubmitting}
          >
            {"Save Changes"}
          </Form.SubmitButton>
        </>
      }
    >
      <MemberGroupFormFields data-testid={"EditMemberGroupFormFields"} form={form} />
    </Form>
  )

  async function handleSubmit() {
    if (!memberGroup) return

    // Find the added and removed members
    const diff = ArrayUtils.diff(
      form.initialState.selectedUserIds,
      form.state.selectedUserIds
    )

    const { didSave } = await form.submit({
      id: memberGroup.id,
      addUsers: diff.added,
      removeUsers: diff.removed,
      color: form.state.color,
      name: form.state.name,
    })

    if (!didSave) return

    displaySuccessToast({
      message: "Group updated!",
      testid: "EditMemberGroupForm.success-toast",
    })
    onSave()
  }
})

export default Relay.withSkeleton<Props>({
  component: EditMemberGroupForm,
  skeleton: MemberGroupFormFieldsSkeleton,
})
