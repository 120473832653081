import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EditEmailTemplateFormFragment$key } from "@/product/course/settings/notifications-settings/email/form/__generated__/EditEmailTemplateFormFragment.graphql"
import { EditEmailTemplateFormMutation } from "@/product/course/settings/notifications-settings/email/form/__generated__/EditEmailTemplateFormMutation.graphql"
import { EditEmailTemplateFormPreviewMutation } from "@/product/course/settings/notifications-settings/email/form/__generated__/EditEmailTemplateFormPreviewMutation.graphql"
import Relay from "@/relay/relayUtils"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { generatePath } from "react-router"
import { graphql } from "relay-runtime"

interface Props {
  onCancel(): void
  emailTemplateKey: EditEmailTemplateFormFragment$key
  testid: string
}

const EditEmailTemplateForm = observer<Props>((props) => {
  const { onCancel, emailTemplateKey, testid } = props
  const activeProduct = useActiveProduct()!
  const classes = useStyles()
  const theme = useTheme()
  const { authUser } = useAuthUser({ required: true })

  const emailTemplate = useFragment<EditEmailTemplateFormFragment$key>(
    graphql`
      fragment EditEmailTemplateFormFragment on EmailTemplate {
        id
        subject
        title
        body
        richEditorBody
        kind
        ctaText
      }
    `,
    emailTemplateKey
  )

  const sendPreviewEmail = Relay.useAsyncMutation<EditEmailTemplateFormPreviewMutation>(
    graphql`
      mutation EditEmailTemplateFormPreviewMutation(
        $input: SendCustomizablePreviewEmailInput!
      ) {
        sendCustomizablePreviewEmail(input: $input)
      }
    `
  )

  const form = useFormStore<EditEmailTemplateFormMutation>(
    graphql`
      mutation EditEmailTemplateFormMutation($input: UpdateEmailTemplateInput!) {
        response: updateEmailTemplate(input: $input) {
          node {
            id
            subject
            title
            body
            richEditorBody
            ctaText
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      emailTemplateId: emailTemplate.id,
      subject: emailTemplate.subject,
      title: emailTemplate.title,
      body: emailTemplate.body,
      richEditorBody: emailTemplate.richEditorBody,
      ctaText: emailTemplate.ctaText,
    }
  )

  const { setUnsavedChanges } = useUnsavedChangesModalContext()

  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  if (!emailTemplate) return null

  return (
    <Form
      testid={testid}
      onSubmit={handleSubmit}
      classes={{ formFieldsContainer: classes.form }}
      height={"100%"}
      hasStickyButtons
      buttons={
        <>
          <DiscoButton
            onClick={onCancel}
            color={"grey"}
            variant={"outlined"}
            data-testid={`${testid}.cancel-button`}
          >
            {"Cancel"}
          </DiscoButton>
          <DiscoButton
            onClick={handleSendPreview}
            data-testid={`${testid}.preview-button`}
            // // color={"outline"}
          >
            {"Send Preview Email to Me"}
          </DiscoButton>
          <DiscoButton
            data-testid={`${testid}.submit-button`}
            disabled={EditorUtils.isEmpty(form.state.richEditorBody)}
            shouldDisplaySpinner={form.isSubmitting}
            type={"submit"}
          >
            {"Save Changes"}
          </DiscoButton>
        </>
      }
    >
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Email Subject"}
          </DiscoText>
        }
        error={Boolean(form.errorsByField.subject)}
        errorMessages={form.errorsByField.subject}
      >
        <DiscoInput
          inputProps={{
            "data-testid": "EditEmailTemplateForm.subject.input",
          }}
          name={"subject"}
          value={form.state.subject}
          placeholder={"Email subject"}
          onChange={(e) => (form.state.subject = e.target.value)}
          fullWidth
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Email Body"}
          </DiscoText>
        }
        error={Boolean(form.errorsByField.richEditorBody)}
        errorMessages={form.errorsByField.richEditorBody}
      >
        <DiscoEditor
          className={classes.editor}
          defaultValue={form.state.richEditorBody}
          onChange={(v) => (form.state.richEditorBody = v)}
          textColor={theme.palette.text.primary}
          config={"email"}
          testid={"EditEmailTemplateForm.body.input"}
          showOutline
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {"Button Label"}
          </DiscoText>
        }
        error={Boolean(form.errorsByField.ctaText)}
        errorMessages={form.errorsByField.ctaText}
      >
        <DiscoInput
          inputProps={{
            "data-testid": "EditEmailTemplateForm.ctaText.input",
          }}
          name={"ctaText"}
          value={form.state.ctaText}
          placeholder={"Button Label"}
          onChange={(e) => (form.state.ctaText = e.target.value)}
          fullWidth
        />
      </DiscoFormControl>
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit({
      emailTemplateId: form.state.emailTemplateId,
      subject: form.state.subject,
      richEditorBody: form.state.richEditorBody,
      ctaText: form.state.ctaText,
    })

    if (!didSave) return
    displaySuccessToast({
      message: "Email template updated!",
      testid: "EditEmailTemplateForm.success-toast",
    })
  }

  async function handleSendPreview() {
    const courseDashboardPath = generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
      productSlug: activeProduct.slug,
    })

    await sendPreviewEmail({
      input: {
        productId: activeProduct.id,
        kind: emailTemplate.kind,
        subject: form.state.subject || "",
        title: form.state.title || "",
        richEditorBody: form.state.richEditorBody || "",
        ctaText: form.state.ctaText || "",
        ctaUrl: `${window.location.origin}${courseDashboardPath}`,
        notificationsUrl: `${window.location.origin}${courseDashboardPath}`,
      },
    })

    displaySuccessToast({
      message: `Preview email sent to ${authUser!.email!}`,
      testid: `EditEmailTemplateForm.success-emoji`,
    })
  }
})

const useStyles = makeUseStyles((theme) => ({
  form: {
    padding: theme.spacing(3, 3, 0),
  },
  editor: {
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.default,
  },
}))

export default EditEmailTemplateForm
