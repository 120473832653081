import ProductUtils from "@/product/util/productUtils"
import {
  displayErrorToast,
  displayGraphQLErrorToast,
} from "@components/toast/ToastProvider"
import { useCallback } from "react"
import { graphql } from "relay-runtime"
import Relay from "../../../relay/relayUtils"
import CheckoutStore from "./CheckoutStore"
import { useCompleteCheckoutMutation_Mutation } from "./__generated__/useCompleteCheckoutMutation_Mutation.graphql"

/** Returns a callback function to execute an updateCheckout mutation */
export default function useCompleteCheckoutMutation(store: CheckoutStore) {
  const commitMutation = Relay.useAsyncMutation<useCompleteCheckoutMutation_Mutation>(
    graphql`
      mutation useCompleteCheckoutMutation_Mutation($id: ID!) {
        completeCheckout(id: $id) {
          node {
            id
            product {
              hasEnded
              viewerMembership {
                id
              }
              viewerApplication {
                id
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )
  const invalidateProductsSidebar = ProductUtils.useInvalidateSidebar()

  const completeCheckout = useCallback(async () => {
    if (!store.checkout) {
      throw new Error("Missing store.checkout to completeCheckout")
    }
    store.isSubmitting = true
    const res = await commitMutation(
      { id: store.checkout.id },
      {
        updater: (recordSource) => {
          // Refetch products so that the new product shows up in the sidebar
          invalidateProductsSidebar(recordSource)
        },
      }
    )
      .catch((err) => displayErrorToast(err))
      .finally(() => (store.isSubmitting = false))
    if (!res) return false
    if (res.completeCheckout.errors) {
      displayGraphQLErrorToast(res.completeCheckout.errors[0])
      return false
    }
    // Show success page whenever checkout is complete.
    store.currentStep = "success"
    return true
  }, [store, commitMutation, invalidateProductsSidebar])

  return completeCheckout
}
