import useDismissNotifications from "@/apps/util/hooks/useDismissNotifications"
import ContentUtils from "@/content/util/contentUtils"
import { ContentFlaggingListItemFragment$key } from "@/product/common/page/content/flagging/popover/__generated__/ContentFlaggingListItemFragment.graphql"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  contentFlaggingKey: ContentFlaggingListItemFragment$key
}

function ContentFlaggingListItem(props: Props) {
  const { contentFlaggingKey, testid = "ContentFlaggingListItem" } = props

  const contentFlagging = useFragment<ContentFlaggingListItemFragment$key>(
    graphql`
      fragment ContentFlaggingListItemFragment on ContentFlagging {
        id
        reason
        reasonText
        createdAt
        user {
          id
          ...ProfileAvatarWithDetailsFragment
        }
      }
    `,
    contentFlaggingKey
  )

  const userTimezone = useUserTimezone()
  const classes = useStyles()

  useDismissNotifications({
    kind: "content-flagged",
    entityId: contentFlagging.id,
  })

  return (
    <li>
      <ProfileAvatarWithDetails
        userKey={contentFlagging.user}
        details={formatDateWithOptions({
          timeZone: userTimezone,
          format: DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_AT,
        })(new Date(contentFlagging.createdAt))}
        className={classes.profile}
        testid={`${testid}.profile`}
      />

      <DiscoText variant={"body-sm-700"} testid={`${testid}.reason`}>
        {ContentUtils.getFlagReasonLabel(contentFlagging.reason)}
      </DiscoText>
      {contentFlagging.reasonText && (
        <DiscoText
          marginTop={1}
          variant={"body-sm"}
          color={"text.secondary"}
          testid={`${testid}.reason-text`}
        >
          {contentFlagging.reasonText}
        </DiscoText>
      )}
    </li>
  )
}

const useStyles = makeUseStyles((theme) => ({
  profile: {
    marginBottom: theme.spacing(2),
  },
}))

export default ContentFlaggingListItem
