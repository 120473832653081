import { DiscoIcon, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  size?: number
}

function RecurringEventIcon({ testid, size = 18 }: Props) {
  const theme = useTheme()

  return (
    <DiscoTooltip content={"This is a recurring event"} placement={"top"}>
      <div
        data-testid={`${testid}-recurring-event-icon`}
        style={{ height: size, width: size }}
      >
        <DiscoIcon
          icon={"repeat"}
          color={theme.palette.primary.main}
          height={size}
          width={size}
        />
      </div>
    </DiscoTooltip>
  )
}

export default RecurringEventIcon
