import { AuthUserData } from "@/core/context/AuthUserContext"
import { makeAutoObservable, observable } from "mobx"
import { graphql } from "react-relay"
import { ValidationError } from "../../../relay/RelayTypes"
import { useStartCheckoutQuery$data } from "./__generated__/useStartCheckoutQuery.graphql"

export type CheckoutStep =
  | "registration"
  | "apply"
  | "payment"
  | "success"
  | "verify_email"

export default class CheckoutStore {
  response = observable.box<useStartCheckoutQuery$data["product"] | null>(null)

  /** Observable + modifiable profile of the user */
  profile: CheckoutProfile = {}

  currentStep: CheckoutStep = "registration"

  /** If set, indicates this error prevents the current user from checking out */
  startCheckoutError: ValidationError | null = null

  /** Has the user checked the T&C + Privacy Policy checkbox? */
  hasAcceptedTerms = false
  /** Has the user checked the "I will be charged when application is accepted" box */
  hasAcceptedFutureCharge = false

  isSubmitting = false

  /** Is the checkout started by a new user? */
  isForNewUser = true

  payment: CheckoutPayment = {
    existingPaymentMethodId: null,
    newPaymentMethod: {
      cardHolderName: "",
      saveToUser: false,
      postalCode: "",
    },
  }

  /** Has the user selected to sign up with an email instead of social */
  hasSelectedEmailSignup = false

  applicationAnswers = observable.array<string>()
  organizationFormAnswers = observable.array<string>()

  constructor(args: { authUser: AuthUserData }) {
    makeAutoObservable(this)
    this.isForNewUser = !args.authUser
  }

  get checkout() {
    return this.product?.myCheckout
  }

  get product() {
    return this.response.get()
  }

  get isEmailConfirmationWrong(): boolean {
    return Boolean(
      this.profile.email &&
        this.profile.confirmEmail &&
        this.profile.email !== this.profile.confirmEmail
    )
  }

  /** Are all details on the registration step complete enough to proceed? */
  get canCompleteRegistrationStep(): boolean {
    if (!this.hasAcceptedTerms && this.isForNewUser) return false
    // Either logged in or completed registration form.
    if (
      !this.profile.userId &&
      (!this.profile.firstName ||
        !this.profile.lastName ||
        !this.profile.email ||
        !this.profile.confirmEmail ||
        this.isEmailConfirmationWrong)
    ) {
      return false
    }
    // Answers are provided for each application question.
    if (
      this.checkout?.hasApplication &&
      !this.applicationAnswers.every((answer) => answer.trim())
    ) {
      return false
    }
    // Answers are provided for each organization form question.
    if (this.checkout?.organizationForm && !this.organizationFormAnswers.every(Boolean)) {
      return false
    }
    return true
  }

  /** Are all details on the payment step OK to submit? */
  get canCompletePaymentStep(): boolean {
    // The checkout is free, nothing required on the payment step.
    if (!this.hasPayment) return true
    // With an application, an extra checkbox is required.
    if (this.checkout?.hasApplication && !this.hasAcceptedFutureCharge) return false
    // Must enter a cardholder name. Other credit card fields are checked
    // by stripe on submission.
    if (!this.payment.newPaymentMethod.cardHolderName) return false
    return true
  }

  /** Does the payment step need to be displayed? */
  get requiresPaymentStep(): boolean {
    return this.isGifted ? false : Boolean(this.checkout?.quote.basePrice)
  }

  /** Does the checkout have a payment? */
  get hasPayment(): boolean {
    return this.isGifted ? false : Boolean(this.checkout?.quote.finalPrice)
  }

  /** Whether or not this experience is gifted and no further payment info is required */
  get isGifted(): boolean {
    return this.checkout?.invitation?.kind === "gift"
  }
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment CheckoutStore_Checkout on Checkout {
    id
    ...ProductRegistrationPaymentStep_Checkout
    ...CheckoutFormDiscountInput_Checkout
    ...CheckoutFormApplicationSection_Checkout
    ...CheckoutFormOrganizationFormSection_Checkout
    hasApplication
    isOrganizationPublic
    profile {
      userId
      email
      firstName
      lastName
      timezone
    }
    quote {
      basePrice
      finalPrice
      currency
      discount {
        id
        code
      }
    }
    occurrence {
      id
      allowGuestAccess
    }
    application {
      answers
    }
    organizationForm {
      answers
    }
    invitation {
      kind
      createdByUser {
        fullName
      }
      message
      productId
      organizationId
    }
  }
`

/** Client-side state of a checkout profile  */
type CheckoutProfile = {
  userId?: string | null
  email?: string | null
  confirmEmail?: string | null
  firstName?: string | null
  lastName?: string | null
  timezone?: string | null
}

type CheckoutPayment = {
  existingPaymentMethodId: string | null
  newPaymentMethod: {
    cardHolderName: string
    saveToUser: boolean
    postalCode: string
  }
}
