import { useActiveProduct } from "@/core/context/ActiveProductContext"
import {
  MemberGroupKind,
  MemberGroupRole,
} from "@/product/common/member-group/common/tag/__generated__/MemberGroupTagFragment.graphql"
import { useOrganizationRoleLabels, useProductRoleLabels } from "@/role/roleUtils"

const useGetGroupName = () => {
  const activeProduct = useActiveProduct()
  const orgRoleLabels = useOrganizationRoleLabels()
  const productRoleLabels = useProductRoleLabels()

  function getName(
    kind: MemberGroupKind,
    name: string,
    showFullDetails: boolean,
    product?: { id: string; name: string },
    role?: MemberGroupRole | null
  ) {
    if (kind === "default" && product) {
      if (showFullDetails) return `All: ${product.name}`
      // Don't add the product name if we're inside this product
      if (activeProduct?.id === product.id) return name
      return `All: ${product.name}`
    }
    switch (role) {
      case "owner":
      case "admin":
        return `Community ${orgRoleLabels[role].plural}`
      case "manager":
      case "instructor":
        if (showFullDetails) return `${productRoleLabels[role].plural}: ${product!.name}`
        // Don't add the product name if we're inside this product
        if (activeProduct?.id === product?.id) return productRoleLabels[role].plural
        return `${productRoleLabels[role].plural}: ${product!.name}`
    }
    return name
  }
  return getName
}

export default useGetGroupName
