import { ObservableState } from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  CloneCurriculumContentModuleUsage,
  useActiveCloneProductModalContext,
} from "@/product/clone/sequence/CloneProductModalContext"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoModal, DiscoText } from "@disco-ui"
import DiscoDateTimePickerV1 from "@disco-ui/date/DiscoDateTimePickerV1"
import { isBefore } from "date-fns"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

function CloneProductEditModuleDetails() {
  const { form } = useActiveCloneProductModalContext()!
  const [moduleState, setModuleState] =
    useState<ObservableState<CloneCurriculumContentModuleUsage> | null>(null)
  const classes = useStyles()

  useEffect(() => {
    if (!form.state.curriculumContentModuleUsages) return
    const section = form.state.curriculumContentModuleUsages.find(
      ({ sourceId }) => form.state.editModuleSourceId === sourceId
    )
    setModuleState(section || null)
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.editModuleSourceId])

  if (!moduleState) return null
  return (
    <DiscoModal
      isOpen={Boolean(form.state.editModuleSourceId)}
      title={"Edit Module Details"}
      subtitle={
        <>
          <DiscoText display={"inline"}>{"Changing date and time for "}</DiscoText>
          <DiscoText display={"inline"} variant={"body-md-700"}>
            {moduleState.name}
          </DiscoText>
        </>
      }
      testid={"CloneProductEditModuleDetails"}
      modalContentLabel={"Edit Module Details"}
      onClose={handleClose}
      width={"550px"}
      body={
        <div className={classes.picker}>
          <DiscoDateTimePickerV1
            // Use the current datetime as the release date if the module does not have one
            value={moduleState.releasedAt || new Date()}
            onChange={(v) =>
              setModuleState((prev) => {
                if (!prev) return null
                return observable({
                  ...prev,
                  releasedAt: v?.toISOString() || prev.releasedAt,
                })
              })
            }
            minDate={new Date()}
            dateSelectTitle={"Date"}
            timeSelectTitle={"Start Time"}
          />
        </div>
      }
      buttons={
        <>
          <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton onClick={handleSubmit}>{"Save Changes"}</DiscoButton>
        </>
      }
    />
  )

  function handleSubmit() {
    const editIndex = form.state.curriculumContentModuleUsages?.findIndex(
      ({ sourceId }) => form.state.editModuleSourceId === sourceId
    )
    if (editIndex === undefined || !moduleState) return
    if (
      moduleState.releasedAt &&
      isBefore(new Date(moduleState.releasedAt), new Date())
    ) {
      form.addError({ field: "*", message: "This module cannot be created in the past." })
      return
    }
    form.state.curriculumContentModuleUsages!.splice(editIndex, 1, moduleState)
    form.state.curriculumContentModuleUsages! = observable.array(
      JSON.parse(JSON.stringify(form.state.curriculumContentModuleUsages!))
    ) // Trigger state update for other steps reliant on these dates (ie. assignment due date step)
    displaySuccessToast({
      message: `${moduleState.name} has been updated.`,
      testid: "CloneProductEditModuleDetails",
    })
    handleClose()
  }

  function handleClose() {
    form.state.editModuleSourceId = undefined
  }
}

const useStyles = makeUseStyles((theme) => ({
  picker: {
    marginTop: theme.spacing(2.5),
  },
}))

export default observer(CloneProductEditModuleDetails)
