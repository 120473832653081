/**
 * @generated SignedSource<<67d33041e899676e44d6ce05358609ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddUsersToMemberGroupInput = {
  userIds: ReadonlyArray<string>;
  memberGroupIds: ReadonlyArray<string>;
  productId: string;
};
export type AddMembersToGroupsModalMutation$variables = {
  input: AddUsersToMemberGroupInput;
};
export type AddMembersToGroupsModalMutation$data = {
  readonly response: {
    readonly memberGroupMemberships: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly productMembership: {
            readonly " $fragmentSpreads": FragmentRefs<"useMemberGroupTagsList_ProductMembershipFragment">;
          } | null;
          readonly organizationMembership: {
            readonly " $fragmentSpreads": FragmentRefs<"useMemberGroupTagsList_OrganizationMembershipFragment">;
          };
        };
      }>;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type AddMembersToGroupsModalMutation = {
  variables: AddMembersToGroupsModalMutation$variables;
  response: AddMembersToGroupsModalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "groupKinds",
    "value": [
      "custom"
    ]
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MemberGroupMembershipNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MemberGroupMembership",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MemberGroup",
            "kind": "LinkedField",
            "name": "memberGroup",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentMemberGroupId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoObjectType",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  "groupKinds"
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddMembersToGroupsModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "AddUsersToMemberGroupResponse",
        "kind": "LinkedField",
        "name": "addUsersToMemberGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MemberGroupMembershipNodeConnection",
            "kind": "LinkedField",
            "name": "memberGroupMemberships",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberGroupMembershipNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MemberGroupMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "productMembership",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "useMemberGroupTagsList_ProductMembershipFragment"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "organizationMembership",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "useMemberGroupTagsList_OrganizationMembershipFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddMembersToGroupsModalMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "AddUsersToMemberGroupResponse",
        "kind": "LinkedField",
        "name": "addUsersToMemberGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MemberGroupMembershipNodeConnection",
            "kind": "LinkedField",
            "name": "memberGroupMemberships",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberGroupMembershipNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MemberGroupMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "productMembership",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "concreteType": "MemberGroupMembershipNodeConnection",
                            "kind": "LinkedField",
                            "name": "memberGroupMemberships",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": "memberGroupMemberships(groupKinds:[\"custom\"])"
                          },
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "filters": (v7/*: any*/),
                            "handle": "connection",
                            "key": "useMemberGroupTagsList_ProductMembershipFragment_memberGroupMemberships",
                            "kind": "LinkedHandle",
                            "name": "memberGroupMemberships"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "organizationMembership",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "concreteType": "MemberGroupMembershipNodeConnection",
                            "kind": "LinkedField",
                            "name": "groupMemberships",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": "groupMemberships(groupKinds:[\"custom\"])"
                          },
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "filters": (v7/*: any*/),
                            "handle": "connection",
                            "key": "useMemberGroupTagsList_OrganizationMembershipFragment_groupMemberships",
                            "kind": "LinkedHandle",
                            "name": "groupMemberships"
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ace74545bca4ecb4de99aa8002b0dca",
    "id": null,
    "metadata": {},
    "name": "AddMembersToGroupsModalMutation",
    "operationKind": "mutation",
    "text": "mutation AddMembersToGroupsModalMutation(\n  $input: AddUsersToMemberGroupInput!\n) {\n  response: addUsersToMemberGroup(input: $input) {\n    memberGroupMemberships {\n      edges {\n        node {\n          productMembership {\n            ...useMemberGroupTagsList_ProductMembershipFragment\n            id\n          }\n          organizationMembership {\n            ...useMemberGroupTagsList_OrganizationMembershipFragment\n            id\n          }\n          id\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment MemberGroupTagFragment on MemberGroup {\n  id\n  name\n  kind\n  color\n  role\n  product {\n    id\n    name\n  }\n}\n\nfragment useMemberGroupTagsList_OrganizationMembershipFragment on OrganizationMembership {\n  id\n  groupMemberships(groupKinds: [custom]) {\n    edges {\n      node {\n        id\n        productId\n        memberGroup {\n          id\n          parentMemberGroupId\n          visibility\n          ...MemberGroupTagFragment\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment useMemberGroupTagsList_ProductMembershipFragment on ProductMembership {\n  id\n  memberGroupMemberships(groupKinds: [custom]) {\n    edges {\n      node {\n        id\n        productId\n        memberGroup {\n          id\n          parentMemberGroupId\n          visibility\n          ...MemberGroupTagFragment\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7bffd33d764fe028746671a0e822a813";

export default node;
