import { MemberGroupProfileListDrawerPaginationQuery } from "@/product/common/member-group/common/profile-drawer/__generated__/MemberGroupProfileListDrawerPaginationQuery.graphql"
import { MemberGroupProfileListDrawerQuery } from "@/product/common/member-group/common/profile-drawer/__generated__/MemberGroupProfileListDrawerQuery.graphql"
import { MemberGroupProfileListDrawer_PaginationFragment$key } from "@/product/common/member-group/common/profile-drawer/__generated__/MemberGroupProfileListDrawer_PaginationFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import ProfileListDrawer, {
  ProfileListItemType,
} from "@/user/common/profile-list-drawer/ProfileListDrawer"
import DiscoDrawerHeaderBreadcrumbTitle from "@disco-ui/drawer/DiscoDrawerHeaderBreadcrumbTitle"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  isOpen: boolean
  onClose: () => void
  memberGroupId: GlobalID
  title?: React.ReactElement | string
  separateAdmins?: boolean
}

const MEMBERS_PER_LOAD = 30

function MemberGroupProfileListDrawer(props: Props) {
  const { isOpen, onClose, memberGroupId, title, separateAdmins = false } = props

  const { memberGroup } = useLazyLoadQuery<MemberGroupProfileListDrawerQuery>(
    graphql`
      query MemberGroupProfileListDrawerQuery($id: ID!, $first: Int!, $after: String) {
        memberGroup: node(id: $id) {
          ... on MemberGroup {
            id
            name
            productId
            ...MemberGroupProfileListDrawer_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    { id: memberGroupId, first: MEMBERS_PER_LOAD },
    { fetchPolicy: "network-only" }
  )

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    MemberGroupProfileListDrawerPaginationQuery,
    MemberGroupProfileListDrawer_PaginationFragment$key
  >(
    graphql`
      fragment MemberGroupProfileListDrawer_PaginationFragment on MemberGroup
      @refetchable(queryName: "MemberGroupProfileListDrawerPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        memberGroupMemberships(first: $first, after: $after)
          @connection(key: "MemberGroupProfileListDrawer__memberGroupMemberships") {
          edges {
            node {
              id
              organizationMembership {
                role
              }
              productMembership {
                role
              }
              user {
                id
                avatar
                first_name: firstName
                last_name: lastName
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    `,
    memberGroup
  )

  if (!memberGroup || !data) return null

  const memberships = Relay.connectionToArray(data.memberGroupMemberships)

  return (
    <ProfileListDrawer
      isOpen={isOpen}
      onClose={onClose}
      separateAdmins={separateAdmins}
      users={mapGroupMembersToAvatarUsers()}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      hasNext={hasNext}
      membersPerLoad={MEMBERS_PER_LOAD}
      totalCount={data.memberGroupMemberships.totalCount}
      title={
        title || (
          <DiscoDrawerHeaderBreadcrumbTitle
            mobileIcon={"user"}
            parts={["Members", memberGroup.name || ""]}
            truncatedParts={{ 0: false, 1: true }}
          />
        )
      }
    />
  )

  function mapGroupMembersToAvatarUsers(): UserAvatarShape[] {
    const users: ProfileListItemType[] = []
    for (const member of memberships) {
      // Filter out org owners/admins in a product w/o membership
      if (!member.productMembership && memberGroup?.productId) continue

      // If the user is an owner/admin/manager/instructor, add the admin prop to the profile
      if (
        member.productMembership?.role === "manager" ||
        member.productMembership?.role === "instructor" ||
        member.organizationMembership?.role === "owner" ||
        member.organizationMembership?.role === "admin"
      ) {
        users.push({ ...member.user, admin: true })
      } else {
        users.push(member.user)
      }
    }
    return users
  }
}

export default Relay.withSkeleton({
  component: MemberGroupProfileListDrawer,
  skeleton: () => null,
})
