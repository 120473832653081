import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import RecurringEventIcon from "@/product/course/event/components/RecurringEventIcon"
import { OccurrenceNameAndTimeFragment$key } from "@/product/course/event/components/__generated__/OccurrenceNameAndTimeFragment.graphql"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import useUserTimezone from "../../../../user/util/useUserTimezone"
import { DATE_FORMAT } from "../../../../utils/time/timeConstants"
import { formatDateWithOptions } from "../../../../utils/time/timeUtils"

interface OccurrenceNameAndTimeProps extends TestIDProps {
  occurrenceKey: OccurrenceNameAndTimeFragment$key
  classes?: ClassNameMap<"root">
  hideDate?: boolean
  hideRecurringIcon?: boolean
  variant?: "default" | "compact"
}

function OccurrenceNameAndTime({
  occurrenceKey,
  hideDate = false,
  classes: customClasses,
  hideRecurringIcon = false,
  variant = "default",
  testid = "OccurrenceNameAndTime",
}: OccurrenceNameAndTimeProps) {
  const userTimeZone = useUserTimezone()
  const classes = useStyles({ variant })

  const occurrence = useFragment<OccurrenceNameAndTimeFragment$key>(
    graphql`
      fragment OccurrenceNameAndTimeFragment on Occurrence {
        id
        name
        datetimeRange
        event {
          isRecurring
        }
      }
    `,
    occurrenceKey
  )

  return (
    <div data-testid={testid} className={classNames(classes.root, customClasses?.root)}>
      <div className={classes.titleWrapper}>
        <DiscoText
          variant={variant === "compact" ? "body-md-600" : "body-lg-600"}
          noWrap
          testid={`${testid}.title`}
          truncateText={2}
        >
          {occurrence.name}
        </DiscoText>
        {occurrence.event.isRecurring && !hideRecurringIcon && (
          <RecurringEventIcon
            testid={testid}
            size={variant === "compact" ? 15 : undefined}
          />
        )}
      </div>

      <DiscoText
        variant={variant === "compact" ? "body-xs-500" : "body-sm"}
        color={"text.secondary"}
        testid={`${testid}.date`}
      >
        {formatDateWithOptions({
          timeZone: userTimeZone,
          format: hideDate
            ? DATE_FORMAT.TIME_WITH_ZONE
            : DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
        })(new Date(occurrence.datetimeRange[0]))}
      </DiscoText>
    </div>
  )
}

type StyleProps = {
  variant?: "default" | "compact"
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ variant }: StyleProps) => ({
    flexGrow: 1,
    marginLeft: theme.spacing(variant === "compact" ? 1.5 : 3),
    overflow: "hidden",
    display: "grid",
    minWidth: "140px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  }),
  titleWrapper: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    overflow: "hidden",
  },
}))

export function OccurrenceNameAndTimeSkeleton() {
  const classes = useStyles({})
  return (
    <div className={classes.root}>
      <DiscoTextSkeleton width={"50%"} />
      <DiscoTextSkeleton width={"50%"} />
    </div>
  )
}

export default OccurrenceNameAndTime
