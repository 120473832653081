import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CheckmarkIcon from "@assets/iconsax/bold/tick-circle.svg"
import { DiscoButton, DiscoInput, DiscoText } from "@disco-ui"
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import { useQueryParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { KeyboardEvent, useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay/hooks"
import CloseIcon from "../../../../core/ui/iconsax/linear/custom-x.svg"
import CheckoutStore from "../../store/CheckoutStore"
import useUpdateCheckoutMutation from "../../store/useUpdateCheckoutMutation"
import { CheckoutFormDiscountInput_Checkout$key } from "./__generated__/CheckoutFormDiscountInput_Checkout.graphql"

interface Props {
  store: CheckoutStore
}

type CheckoutFormDiscountInputParamsShape = {
  /** Discount code from landing page */
  c?: string
}

const CheckoutFormDiscountInput = observer<Props>(({ store }) => {
  const discountCode = useQueryParams<CheckoutFormDiscountInputParamsShape>().c?.trim()
  const [unappliedDiscountCode, setUnappliedDiscountCode] = useState(discountCode || "")

  const checkout = useFragment<CheckoutFormDiscountInput_Checkout$key>(
    graphql`
      fragment CheckoutFormDiscountInput_Checkout on Checkout {
        quote {
          discount {
            id
            code
          }
        }
      }
    `,
    toJS(store.checkout || null)
  )

  useEffect(() => {
    /** If there is no discount applied */
    if (discountCode && !checkout?.quote.discount) {
      /** Apply the discount code that was passed from the landing page */
      handleClickApply()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCode, checkout?.quote.discount])

  const updateCheckout = useUpdateCheckoutMutation(store)

  const classes = useStyles()

  const theme = useTheme()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  // Success state
  if (checkout?.quote.discount) {
    return (
      <div className={classes.appliedBox}>
        <div className={classes.appliedCode}>
          <DiscoText
            variant={"body-sm"}
            aria-label={"Applied Discount code"}
            data-testid={"CheckoutFormDiscountInput.applied-code"}
          >
            {checkout.quote.discount.code}
          </DiscoText>
          <IconButton
            onClick={handleRemoveDiscountCode}
            size={"small"}
            aria-label={"Remove Discount"}
            data-testid={"CheckoutFormDiscountInput.remove-discount-button"}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.appliedCheckMark}>
          {!isXsDown && <DiscoText variant={"body-sm"}>{"Applied"}</DiscoText>}
          <CheckmarkIcon width={20} />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.input}>
      <DiscoInput
        data-testid={"CheckoutForm.discount-input"}
        placeholder={"Enter Discount"}
        className={classNames(classes.discountInput, {
          [classes.hasValue]: unappliedDiscountCode,
        })}
        value={unappliedDiscountCode}
        onChange={(e) => setUnappliedDiscountCode(e.target.value)}
        onKeyDown={handleKeyDown}
      />

      <DiscoButton
        disabled={!unappliedDiscountCode || store.isSubmitting}
        onClick={handleClickApply}
        size={"large"}
      >
        {"Apply"}
      </DiscoButton>
    </div>
  )

  function handleClickApply() {
    if (!unappliedDiscountCode) return
    updateCheckout({ discountCode: unappliedDiscountCode })
  }

  function handleRemoveDiscountCode() {
    setUnappliedDiscountCode("")
    updateCheckout({ discountCode: "" })
  }

  /** Make clicking enter in the discount input submit the discount code */
  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      // Prevent enter key within discount input from submitting the checkout form
      e.preventDefault()
      handleClickApply()
    }
  }
})

export default CheckoutFormDiscountInput

const useStyles = makeUseStyles((theme) => ({
  appliedBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "48px",
    padding: "8px 12px 8px 16px",

    background: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.default,
  },
  appliedCode: {
    display: "flex",
    alignItems: "center",
    padding: "6px 8px",
    background: "white",
    borderRadius: "4px",
    "& button": {
      marginLeft: "8px",
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
  },
  appliedCheckMark: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.success.main,
    "& p": {
      marginTop: "2px",
    },

    "& svg": {
      marginLeft: "6px",
    },
  },
  discountInput: {
    position: "relative",
    width: "100%",
  },
  hasValue: {
    "&:focus-within": {},
  },
  input: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))
