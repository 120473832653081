import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import SelectGroupMembersListItem from "@/product/common/member-group/modal/components/SelectGroupMembersListItem"
import { SelectGroupMembersListFragment$key } from "@/product/common/member-group/modal/components/__generated__/SelectGroupMembersListFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface SelectGroupMembersList {
  productKey: SelectGroupMembersListFragment$key
  selectedMembershipIds: GlobalID[]
  setSelectedMembershipIds: React.Dispatch<React.SetStateAction<GlobalID[]>>
}

function SelectGroupMembersList({
  productKey,
  selectedMembershipIds,
  setSelectedMembershipIds,
}: SelectGroupMembersList) {
  const classes = useStyles()

  const response = useFragment<SelectGroupMembersListFragment$key>(
    graphql`
      fragment SelectGroupMembersListFragment on Product
      @argumentDefinitions(membershipIds: { type: "[ID!]" }) {
        productMemberships(ids: $membershipIds) {
          edges {
            node {
              id
              ...SelectGroupMembersListItemFragment
            }
          }
        }
      }
    `,
    productKey
  )
  const productMemberships = Relay.connectionToArray(response.productMemberships)

  return (
    <div className={classes.selectedMembershipsList}>
      {productMemberships.map((productMembership, index) => {
        return (
          <div key={productMembership.id}>
            <SelectGroupMembersListItem
              testid={`SelectGroupMembersList.item-${index}`}
              productMembershipKey={productMembership}
              selectedMembershipIds={selectedMembershipIds}
              handleSelectMember={handleSelectMember}
            />
          </div>
        )
      })}
    </div>
  )

  function handleSelectMember(v: boolean, membershipId: string) {
    if (v) {
      setSelectedMembershipIds([...selectedMembershipIds, membershipId])
    } else {
      setSelectedMembershipIds((prev) => {
        return prev.filter((pm) => pm !== membershipId)
      })
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  selectedMembershipsList: {
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
    padding: theme.spacing(0.75, 2.5),
    marginBottom: theme.spacing(2.5),
    marginLeft: "3px",
    width: "calc(100% - 6px)",
    maxHeight: "188px",
    overflowY: "auto",
  },
}))

export default SelectGroupMembersList
