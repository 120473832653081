import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import { DiscoText } from "@disco-ui"
import { Grid, Theme, useMediaQuery } from "@material-ui/core"
import { FC } from "react"

interface Props {
  store: CheckoutStore
}

const CheckoutFormInvitationSection: FC<Props> = ({ store }) => {
  const classes = useStyles()
  const experienceLabel = useLabel("experience")
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  if (!store.checkout?.invitation) return null
  const { invitation } = store.checkout
  const senderFullName = invitation.createdByUser.fullName

  return (
    <>
      <Grid
        container
        spacing={2}
        wrap={"nowrap"}
        data-testid={"CheckoutFormInvitationSection.container"}
        className={classes.container}
      >
        <Grid item container alignItems={"center"}>
          <DiscoText
            variant={isXsScreen ? "body-lg-600" : "heading-md"}
            testid={"CheckoutFormInvitationSection.message"}
          >
            {getInvitationHeaderText()}
          </DiscoText>
          <DiscoText marginTop={1} variant={"body-sm"} color={"text.secondary"}>
            {"Create an account to continue."}
          </DiscoText>
        </Grid>
      </Grid>
      {invitation.kind === "gift" && (
        <div className={classes.customMessage}>
          <DiscoText
            variant={"body-lg-600"}
            marginBottom={2}
          >{`From ${senderFullName}:`}</DiscoText>
          <DiscoText className={classes.customMessageContent}>
            {`"${invitation.message}"`}
          </DiscoText>
        </div>
      )}
    </>
  )

  function getInvitationHeaderText() {
    switch (invitation.kind) {
      case "member":
        return `You have been invited to join this ${experienceLabel.singular}!`
      case "gift":
        return `You've been gifted access to this ${experienceLabel.singular}! ✨`
      default:
        return ""
    }
  }
}

export default CheckoutFormInvitationSection

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  customMessage: {
    marginBottom: theme.spacing(4),
    whiteSpace: "pre-wrap",
  },
  customMessageContent: {
    borderRadius: theme.measure.borderRadius.big,
    background: theme.palette.groovy.neutral[100],
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
}))
