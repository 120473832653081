import ContentFlaggingListItem from "@/product/common/page/content/flagging/popover/ContentFlaggingListItem"
import { ContentFlaggingListPopoverFragment$key } from "@/product/common/page/content/flagging/popover/__generated__/ContentFlaggingListPopoverFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider } from "@disco-ui"
import { Popover } from "@material-ui/core"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  contentKey: ContentFlaggingListPopoverFragment$key
  isOpen: boolean
  onClose(): void
  anchorEl: HTMLElement
}

function ContentFlaggingListPopover(props: Props) {
  const { contentKey, isOpen, onClose, anchorEl } = props

  const content = useFragment<ContentFlaggingListPopoverFragment$key>(
    graphql`
      fragment ContentFlaggingListPopoverFragment on Content {
        flaggings {
          edges {
            node {
              id
              ...ContentFlaggingListItemFragment
            }
          }
        }
      }
    `,
    contentKey
  )

  const flaggings = Relay.connectionToArray(content.flaggings)

  const classes = useStyles()

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      classes={{ paper: classes.popoverPaper }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <ul className={classes.popoverList}>
        {flaggings.map((flagging, i) => (
          <React.Fragment key={flagging.id}>
            {i > 0 && <DiscoDivider marginTop={2} marginBottom={2} />}
            <ContentFlaggingListItem
              contentFlaggingKey={flagging}
              testid={`ContentFlaggingListPopover.${i}`}
            />
          </React.Fragment>
        ))}
      </ul>
    </Popover>
  )
}

const useStyles = makeUseStyles((theme) => ({
  popoverPaper: {
    width: "300px",
    // Roughly 3 flaggings visible + scroll.
    maxHeight: "350px",
    padding: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.medium,
    boxShadow: theme.palette.groovyDepths.boxShadow,
  },
  popoverList: {
    listStyleType: "none",
  },
}))

export default ContentFlaggingListPopover
