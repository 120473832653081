import { MemberGroupMembershipTypeOption } from "@/product/common/member-group/common/MembershipTypeFilter"
import { AdminMemberGroupTableRowFragment$key } from "@/product/common/member-group/common/__generated__/AdminMemberGroupTableRowFragment.graphql"
import { UserAvatarStackSkeleton } from "@/user/common/avatar-stack/UserAvatarStack"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoChip,
  DiscoIcon,
  DiscoIconButton,
  DiscoIconButtonSkeleton,
  DiscoTable,
} from "@disco-ui"
import { DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"
import { TableCell, capitalize } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { toJS } from "mobx"
import { graphql, useFragment } from "react-relay"

interface MemberGroupTableRowProps {
  membershipKey: AdminMemberGroupTableRowFragment$key
  filter?: MemberGroupMembershipTypeOption["value"] | null
  removeMember: () => void
  index: number
}

function AdminMemberGroupTableRow({
  membershipKey,
  filter,
  removeMember,
  index,
}: MemberGroupTableRowProps) {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const membership = useFragment<AdminMemberGroupTableRowFragment$key>(
    graphql`
      fragment AdminMemberGroupTableRowFragment on MemberGroupMembership {
        id
        organizationMembership {
          role
          email
        }
        productMembership {
          role
          email
        }
        user {
          id
          fullName
          ...ProfileAvatarWithDetailsFragment
        }
      }
    `,
    toJS(membershipKey)
  )

  // Translate organization/product role to group membership type
  let membershipType =
    membership.organizationMembership.role === "member" ? "member" : "admin"
  if (membership.productMembership) {
    membershipType = membership.productMembership.role === "member" ? "member" : "admin"
  }

  if (filter && membershipType !== filter) return null

  const TESTID = `AdminMemberGroupTableRow.${membership.user.fullName}`

  return (
    <div
      data-testid={TESTID}
      className={classes.row}
      data-userflowid={`AdminMemberGroupTableRow.${index}`}
    >
      <ProfileAvatarWithDetails
        userKey={membership.user}
        testid={TESTID}
        details={
          membership.productMembership?.email || membership.organizationMembership.email
        }
        linkToProfile={false}
      />
      <div className={classes.rhs}>
        {!isMobile && (
          <DiscoChip
            label={capitalize(membershipType)}
            color={membershipType === "admin" ? "blue" : "grey"}
            testid={`${TESTID}-type`}
          />
        )}

        <DiscoIconButton onClick={removeMember} testid={`${TESTID}-remove-member`}>
          <DiscoIcon icon={"close"} />
        </DiscoIconButton>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: theme.measure.borderRadius.large,
    padding: theme.spacing(0.5),
    width: "100%",
    alignItems: "center",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
    },
  },
  rhs: {
    display: "flex",
    gap: theme.spacing(23),
    alignItems: "center",
  },
}))

export function AdminMemberGroupTableRowSkeleton() {
  return (
    <DiscoTable.Row>
      <TableCell width={"50%"}>
        <UserAvatarStackSkeleton stackSize={1} avatarSize={40} />
      </TableCell>
      <TableCell>
        <DiscoTagSkeleton />
      </TableCell>
      <TableCell>
        <DiscoIconButtonSkeleton />
      </TableCell>
    </DiscoTable.Row>
  )
}

export default AdminMemberGroupTableRow
