/**
 * @generated SignedSource<<1234d39607f750e81d09cea1aeb0cf19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCanFlagContentFragment$data = {
  readonly viewerHasFlagged: boolean;
  readonly createdBy: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "useCanFlagContentFragment";
};
export type useCanFlagContentFragment$key = {
  readonly " $data"?: useCanFlagContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCanFlagContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCanFlagContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasFlagged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "5d25c45774364dd53dc889d75aa7ec03";

export default node;
