/**
 * @generated SignedSource<<e1a1274c7ac6bf15dc225c606b629cde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductWithDetailsFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly startsAt: string | null;
  readonly endsAt: string | null;
  readonly type: ProductType;
  readonly slug: string;
  readonly pricing: {
    readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
  } | null;
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly cover: string;
  readonly " $fragmentSpreads": FragmentRefs<"ProductTypeTagFragment">;
  readonly " $fragmentType": "ProductWithDetailsFragment";
};
export type ProductWithDetailsFragment$key = {
  readonly " $data"?: ProductWithDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductWithDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductWithDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "pricingUtils_usePricingDisplayValue"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductTypeTagFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "eca1871725d65967780bfb2353938c99";

export default node;
