import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import EditEmailTemplateForm from "@/product/course/settings/notifications-settings/email/form/EditEmailTemplateForm"
import { EditEmailTemplateFormFragment$key } from "@/product/course/settings/notifications-settings/email/form/__generated__/EditEmailTemplateFormFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer, DiscoDrawerHeader } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"

interface Props {
  children: OverridableDiscoButtonChildren
  emailTemplateKey: EditEmailTemplateFormFragment$key
}

const EditEmailTemplateDrawerButton: React.FC<Props> = (props) => {
  const { children, emailTemplateKey } = props
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const classes = useStyles()
  const modal = useInitUnsavedChangesModalContext()

  return (
    <>
      <OverridableDiscoButton onClick={openDrawer}>{children}</OverridableDiscoButton>

      <UnsavedChangesModalProvider {...modal}>
        <DiscoDrawer
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
          containerClasses={{ drawerContainer: classes.drawer }}
          title={"Edit Email Template"}
          size={"large"}
          maxSize={"xl"}
        >
          <DiscoDrawerHeader
            testid={"EditEmailTemplateDrawerHeader"}
            disableExpand
            title={"Edit EmailTemplate"}
            onClose={handleCloseDrawer}
            className={classes.header}
          />
          <EditEmailTemplateForm
            emailTemplateKey={emailTemplateKey}
            onCancel={handleCloseDrawer}
            testid={"EditEmailTemplateForm"}
          />
        </DiscoDrawer>
      </UnsavedChangesModalProvider>
    </>
  )

  function openDrawer() {
    setIsDrawerOpen(true)
  }

  function handleCloseDrawer() {
    // Check that there are no unsaved changes before closing
    modal.handleLeave({
      onLeave: () => setIsDrawerOpen(false),
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  drawer: {
    padding: 0,
  },
  header: {
    padding: theme.spacing(1.5, 3, 0, 3),
  },
}))

export default EditEmailTemplateDrawerButton
