/**
 * @generated SignedSource<<c659ff598f19ea12511f2b0f39bd44c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectGroupMembersListItemFragment$data = {
  readonly id: string;
  readonly member: {
    readonly id: string;
    readonly avatar: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
  };
  readonly " $fragmentType": "SelectGroupMembersListItemFragment";
};
export type SelectGroupMembersListItemFragment$key = {
  readonly " $data"?: SelectGroupMembersListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectGroupMembersListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectGroupMembersListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "member",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};
})();

(node as any).hash = "78cb65591b0c3de37c17969f998a5119";

export default node;
