/**
 * @generated SignedSource<<fe856cedc0ea0878312f02c35f33d49d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckoutFormDiscountInput_Checkout$data = {
  readonly quote: {
    readonly discount: {
      readonly id: string;
      readonly code: string | null;
    } | null;
  };
  readonly " $fragmentType": "CheckoutFormDiscountInput_Checkout";
};
export type CheckoutFormDiscountInput_Checkout$key = {
  readonly " $data"?: CheckoutFormDiscountInput_Checkout$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutFormDiscountInput_Checkout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckoutFormDiscountInput_Checkout",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutQuote",
      "kind": "LinkedField",
      "name": "quote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Discount",
          "kind": "LinkedField",
          "name": "discount",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Checkout",
  "abstractKey": null
};

(node as any).hash = "92c067c490aacba6b1960e615ca28942";

export default node;
