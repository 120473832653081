import useUserTimezone from "@/user/util/useUserTimezone"
import { displayGraphQLErrorToast } from "@components/toast/ToastProvider"
import { useCallback } from "react"
import { graphql } from "relay-runtime"
import Relay from "../../../relay/relayUtils"
import CheckoutStore from "./CheckoutStore"
import { useUpdateCheckoutMutation_Mutation } from "./__generated__/useUpdateCheckoutMutation_Mutation.graphql"

/** Returns a callback function to execute an updateCheckout mutation */
export default function useUpdateCheckoutMutation(store: CheckoutStore) {
  const commitMutation = Relay.useAsyncMutation<useUpdateCheckoutMutation_Mutation>(
    graphql`
      mutation useUpdateCheckoutMutation_Mutation(
        $id: ID!
        $profile: CheckoutProfileInput
        $discountCode: String
        $paymentMethodId: String
        $paymentIntentId: String
        $applicationAnswers: [String!]
        $organizationFormAnswers: [String!]
      ) {
        updateCheckout(
          id: $id
          profile: $profile
          discountCode: $discountCode
          paymentMethodId: $paymentMethodId
          paymentIntentId: $paymentIntentId
          applicationAnswers: $applicationAnswers
          organizationFormAnswers: $organizationFormAnswers
        ) {
          node {
            id
            ...CheckoutStore_Checkout
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  const userTimezone = useUserTimezone()

  const updateCheckout = useCallback(
    async ({
      discountCode,
      paymentMethodId,
      paymentIntentId,
    }: Partial<
      Pick<
        useUpdateCheckoutMutation_Mutation["variables"],
        "discountCode" | "paymentMethodId" | "paymentIntentId"
      >
    > = {}) => {
      if (!store.checkout) {
        throw new Error("Missing store.checkout to updateCheckout")
      }
      store.isSubmitting = true
      const res = await commitMutation({
        profile: {
          userId: store.profile.userId,
          email: store.profile.email,
          firstName: store.profile.firstName,
          lastName: store.profile.lastName,
          timezone: userTimezone,
        },
        discountCode,
        paymentMethodId,
        paymentIntentId,
        applicationAnswers: store.applicationAnswers.slice(),
        organizationFormAnswers: store.organizationFormAnswers.slice(),
        id: store.checkout.id,
      })
        .catch((err) => {
          displayGraphQLErrorToast(err)
        })
        .finally(() => (store.isSubmitting = false))
      if (!res) return false
      if (res.updateCheckout.errors) {
        displayGraphQLErrorToast(res.updateCheckout.errors[0])
        return false
      }
      return true
    },
    [store, commitMutation, userTimezone]
  )

  return updateCheckout
}
