import FlagContentForm from "@/product/common/page/content/flagging/FlagContentForm"
import useCanFlagContent from "@/product/common/page/content/flagging/popover/useCanFlagContent"
import { FlagContentButtonFragment$key } from "@/product/common/page/content/flagging/__generated__/FlagContentButtonFragment.graphql"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useCallback } from "react"
import { graphql, useFragment } from "react-relay"

interface Props extends OverridableDiscoButtonProps {
  contentKey: FlagContentButtonFragment$key
}

function FlagContentButton(props: Props) {
  const { contentKey, children, ...rest } = props

  const content = useFragment<FlagContentButtonFragment$key>(
    graphql`
      fragment FlagContentButtonFragment on Content {
        ...useCanFlagContentFragment
        ...FlagContentFormFragment
      }
    `,
    contentKey
  )

  const ModalComponent = useCallback(
    (modalProps) => (
      <DiscoModal
        {...modalProps}
        title={"Flag Post"}
        modalContentLabel={"Flag Post"}
        testid={"FlagContentModal"}
        body={<FlagContentForm contentKey={content} onClose={modalProps.onClose} />}
        buttons
      />
    ),
    [content]
  )

  const canFlag = useCanFlagContent(content)
  if (!canFlag) return null

  return (
    <OverridableDiscoButton {...rest} modal={ModalComponent}>
      {children}
    </OverridableDiscoButton>
  )
}

export default FlagContentButton
