import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { CheckoutFormApplicationSection_Checkout$key } from "@/product/checkout/form/application/__generated__/CheckoutFormApplicationSection_Checkout.graphql"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoFormControl, DiscoFormLabel, DiscoTextField } from "@disco-ui"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"
interface Props {
  store: CheckoutStore
}

const CheckoutFormApplicationSection = ({ store }: Props) => {
  const classes = useStyles()
  const checkout = useFragment<CheckoutFormApplicationSection_Checkout$key>(
    graphql`
      fragment CheckoutFormApplicationSection_Checkout on Checkout {
        application {
          questions {
            id
            richEditorBody
          }
        }
      }
    `,
    toJS(store.checkout || null)
  )

  if (!checkout?.application) return null

  return (
    <>
      {checkout.application.questions.map((question, i) => (
        <DiscoFormControl key={question.id}>
          <DiscoFormLabel data-testid={`CheckoutFormApplicationSection.question-${i}`}>
            <DiscoEditor
              className={classes.editor}
              defaultValue={question.richEditorBody}
              readOnly
              disableGutter
            />
          </DiscoFormLabel>
          <DiscoTextField
            multiline
            rows={6}
            value={store.applicationAnswers[i]}
            onChange={(e) => (store.applicationAnswers[i] = e.target.value)}
            data-testid={`CheckoutFormApplicationSection.answer-${i}`}
          />
        </DiscoFormControl>
      ))}
    </>
  )
}

const useStyles = makeUseStyles({
  editor: {
    width: "100%",
  },
})

export default observer(CheckoutFormApplicationSection)
