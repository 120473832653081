import { DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"

interface Props {
  className?: string
  onClick: () => void
  color?: string
  testid: string
  tooltipContent?: string
}

function ViewAsIconButton({
  className,
  onClick,
  color,
  testid,
  tooltipContent,
}: Props): JSX.Element {
  return (
    <DiscoTooltip disabled={!tooltipContent} content={tooltipContent || ""}>
      <DiscoIconButton
        className={className}
        onClick={onClick}
        color={color}
        testid={`${testid}.view-as-icon-button`}
      >
        <DiscoIcon icon={"iconsax.eye"} />
      </DiscoIconButton>
    </DiscoTooltip>
  )
}

export default ViewAsIconButton
