import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MembershipPlanSelectButton from "@/membership-plan/register/MembershipPlanSelectButton"
import RedirectToSignInLink from "@/user/common/prompt-to-sign-in-link/PromptToSignInLink"
import { DiscoButton, DiscoDivider, DiscoText } from "@disco-ui"
import Link from "@material-ui/core/Link"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { generatePath, Link as RouterLink } from "react-router-dom"
import { graphql } from "relay-runtime"
import ROUTE_NAMES from "../../../../core/route/util/routeNames"
import CheckoutFormProfileAuthUserSection from "./CheckoutFormProfileAuthUserSection"
import { CheckoutFormRegistrationErrorStep_Product$key } from "./__generated__/CheckoutFormRegistrationErrorStep_Product.graphql"

interface Props {
  icon?: JSX.Element
  errorMessage: string
  title: string
  authUserLabel: string
  productKey?: CheckoutFormRegistrationErrorStep_Product$key
}

const CheckoutFormRegistrationErrorStep = observer<Props>((props) => {
  const { errorMessage, title, authUserLabel, productKey, icon } = props

  const activeOrganization = useActiveOrganization()!
  const { authUser } = useAuthUser()
  const classes = useStyles()

  const product = useFragment<CheckoutFormRegistrationErrorStep_Product$key>(
    graphql`
      fragment CheckoutFormRegistrationErrorStep_Product on Product {
        type
        slug
        landingPageUrl
        registrationType
        waitlistUrl
        waitlistCtaLabel
        viewerMembership {
          id
        }
      }
    `,
    productKey || null
  )

  if (icon) {
    return (
      <div className={classes.container}>
        <div className={classes.iconContainer}>{icon}</div>
        <DiscoText marginTop={2} align={"center"} variant={"heading-md"} marginBottom={1}>
          {title}
        </DiscoText>
        {authUser && <CheckoutFormProfileAuthUserSection label={authUserLabel} />}
        <div className={classes.errorMessageContainer}>
          <DiscoText
            variant={"body-sm"}
            color={"text.secondary"}
            testid={"CheckoutFormRegistrationErrorStep.message"}
            marginBottom={11}
          >
            {errorMessage}
          </DiscoText>
          <div>{renderBackLink()}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <DiscoText variant={"heading-md"} marginBottom={1}>
        {title}
      </DiscoText>
      <DiscoDivider marginBottom={3} />
      {authUser && <CheckoutFormProfileAuthUserSection label={authUserLabel} />}
      <div className={classes.errorMessageContainer}>
        <DiscoText
          variant={"body-md-600"}
          color={"error.main"}
          marginTop={10}
          marginBottom={1}
          testid={"CheckoutFormRegistrationErrorStep.message"}
        >
          {errorMessage}
        </DiscoText>
        {renderBackLink()}
      </div>
    </>
  )

  function renderBackLink() {
    if (!product) {
      if (activeOrganization.visibility === "private" && !authUser) {
        return (
          <RedirectToSignInLink testid={"CheckoutFormRegistrationErrorStep.login-link"} />
        )
      }
      return (
        <DiscoButton
          component={RouterLink}
          to={ROUTE_NAMES.COMMUNITY.HOME.ROOT}
          data-testid={"CheckoutFormRegistrationErrorStep.community-link"}
        >
          {`Go to ${activeOrganization.name}`}
        </DiscoButton>
      )
    }

    if (product.viewerMembership) {
      if (product.type === "membership_plan") {
        return (
          <MembershipPlanSelectButton>
            {(buttonProps) => (
              <DiscoButton
                {...buttonProps}
                color={"grey"}
                variant={"outlined"}
                testid={"CheckoutFormRegistrationErrorStep.change-membership-plan.button"}
              >
                {"Change Plan"}
              </DiscoButton>
            )}
          </MembershipPlanSelectButton>
        )
      }
      return (
        <DiscoButton
          component={RouterLink}
          to={generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug: product.slug,
          })}
          data-testid={"CheckoutFormRegistrationErrorStep.overview-link"}
        >
          {"View More"}
        </DiscoButton>
      )
    }

    if (product.registrationType === "waitlist" && product.waitlistUrl) {
      return (
        <DiscoButton
          component={Link}
          underline={"none"}
          target={"_blank"}
          href={product.waitlistUrl}
          rel={"noreferrer noopener"}
          color={"primary"}
          data-testid={"CheckoutFormRegistrationErrorStep.join-the-waitlist.button"}
        >
          {product.waitlistCtaLabel || "Join the Waitlist"}
        </DiscoButton>
      )
    }

    return null
  }
})

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.large,
    border: theme.palette.constants.borderLarge,
    padding: theme.spacing(11, 4, 2),
  },
  iconContainer: {
    width: "45px",
    height: "45px",
    borderRadius: "100%",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
  },
  errorMessageContainer: {
    textAlign: "center",
  },
}))

export default CheckoutFormRegistrationErrorStep
