/**
 * @generated SignedSource<<e1205a926dac7d8a8ed8d7873cb66b27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type OrganizationRole = "owner" | "admin" | "member" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberGroupProfileListDrawer_PaginationFragment$data = {
  readonly memberGroupMemberships: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly organizationMembership: {
          readonly role: OrganizationRole;
        };
        readonly productMembership: {
          readonly role: ProductRole;
        } | null;
        readonly user: {
          readonly id: string;
          readonly avatar: string;
          readonly first_name: string | null;
          readonly last_name: string | null;
        };
      };
    }>;
    readonly pageInfo: {
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
    readonly totalCount: number;
  };
  readonly id: string;
  readonly " $fragmentType": "MemberGroupProfileListDrawer_PaginationFragment";
};
export type MemberGroupProfileListDrawer_PaginationFragment$key = {
  readonly " $data"?: MemberGroupProfileListDrawer_PaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupProfileListDrawer_PaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "memberGroupMemberships"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./MemberGroupProfileListDrawerPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "MemberGroupProfileListDrawer_PaginationFragment",
  "selections": [
    {
      "alias": "memberGroupMemberships",
      "args": null,
      "concreteType": "MemberGroupMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "__MemberGroupProfileListDrawer__memberGroupMemberships_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroupMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationMembership",
                  "kind": "LinkedField",
                  "name": "organizationMembership",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductMembership",
                  "kind": "LinkedField",
                  "name": "productMembership",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    },
                    {
                      "alias": "first_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": "last_name",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "MemberGroup",
  "abstractKey": null
};
})();

(node as any).hash = "959ba7e89221e095f9939545c9273d66";

export default node;
