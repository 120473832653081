import {
  InlineContentDrawerFormState,
  InlineContentDrawerMode,
} from "@/content-usage/drawer/InlineContentDrawerTemplate"
import ContentFormAttachmentsSection from "@/content/form/sections/ContentFormAttachmentsSection"
import { ContentFormStore } from "@/content/form/util/contentFormUtil"
import RequiredAsterisk from "@/core/form/RequiredAsterisk"
import FormStore from "@/core/form/store/FormStore"
import { SubmitAssignmentFormFieldsFragment$key } from "@/product/course/assignments/submission/__generated__/SubmitAssignmentFormFieldsFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import {
  DiscoFormControl,
  DiscoInputSkeleton,
  DiscoLinkInput,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import Grid from "@material-ui/core/Grid"
import { useTheme } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  form: ContentFormStore
  contentDrawerMode?: InlineContentDrawerMode
  contentForm?: FormStore<InlineContentDrawerFormState>
  contentUsageKey: SubmitAssignmentFormFieldsFragment$key | null
}

function SubmitAssignmentFormFields(props: Props) {
  const { form, contentForm, contentUsageKey, contentDrawerMode } = props

  const classes = useStyles()

  const contentUsage = useFragment<SubmitAssignmentFormFieldsFragment$key>(
    graphql`
      fragment SubmitAssignmentFormFieldsFragment on ContentUsage {
        id
        submissionVisibility
        submissionFormat {
          text {
            enabled
            required
          }
          file {
            enabled
            required
          }
          link {
            enabled
            required
          }
        }
      }
    `,
    contentUsageKey
  )

  const submissionFormat = getSubmissionFormat()

  return (
    <>
      {submissionFormat.text.enabled && (
        <DiscoFormControl
          label={
            <DiscoText variant={"body-sm-500"} color={"text.primary"}>
              {"Submission Body"}
              {submissionFormat.text.required && <RequiredAsterisk />}
            </DiscoText>
          }
          marginBottom={2}
          errorMessages={form.errorsByField.richEditorDescriptionContent}
          error={Boolean(form.errorsByField.richEditorDescriptionContent)}
        >
          <DiscoEditor
            testid={"SubmitAssignmentFormFields.body"}
            defaultValue={form.state.content.richEditorDescriptionContent}
            onChange={(v) => (form.state.content.richEditorDescriptionContent = v)}
            showOutline
            minHeight={100}
            className={classes.bodyEditor}
          />
        </DiscoFormControl>
      )}
      {submissionFormat.link.enabled && (
        <DiscoFormControl
          label={
            <DiscoText variant={"body-sm-500"} color={"text.primary"}>
              {"Link"}
              {submissionFormat.link.required && <RequiredAsterisk />}
            </DiscoText>
          }
          marginBottom={2}
          errorMessages={form.errorsByField.submissionLink}
          error={Boolean(form.errorsByField.submissionLink)}
        >
          <DiscoLinkInput
            className={classes.linkInput}
            value={form.state.content.submissionLink}
            onChange={(v) => (form.state.content.submissionLink = v)}
            placeholder={"www.your-site.com"}
            data-testid={"SubmitAssignmentFormFields.link"}
          />
        </DiscoFormControl>
      )}
      {submissionFormat.file.enabled && (
        <ContentFormAttachmentsSection
          form={form}
          label={
            <Grid item xs={12} sm={6} container alignItems={"center"}>
              <DiscoText variant={"body-sm-500"} color={"text.primary"}>
                {"File"}
                {submissionFormat.file.required && <RequiredAsterisk />}
              </DiscoText>
            </Grid>
          }
          dropzoneMessage={"Drop your file here or browse"}
          classes={{
            dropzone: classes.dropzone,
          }}
        />
      )}
    </>
  )

  function getSubmissionFormat() {
    if (
      contentDrawerMode !== "read" &&
      contentForm?.state.contentUsageInput?.submissionFormat
    ) {
      return contentForm.state.contentUsageInput.submissionFormat
    }
    if (contentUsage?.submissionFormat) {
      return contentUsage.submissionFormat
    }
    // Backwards compat for assignments without set format
    return {
      text: { enabled: true, required: false },
      link: { enabled: false, required: false },
      file: { enabled: true, required: false },
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  linkInput: {
    background: theme.palette.background.paper,
    borderColor: theme.palette.constants.divider,
    borderRadius: theme.measure.borderRadius.medium,
  },
  bodyEditor: {
    borderStyle: "solid",
    borderRadius: theme.measure.borderRadius.medium,
    borderColor: theme.palette.constants.divider,
    borderWidth: "1.5px",
  },
  dropzone: {
    background: theme.palette.background.paper,
  },
}))

export function SubmitAssignmentFormFieldsSkeleton() {
  const theme = useTheme()

  return (
    <>
      <DiscoFormControl label={<DiscoTextSkeleton variant={"body-sm-500"} width={80} />}>
        <Skeleton
          variant={"rect"}
          height={100}
          width={"100%"}
          style={{ borderRadius: theme.measure.borderRadius.medium }}
        />
      </DiscoFormControl>
      <DiscoFormControl label={<DiscoTextSkeleton variant={"body-sm-500"} width={80} />}>
        <DiscoInputSkeleton style={{ borderRadius: theme.measure.borderRadius.medium }} />
      </DiscoFormControl>
      <DiscoFormControl label={<DiscoTextSkeleton variant={"body-sm-500"} width={80} />}>
        <Skeleton
          variant={"rect"}
          height={149}
          width={"100%"}
          style={{ borderRadius: theme.measure.borderRadius.medium }}
        />
      </DiscoFormControl>
    </>
  )
}

export default observer(SubmitAssignmentFormFields)
