import { useAuthUser } from "@/core/context/AuthUserContext"
import { useCanFlagContentFragment$key } from "@/product/common/page/content/flagging/popover/__generated__/useCanFlagContentFragment.graphql"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

function useCanFlagContent(contentKey: useCanFlagContentFragment$key | undefined | null) {
  const content = useFragment<useCanFlagContentFragment$key>(
    graphql`
      fragment useCanFlagContentFragment on Content {
        viewerHasFlagged
        createdBy {
          id
        }
      }
    `,
    contentKey ?? null
  )

  // Can't flag your own content
  const { authUser } = useAuthUser()
  if (!authUser) return false
  if (!content) return false
  if (content.createdBy?.id === authUser.id) return false

  // Can't flag twice
  if (content.viewerHasFlagged) return false

  return true
}

export default useCanFlagContent
