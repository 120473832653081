import {
  ActiveCloneProductModalContextValue,
  CloneCourseEvent,
  CloneProductInput,
} from "@/product/clone/sequence/CloneProductModalContext"
import { CloneCourseEventOption } from "@/product/clone/sequence/__generated__/CloneProductModalContextMutation.graphql"
import { format, isBefore } from "date-fns"
import { IObservableArray } from "mobx"

namespace CloneProductFormUtils {
  export function validate(form: ActiveCloneProductModalContextValue["form"]) {
    form.removeErrors()
    /**
     * "general_settings" validations
     */
    if (!form.state.name)
      form.addError({ field: "name", message: "Experience name is required." })
    if (
      form.state.startDate &&
      // if the startDate is before today's date at midnight UTC, we do not allow creation.
      isBefore(new Date(form.state.startDate), new Date(format(new Date(), "yyyy-MM-dd")))
    )
      form.addError({
        field: "startDate",
        message: "Your Experience cannot start in the past",
      })
    // event and curriculum module validations are done in their respective edit modals.
    // We should not have to check for errors here (although it would be more safe to do a second check)
    if (form.errors.length) return false
    return true
  }

  export function formatInput(
    form: ActiveCloneProductModalContextValue["form"]
  ): CloneProductInput {
    const events = form.state.includeEvents ? formatEventInput(form.state.events) : []

    return {
      productId: form.state.productId,
      name: form.state.name,
      badge: form.state.badge,
      startDate: form.state.startDate,
      includeCollections: form.state.includeCollections,
      includeManagers: form.state.includeManagers,
      includeInstructors: form.state.includeInstructors,
      includeChatChannels: form.state.includeChatChannels,
      events,
      curriculumContentModuleUsages: form.state.includeCurriculum
        ? // map items to object containing only the keys we need
          form.state.curriculumContentModuleUsages?.map(
            ({ sourceId, releasedAt, contentUsages }) => ({
              sourceId,
              releasedAt,
              contentUsages,
            })
          )
        : [],
    }
  }

  export function formatEventInput(
    events?: IObservableArray<CloneCourseEvent> | null
  ): CloneCourseEventOption[] {
    if (!events) return []

    // get array of unique event ids
    const eventIds = events
      .map(({ eventId }) => eventId)
      .filter((id, index, arr) => arr.indexOf(id) === index)

    return eventIds.map((eventId) => {
      const occurrences = events.filter((event) => event.eventId === eventId)

      return {
        sourceId: eventId,
        occurrences: occurrences.map((o) => ({
          sourceId: o.occurrenceId,
          startDatetime: o.startDatetime,
          duration: o.duration,
          timezone: o.timezone,
        })),
      }
    })
  }
}

export default CloneProductFormUtils
