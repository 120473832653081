import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CardForm from "@/payment/card/CardForm"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import { observer } from "mobx-react-lite"

interface Props {
  store: CheckoutStore
}

const CheckoutFormPaymentMethodForm = observer<Props>(({ store }) => {
  const { newPaymentMethod } = store.payment

  const classes = useStyles()

  return (
    <CardForm
      customClassName={classes.form}
      onCardHolderNameChange={(e) =>
        (newPaymentMethod.cardHolderName = e.currentTarget.value)
      }
      // For now, only support entering a new CC each time
      // onSaveCardCheckboxSelect={() =>
      //   (newPaymentMethod.saveToUser = !newPaymentMethod.saveToUser)
      // }
      onPostalCodeChange={(e) => (newPaymentMethod.postalCode = e.currentTarget.value)}
      cardFormState={{
        cardHolderName: newPaymentMethod.cardHolderName,
        isSaveCardCheckboxSelected: newPaymentMethod.saveToUser,
        postalCode: newPaymentMethod.postalCode,
      }}
    />
  )
})

export default CheckoutFormPaymentMethodForm

const useStyles = makeUseStyles({
  form: {
    maxWidth: "100% !important",
  },
})
