import { useLabel } from "@/core/context/LabelsContext"
import {
  CloneProductModalKind,
  useActiveCloneProductModalContext,
} from "@/product/clone/sequence/CloneProductModalContext"
import { DiscoButton, DiscoModal, DiscoSpinner, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { observer } from "mobx-react-lite"

const SUCCESS_KIND: Extract<"success", CloneProductModalKind> = "success"

function CloneProductModalStepSuccess() {
  const { isOpen, handleClose } = useActiveCloneProductModalContext(SUCCESS_KIND)!
  const experienceLabel = useLabel("experience")
  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={handleClose}
      title={""}
      testid={"CloneProductModalStepSuccess"}
      modalContentLabel={""}
      width={"550px"}
      fullWidthButtons
      body={
        <Grid container justifyContent={"center"}>
          <DiscoSpinner size={"md"} />
          <DiscoText variant={"body-lg-600"} align={"center"} marginTop={5}>
            {"Cloning in progress..."}
          </DiscoText>
          <DiscoText align={"center"} marginTop={1} style={{ maxWidth: "340px" }}>
            {`You can keep working while we get your new ${experienceLabel.singular} ready. We'll notify you here and send you an email when it's done.`}
          </DiscoText>
        </Grid>
      }
      buttons={
        <Grid container justifyContent={"center"} style={{ width: "100%" }}>
          <DiscoButton onClick={handleClose}>{"Keep Working!"}</DiscoButton>
        </Grid>
      }
    />
  )
}

export default observer(CloneProductModalStepSuccess)
