import { CloneProductModalProvider } from "@/product/clone/sequence/CloneProductModalContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"

type CloneProductButtonProps = {
  children: OverridableDiscoButtonChildren
  productId: GlobalID
}

function CloneProductButton(props: CloneProductButtonProps) {
  const { children, productId } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsOpen(true)}>
        {children}
      </OverridableDiscoButton>
      {isOpen && (
        <CloneProductModalProvider
          productId={productId}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  )
}

export default Relay.withSkeleton({
  skeleton: () => <DiscoButtonSkeleton width={"48px"} />,
  component: CloneProductButton,
})
