import { useActiveProduct } from "@/core/context/ActiveProductContext"
import MetaDetails from "@/main/page/MetaDetails"
import React from "react"

interface ProductPageMetaDetailsProps {
  metaTitle?: string
  children: React.ReactNode
}

function ProductPageMetaDetails(props: ProductPageMetaDetailsProps) {
  const { metaTitle, children } = props
  const activeProduct = useActiveProduct()

  return (
    <MetaDetails
      metaTitle={metaTitle || activeProduct?.name}
      metaDetailsForSharing={{
        title: activeProduct?.name,
        url: window.location.href,
        description: activeProduct?.description || "",
        coverPhoto: activeProduct?.cover || "",
      }}
    >
      {children}
    </MetaDetails>
  )
}

export default ProductPageMetaDetails
