import { useLabel } from "@/core/context/LabelsContext"
import { useActiveCloneProductModalContext } from "@/product/clone/sequence/CloneProductModalContext"
import { CloneContentUsageInput } from "@/product/clone/sequence/__generated__/CloneProductModalContextMutation.graphql"
import CloneCurriculumItemDueDateItem from "@/product/clone/sequence/modal/curriculum-modules/CloneCurriculumItemDueDateItem"
import CloneProductEditModuleDetails from "@/product/clone/sequence/modal/curriculum-modules/CloneProductEditModuleDetails"
import { CloneCurriculumModalStepDueDatesFragment$key } from "@/product/clone/sequence/modal/curriculum-modules/__generated__/CloneCurriculumModalStepDueDatesFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoModal } from "@disco-ui"
import DiscoDatetimePicker from "@disco-ui/date/DiscoDatetimePicker"
import { getDifferenceInDaysBetweenDates } from "@utils/time/timeUtils"
import { addDays } from "date-fns/esm"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect } from "react"
import { graphql, useFragment } from "react-relay"

type CloneCurriculumModalStepDueDatesProps = {
  curriculumKey: CloneCurriculumModalStepDueDatesFragment$key | null
}

function CloneCurriculumModalStepDueDates({
  curriculumKey,
}: CloneCurriculumModalStepDueDatesProps) {
  const { form, isOpen, handleClose, handleNext } =
    useActiveCloneProductModalContext("item_due_dates")!
  const experienceLabel = useLabel("admin_experience")
  const curriculumLabel = useLabel("curriculum")
  const classes = useStyles()

  const sourceCurriculum = useFragment<CloneCurriculumModalStepDueDatesFragment$key>(
    graphql`
      fragment CloneCurriculumModalStepDueDatesFragment on Curriculum {
        id
        modules {
          totalCount
          edges {
            node {
              id
              releasedAt
              content {
                name
                children {
                  edges {
                    node {
                      id
                      dueAt
                      entityId
                      content {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    curriculumKey
  )

  useEffect(() => {
    if (!form.state.includeCurriculum) {
      return
    }
    calculateDefaultDueDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.startDate, form.state.curriculumContentModuleUsages])

  const curriculumModules = Relay.connectionToArray(sourceCurriculum?.modules)
  const items = curriculumModules
    .map((cm) => Relay.connectionToArray(cm.content.children))
    .flat(1)
  const itemsWithDueDate = items
    .filter((a) => Boolean(a.dueAt))
    .map((a) => ({ ...a, dueAt: a.dueAt! }))

  return (
    <>
      <DiscoModal
        isOpen={isOpen}
        onClose={handleClose}
        title={`Clone ${experienceLabel.singular}`}
        width={"480px"}
        subtitle={`The ${curriculumLabel.singular} contains ${
          itemsWithDueDate.length
        } ${pluralize(
          "item",
          itemsWithDueDate.length
        )} with a set due date. You can change the due date now or update them later in the item's settings.`}
        testid={"CloneCurriculumModalStepDueDates"}
        modalContentLabel={`Clone ${experienceLabel.singular}`}
        body={
          <div className={classes.itemsContainer}>
            {itemsWithDueDate.map((contentUsage, i) => {
              const destinationContentUsages = form.state
                .curriculumContentModuleUsages!.flatMap((cmu) => cmu.contentUsages)
                .filter(Boolean) as CloneContentUsageInput[]
              const destinationCurriculumItem = destinationContentUsages.find(
                (ci) => ci.sourceId === contentUsage.id
              )
              if (!destinationCurriculumItem) return null

              return (
                <DiscoDatetimePicker
                  key={contentUsage.id}
                  testid={"CloneCurriculumModalStepDueDates.due-at"}
                  onSave={(newDate) =>
                    handleSave(newDate, {
                      contentUsageId: contentUsage.id,
                      contentModuleId: contentUsage.entityId,
                    })
                  }
                  minDate={new Date()}
                  initialDate={new Date(destinationCurriculumItem.dueAt)}
                  timeLabel={"Due Time"}
                >
                  {({ onClick }) => (
                    <CloneCurriculumItemDueDateItem
                      testid={`CloneCurriculumModalStepDueDates.index-${i}`}
                      onCalendarClick={onClick as VoidFunction}
                      title={contentUsage.content.name!}
                      dueDate={new Date(destinationCurriculumItem.dueAt)}
                    />
                  )}
                </DiscoDatetimePicker>
              )
            })}
          </div>
        }
        buttons={
          <>
            <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
              {"Cancel"}
            </DiscoButton>
            <DiscoButton
              testid={"CloneCurriculumModalStepDueDates.submit"}
              shouldDisplaySpinner={form.isSubmitting}
              onClick={handleOnNextClick}
            >
              {form.state.includeEvents ? "Continue" : "Clone"}
            </DiscoButton>
          </>
        }
      />
      <CloneProductEditModuleDetails />
    </>
  )

  function handleSave(
    newDate: Date,
    item: { contentUsageId: GlobalID; contentModuleId: GlobalID }
  ) {
    const contentModuleUsage = form.state.curriculumContentModuleUsages?.find(
      (cmu) => cmu.contentId === item.contentModuleId
    )
    if (!contentModuleUsage) return

    const contentUsage = contentModuleUsage.contentUsages?.find(
      (cu) => cu.sourceId === item.contentUsageId
    )
    if (!contentUsage) return

    contentUsage.dueAt = newDate.toISOString() // set the new due date for the content usage
  }

  function handleOnNextClick() {
    if (form.state.includeEvents) {
      handleNext("events")
    } else {
      handleNext("success")
    }
  }

  function calculateDefaultDueDates() {
    form.state.curriculumContentModuleUsages!.replace(
      form.state.curriculumContentModuleUsages!.map((cmu) => {
        const itemsInSection = itemsWithDueDate.filter(
          (i) => i.entityId === cmu.contentId
        )
        const contentUsages = observable(
          itemsInSection.map(({ id, dueAt }) => {
            // The time delta between the due date relative to the start date of the curriculum module it was in
            const diff = getDifferenceInDaysBetweenDates(
              dueAt!,
              (cmu.releasedAt || form.state.sourceProduct!.startDate)!
            )
            return {
              sourceId: id,
              dueAt: addDays(
                new Date((cmu.releasedAt || form.state.startDate)!),
                diff
              ).toISOString(),
            }
          })
        )

        return observable({
          ...cmu,
          contentUsages,
        })
      })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
}))

export default observer(CloneCurriculumModalStepDueDates)
