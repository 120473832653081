import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import { OneClickRSVPQueryParams } from "@components/buttons/RSVPToEventButton"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { setSearchParams } from "@utils/url/urlUtils"
import { useEffect } from "react"
import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router-dom"

/**
 * Update the pathname based on the CheckoutStore's state to track
 * checkout progress in our analytics services through pageviews.
 */
export default function useCheckoutURLForAnalytics(store: CheckoutStore) {
  const activeProduct = useActiveProduct()
  const match = useRouteMatch<{ productSlug: string; occurrenceId?: string }>()
  const [{ membershipPlanSlug }] = useQueryParamState<{
    membershipPlanSlug: string
  }>()
  const productSlug =
    activeProduct?.slug || match.params.productSlug || membershipPlanSlug
  const { occurrenceId } = match.params
  const location = useLocation()
  const history = useHistory()
  const drawer = useGlobalDrawer("registration")

  useEffect(() => {
    const eventRegistrationPath =
      occurrenceId &&
      generatePath(ROUTE_NAMES.PRODUCT.EVENTS.REGISTRATION.ROOT, {
        productSlug,
        occurrenceId,
      })

    const isOnEventPagePath =
      eventRegistrationPath && location.pathname.startsWith(eventRegistrationPath)

    // Is experience event registration if on the /events/:occurrenceId/register route
    // or id the `drawerRegistrationOccurrenceId` param is set
    const isExperienceEventRegistration =
      (isOnEventPagePath || Boolean(drawer.params.drawerRegistrationOccurrenceId)) &&
      activeProduct?.type === "course"

    // If successful experience event registration, route to the event drawer and auto RSVP
    if (isExperienceEventRegistration && store.currentStep === "success") {
      drawer.setParams({
        drawerRegistrationOccurrenceId: "",
        drawerRegistrationExperienceId: "",
      })
      return history.replace({
        pathname: generatePath(ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT, {
          productSlug,
        }),
        search: setSearchParams<GlobalDrawerParams<"event"> & OneClickRSVPQueryParams>(
          "",
          {
            drawerOccurrenceId: occurrenceId!,
            drawerEventTab: "details",
            rsvp: occurrenceId,
          }
        ),
      })
    }

    // If registering for experience through the drawer, update the `registrationStep`
    else if (drawer.params.drawerRegistrationExperienceId) {
      return drawer.setParams({ registrationStep: store.currentStep })
    }

    // If on the membership plan selection modal, update the query params only
    if (membershipPlanSlug) {
      return history.replace({
        ...location,
        search: setSearchParams(location.search, {
          registrationStep: store.currentStep,
        }),
      })
    }

    // If registering on the register page for an event, update the pathname
    if (occurrenceId && isOnEventPagePath) {
      return history.replace({
        ...location,
        pathname: generatePath(ROUTE_NAMES.PRODUCT.EVENTS.REGISTRATION.ROOT, {
          productSlug,
          occurrenceId,
        }),
        search: setSearchParams(location.search, {
          registrationStep: store.currentStep,
        }),
      })
    }

    // If registering on the register page, update the pathname
    return history.replace({
      ...location,
      pathname: generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
        productSlug,
      }),
      search: setSearchParams(location.search, {
        registrationStep: store.currentStep,
      }),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.currentStep])
}
