import { ProductAppKind } from "@/apps/remove-app-button/__generated__/RemoveAppButtonFragment.graphql"
import { useLabel, useLabels } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useActiveCloneProductModalContext } from "@/product/clone/sequence/CloneProductModalContext"
import { GENERAL_SETTINGS_MODAL_KIND } from "@/product/clone/sequence/modal/general-settings/CloneProductModalStepGeneralSettings"
import { useProductRoleLabels } from "@/role/roleUtils"
import Form from "@components/form/Form"
import {
  DiscoCheckbox,
  DiscoFormControl,
  DiscoInput,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDatePicker from "@disco-ui/date/DiscoDatePicker"
import { compensateForTimeZone } from "@utils/time/timeUtils"
import { observer } from "mobx-react-lite"

function CloneProductGeneralSettingsForm() {
  const { form } = useActiveCloneProductModalContext(GENERAL_SETTINGS_MODAL_KIND)!
  const curriculumLabel = useLabel("curriculum")
  const classes = useStyles()
  const labels = useLabels()
  const roleLabels = useProductRoleLabels()

  const connectedApps = form.state.connectedApps as Set<ProductAppKind>
  const isCurriculumAppConnected = connectedApps.has("curriculum")
  const isEventsAppConnected = connectedApps.has("events")
  const isChatChannelAppConnected = connectedApps.has("chat_channel")
  const isCollectionAppConnected = connectedApps.has("collection")

  return (
    <Form testid={"CloneProductModalStepGeneralSettings"} onSubmit={() => null}>
      {/* Long Name */}
      <DiscoFormControl
        label={`New ${labels.experience.singular} Name`}
        error={Boolean(form.errorsByField.name)}
        errorMessages={form.errorsByField.name}
      >
        <DiscoInput
          placeholder={`Your ${labels.experience.singular} name`}
          value={form.state.name}
          onChange={(e) => (form.state.name = e.target.value)}
        />
      </DiscoFormControl>

      {form.state.startDate && (
        <DiscoFormControl
          label={"Start Date"}
          error={Boolean(form.errorsByField.startDate)}
          errorMessages={form.errorsByField.startDate}
        >
          <DiscoDatePicker
            testid={"CloneProductModalStepGeneralSettings.DateSelect"}
            onChange={(details) => {
              if (details.value) {
                form.state.startDate = details.formatted["yyyy-MM-dd"]
              }
            }}
            value={compensateForTimeZone(new Date(form.state.startDate))}
            minDate={compensateForTimeZone(new Date())}
          />
        </DiscoFormControl>
      )}
      <DiscoText variant={"body-lg-600"} marginBottom={2}>
        {"Select what you would like to copy:"}
      </DiscoText>
      <DiscoFormControl
        marginBottom={0.5}
        classes={{ root: classes.checkboxFormControl }}
      >
        <DiscoCheckbox
          checked
          disabled
          onChange={() => null}
          data-testid={"CloneProductModalStepGeneralSettings.Checkbox.Settings"}
          name={"toggle-clone-settings"}
          label={
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {`${labels.experience.singular} Settings`}
              <DiscoTooltip
                content={`${labels.experience.singular} settings are copied automatically, including Groups, Notifications, Registration, and Advanced settings.`}
                buttonProps={{ classes: { root: classes.tooltipButton } }}
              />
            </DiscoText>
          }
        />
      </DiscoFormControl>
      {isCurriculumAppConnected && (
        <DiscoFormControl
          marginBottom={0.5}
          classes={{ root: classes.checkboxFormControl }}
        >
          <DiscoCheckbox
            data-testid={"CloneProductModalStepGeneralSettings.Checkbox.Curriculum"}
            name={"toggle-clone-curriculum"}
            checked={Boolean(form.state.includeCurriculum)}
            disabled={form.state.isCalendarOpen}
            onChange={(v) => (form.state.includeCurriculum = v)}
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {curriculumLabel.singular}
              </DiscoText>
            }
          />
        </DiscoFormControl>
      )}
      {isEventsAppConnected && (
        <DiscoFormControl
          marginBottom={0.5}
          classes={{ root: classes.checkboxFormControl }}
        >
          <DiscoCheckbox
            data-testid={"CloneProductModalStepGeneralSettings.Checkbox.Events"}
            name={"toggle-clone-events"}
            checked={form.state.includeEvents}
            onChange={(v) => (form.state.includeEvents = v)}
            label={
              form.state.canCloneEvents ? (
                "Events"
              ) : (
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {"Events"}
                  <DiscoTooltip
                    content={
                      "One or more events have an invalid Zoom connection. Events cannot be cloned unless you reconnect Zoom from your Community's Integration page before continuing."
                    }
                    buttonProps={{ classes: { root: classes.tooltipButton } }}
                  />
                </DiscoText>
              )
            }
            disabled={!form.state.canCloneEvents || form.state.isCalendarOpen}
          />
        </DiscoFormControl>
      )}
      {isCollectionAppConnected && (
        <DiscoFormControl
          marginBottom={0.5}
          classes={{ root: classes.checkboxFormControl }}
        >
          <DiscoCheckbox
            data-testid={"CloneProductModalStepGeneralSettings.Checkbox.Collections"}
            name={"toggle-clone-collections"}
            checked={Boolean(form.state.includeCollections)}
            onChange={(v) => (form.state.includeCollections = v)}
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Collections"}
              </DiscoText>
            }
          />
        </DiscoFormControl>
      )}
      {isChatChannelAppConnected && (
        <DiscoFormControl
          marginBottom={0.5}
          classes={{ root: classes.checkboxFormControl }}
        >
          <DiscoCheckbox
            data-testid={"CloneProductModalStepGeneralSettings.Checkbox.Chat_Channels"}
            name={"toggle-clone-chat-channels"}
            checked={Boolean(form.state.includeChatChannels)}
            onChange={(v) => (form.state.includeChatChannels = v)}
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Chat Channels"}
                <DiscoTooltip
                  content={"This will only duplicate all public chat channels."}
                  buttonProps={{ classes: { root: classes.tooltipButton } }}
                />
              </DiscoText>
            }
          />
        </DiscoFormControl>
      )}
      <DiscoFormControl
        marginBottom={0.5}
        classes={{ root: classes.checkboxFormControl }}
      >
        <DiscoCheckbox
          data-testid={"CloneProductModalStepGeneralSettings.Checkbox.Managers"}
          name={"toggle-clone-managers"}
          checked={Boolean(form.state.includeManagers)}
          disabled={form.state.isCalendarOpen}
          onChange={(v) => (form.state.includeManagers = v)}
          label={roleLabels.manager.plural}
        />
      </DiscoFormControl>
      <DiscoFormControl
        marginBottom={0.5}
        classes={{ root: classes.checkboxFormControl }}
      >
        <DiscoCheckbox
          data-testid={"CloneProductModalStepGeneralSettings.Checkbox.Instructors"}
          name={"toggle-clone-instructors"}
          checked={Boolean(form.state.includeInstructors)}
          disabled={form.state.isCalendarOpen}
          onChange={(v) => (form.state.includeInstructors = v)}
          label={roleLabels.instructor.plural}
        />
      </DiscoFormControl>
    </Form>
  )
}

const useStyles = makeUseStyles((theme) => ({
  checkboxFormControl: {
    "& .MuiFormControlLabel-root": {
      marginLeft: theme.spacing(-1),
    },
  },
  tooltipButton: {
    display: "inline",
    width: "24px",
    height: "24px",
    marginLeft: theme.spacing(0.75),
  },
}))

export default observer(CloneProductGeneralSettingsForm)
