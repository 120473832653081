import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { CompleteProfileCurriculumItemFormMutation } from "@/product/course/curriculum/section/drawer/items/profile/__generated__/CompleteProfileCurriculumItemFormMutation.graphql"
import { CompleteProfileCurriculumItemFormQuery } from "@/product/course/curriculum/section/drawer/items/profile/__generated__/CompleteProfileCurriculumItemFormQuery.graphql"
import useUpdateCompleteProfileItems from "@/product/course/curriculum/util/useUpdateCompleteProfileItems"
import Relay from "@/relay/relayUtils"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import ProfileSettingsCustomFormFields from "@/user/settings/subtabs/profile/ProfileSettingsCustomFormFields"
import ProfileSettingsProfileFormFields from "@/user/settings/subtabs/profile/ProfileSettingsProfileFormFields"
import { ProfileSettingsProfileTabSkeleton } from "@/user/settings/subtabs/profile/ProfileSettingsProfileTab"
import {
  initUpdateProfileWebFormSubmissionInput,
  UpdateUserProfileFormState,
} from "@/user/settings/subtabs/profile/ProfileSettingsUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import Form from "@components/form/Form"
import MediaUploadButton from "@components/media/upload/MediaUploadButton"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoText,
  DiscoTextButton,
} from "@disco-ui"
import { IconButton } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type CompleteProfileCurriculumItemFormProps = TestIDProps & {
  showCustomFields?: boolean
  onAfterSubmit?: VoidFunction
  submitButtonTitle?: string
  buttons?: React.ReactNode
  requireChangeToSubmit?: boolean
  classes?: Partial<ClassNameMap<"form" | "buttonsContainer">>
  productId?: string
  skipSubmit?: boolean
}

function CompleteProfileCurriculumItemForm({
  testid = "CompleteProfileCurriculumItemForm",
  submitButtonTitle = "Update",
  showCustomFields = true,
  onAfterSubmit,
  buttons,
  requireChangeToSubmit = true,
  classes: customClasses,
  productId,
  skipSubmit = false,
}: CompleteProfileCurriculumItemFormProps) {
  const updateCompleteProfileCompletionMutation = useUpdateCompleteProfileItems()
  const { authUser, updateAuthUserInStore } = useAuthUser({ required: true })
  const isMobile = useIsMobile()
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()
  const activeOrganization = useActiveOrganization()
  const avatarSize = isMobile ? 96 : 120

  const { user } = useLazyLoadQuery<CompleteProfileCurriculumItemFormQuery>(
    graphql`
      query CompleteProfileCurriculumItemFormQuery($userId: ID!, $organizationId: ID!) {
        user: node(id: $userId) {
          ... on User {
            organizationMembership(organizationId: $organizationId) {
              profileValues {
                edges {
                  node {
                    id
                    profileField {
                      webFormQuestionId
                    }
                    value
                    selectedOptions
                    webFormAnswer {
                      body
                      selectedOptions
                    }
                  }
                }
              }
              organization {
                profileWebForm {
                  currentRevision {
                    ...webFormFillerUtils_getFormStateFromRevisionFragment
                      @relay(mask: false)
                  }
                }
                ...ProfileSettingsProfileFormFieldsFragment
                ...ProfileSettingsCustomFormFieldsFragment
              }
            }
          }
        }
      }
    `,
    {
      userId: authUser.id,
      organizationId: activeOrganization?.id || "",
    }
  )
  const membership = user?.organizationMembership

  // Set up web form submission input using existing values
  let webFormSubmission
  if (showCustomFields) {
    webFormSubmission = initUpdateProfileWebFormSubmissionInput(
      membership?.organization.profileWebForm?.currentRevision,
      membership?.profileValues
    )
  }

  const form = useFormStore<
    CompleteProfileCurriculumItemFormMutation,
    UpdateUserProfileFormState
  >(
    graphql`
      mutation CompleteProfileCurriculumItemFormMutation(
        $input: UpdateUserProfileInput!
        $organizationId: ID!
      ) {
        response: updateProfile(input: $input) {
          node {
            firstName
            lastName
            fullName
            avatar
            bio
            cover
            timezone
            ...ProfileSettingsProfileTabFragment
              @arguments(organizationId: $organizationId)
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: authUser.id,
      firstName: authUser.firstName || "",
      lastName: authUser.lastName || "",
      avatar: authUser.avatar,
      bio: authUser.bio,
      cover: authUser.cover,
      timezone: authUser.timezone,
      webFormSubmission,
    },
    { requireChangeToSubmit }
  )

  return (
    <Form
      testid={testid}
      customClassName={classNames(classes.form, customClasses?.form)}
      onSubmit={handleSubmit}
      classes={{ buttonsRoot: customClasses?.buttonsContainer }}
      buttons={
        <>
          {buttons}
          <DiscoButton
            type={"submit"}
            disabled={form.disabled}
            testid={`${testid}.submit-button`}
            shouldDisplaySpinner={form.isSubmitting}
          >
            {submitButtonTitle}
          </DiscoButton>
        </>
      }
    >
      <div className={classes.coverBannerWrapper}>
        <DiscoFormControl
          className={classNames(classes.coverPhotoControl, showOnHoverClasses.hoverable)}
        >
          <CoverPhoto
            coverPhoto={form.state.cover!}
            customClassName={classes.coverPhoto}
          />
          <div
            className={classNames(
              classes.coverPhotoActionButtonsOverlay,
              showOnHoverClasses.showable
            )}
          >
            {form.state.cover &&
              (isMobile ? (
                <DiscoIconButton
                  className={classes.actionIconButton}
                  onClick={() => (form.state.cover = null)}
                >
                  <DiscoIcon icon={"trash"} />
                </DiscoIconButton>
              ) : (
                <DiscoButton
                  testid={`${testid}.remove`}
                  color={"grey"}
                  variant={"outlined"}
                  leftIcon={"trash"}
                  onClick={() => (form.state.cover = null)}
                >
                  {"Remove"}
                </DiscoButton>
              ))}
            {isMobile ? (
              <MediaUploadButton
                testid={`${testid}.change`}
                accept={IMAGE_FILE_TYPES}
                onSuccess={(result) => (form.state.cover = result.url)}
                color={"transparent"}
                className={classes.transparentButton}
                cropperProps={{
                  stencilProps: { aspectRatio: ASPECT_RATIOS.COVER_PHOTO },
                }}
                includeOrganizationIdOnAsset={false}
                includeInMediaLibrary={false}
              >
                <DiscoIconButton className={classes.actionIconButton}>
                  <DiscoIcon icon={"pencil"} />
                </DiscoIconButton>
              </MediaUploadButton>
            ) : (
              <MediaUploadButton
                testid={`${testid}.change`}
                color={"grey"}
                variant={"outlined"}
                accept={IMAGE_FILE_TYPES}
                onSuccess={(result) => (form.state.cover = result.url)}
                leftIcon={"pencil"}
                cropperProps={{
                  stencilProps: { aspectRatio: ASPECT_RATIOS.COVER_PHOTO },
                }}
                includeOrganizationIdOnAsset={false}
                includeInMediaLibrary={false}
              >
                {"Edit Cover Photo"}
              </MediaUploadButton>
            )}
          </div>
        </DiscoFormControl>

        <div className={classes.details}>
          <div className={classes.avatarWrapper}>
            <div className={classNames(classes.avatarArea, showOnHoverClasses.hoverable)}>
              <UserAvatar
                testid={`${testid}-avatar`}
                user={{
                  avatar: form.state.avatar || null,
                  first_name: form.state.firstName || null,
                  last_name: form.state.lastName || null,
                  id: form.state.id,
                }}
                size={avatarSize}
                dropShadow
              />
              <div
                className={classNames(
                  classes.avatarActionOverlay,
                  showOnHoverClasses.showable
                )}
              >
                <MediaUploadButton
                  testid={`${testid}.change`}
                  className={classes.transparentButton}
                  accept={IMAGE_FILE_TYPES}
                  color={"transparent"}
                  onSuccess={(result) => (form.state.avatar = result.url)}
                  cropperProps={{
                    stencilProps: { aspectRatio: ASPECT_RATIOS.SQUARE },
                  }}
                  includeOrganizationIdOnAsset={false}
                  includeInMediaLibrary={false}
                >
                  <IconButton
                    className={classes.actionIconButton}
                    classes={{
                      root: classes.editAvatarButton,
                    }}
                  >
                    <DiscoIcon icon={"camera"} width={24} height={24} />
                  </IconButton>
                </MediaUploadButton>
              </div>
            </div>
          </div>
          <div className={classes.nameHeader}>
            <div className={classes.nameRoot} data-testid={testid}>
              <DiscoText
                variant={isMobile ? "body-lg-600" : "heading-md"}
                testid={`${testid}.full-name`}
              >
                {`${form.state.firstName} ${form.state.lastName}`}
              </DiscoText>
            </div>
          </div>
          {form.state.avatar && (
            <DiscoTextButton
              onClick={() => (form.state.avatar = "")}
              className={classes.removeAvatarButton}
            >
              {"Remove Photo"}
            </DiscoTextButton>
          )}
        </div>
      </div>

      {membership?.organization && (
        <>
          <ProfileSettingsProfileFormFields
            form={form}
            organizationKey={membership.organization}
            testid={testid}
          />
          {showCustomFields && (
            <ProfileSettingsCustomFormFields
              form={form}
              organizationKey={membership.organization}
            />
          )}
        </>
      )}
    </Form>
  )

  async function handleSubmit() {
    if (((!requireChangeToSubmit && !form.isChanged) || skipSubmit) && onAfterSubmit)
      return onAfterSubmit() // if there are no changes but we still want to do something after submit, skip submission and just do the thing

    const { didSave, response } = await form.submit(form.state, {
      variables: { organizationId: activeOrganization?.id || "" },
    })

    if (!didSave || !response?.node) return

    updateAuthUserInStore({
      firstName: response.node.firstName,
      lastName: response.node.lastName,
      fullName: response.node.fullName,
      avatar: response.node.avatar,
      bio: response.node.bio,
      cover: response.node.cover,
      timezone: response.node.timezone,
    })

    onAfterSubmit?.()

    // Track completion of the lesson
    await commitCompletionMutation()

    displaySuccessToast({
      message: "Profile updated!",
      testid: `${testid}.success-toast`,
    })
  }

  async function commitCompletionMutation() {
    // Update all the complete profile items in any product the member is in,
    await updateCompleteProfileCompletionMutation({
      input: {},
      productId: productId || "",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  coverPhoto: {
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.grey[700],
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: 0,
    height: 200,
    [theme.breakpoints.down("xs")]: {
      height: 94,
    },
  },
  coverPhotoControl: {
    position: "relative",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  coverPhotoActionButtonsOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    gap: theme.spacing(1.5),
    [theme.breakpoints.down("xs")]: {
      top: "auto",
      left: "auto",
      transform: "none",
      right: 10,
      bottom: 10,
    },
  },
  actionIconButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  details: {
    display: "grid",
    gridTemplateColumns: "168px 1fr",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "144px 1fr",
    },
  },
  nameHeader: {
    width: "100%",
    height: "45px",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
  },
  nameRoot: {
    display: "flex",
    alignItems: "flex-end",
    gap: theme.spacing(1),
  },
  avatarArea: {
    display: "grid",
    placeItems: "center",
    width: "100%",
    position: "absolute",
    bottom: "100%",
    transform: "translateY(43%)",
  },
  avatarWrapper: {
    position: "relative",
    height: "100%",
  },
  avatarActionOverlay: {
    position: "absolute",
  },
  editAvatarButton: {
    width: "40px",
    height: "40px",
    border: `1px solid ${theme.palette.groovy.neutral[100]}`,
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  transparentButton: {
    padding: 0,
    backgroundColor: "transparent",
  },
  removeAvatarButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1.5),
    },
  },
  coverBannerWrapper: {
    marginBottom: theme.spacing(2),
  },
  form: {
    paddingBottom: theme.spacing(3),
  },
}))

export default Relay.withSkeleton({
  component: observer(CompleteProfileCurriculumItemForm),
  skeleton: ProfileSettingsProfileTabSkeleton,
})
