import { useLabel } from "@/core/context/LabelsContext"
import {
  CloneProductModalKind,
  useActiveCloneProductModalContext,
} from "@/product/clone/sequence/CloneProductModalContext"
import CloneProductGeneralSettingsForm from "@/product/clone/sequence/modal/general-settings/CloneProductGeneralSettingsForm"
import { DiscoButton, DiscoModal } from "@disco-ui"
import { observer } from "mobx-react-lite"

export const GENERAL_SETTINGS_MODAL_KIND: Extract<
  "general_settings",
  CloneProductModalKind
> = "general_settings"

function CloneProductModalStepGeneralSettings() {
  const { form, isOpen, handleNext, handleClose } = useActiveCloneProductModalContext(
    GENERAL_SETTINGS_MODAL_KIND
  )!
  const experienceLabel = useLabel("admin_experience")

  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={handleClose}
      title={`Clone ${experienceLabel.singular}`}
      subtitle={`To clone ${
        form.state.sourceProduct?.name || `this ${experienceLabel.singular}`
      }, choose a name, select a start date, and select the properties you would like to copy when cloning.`}
      testid={"CloneProductModalStepGeneralSettings"}
      modalContentLabel={`Clone ${experienceLabel.singular}`}
      width={"550px"}
      body={<CloneProductGeneralSettingsForm />}
      buttons={
        <>
          <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton
            type={"submit"}
            shouldDisplaySpinner={form.isSubmitting}
            onClick={() =>
              form.state.includeCurriculum
                ? handleNext("curriculum_modules")
                : form.state.includeEvents
                ? handleNext("events")
                : handleNext("success")
            }
          >
            {form.state.includeCurriculum || form.state.includeEvents
              ? "Continue"
              : "Clone"}
          </DiscoButton>
        </>
      }
    />
  )
}

export default observer(CloneProductModalStepGeneralSettings)
