import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import NotFoundPageContent from "@/core/route/component/not-found/NotFoundPageContent"
import PermissionedRoute from "@/core/route/permissioned-route/PermissionedRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import WaitingRoomFlows from "@/product/detail/flows/waiting-room/WaitingRoomFlows"
import ProductPageMetaDetails from "@/product/detail/page/ProductPageMetaDetails"
import useIsWaitingRoomEnabled from "@/product/util/hook/useIsWaitingRoomEnabled"
import { ArrayUtils } from "@utils/array/arrayUtils"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { setSearchParams } from "@utils/url/urlUtils"
import { useEffect } from "react"
import { Redirect, Route, Switch } from "react-router"
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom"

const ProductCourseDashboardFlow = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "product-course-dashboard" */ "./dashboard/ProductCourseDashboardFlow"
    )
)
const ProductChatsFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "product-chats" */ "./chat/ProductChatsFlow")
)
const ProductCollectionFlow = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "product-collections" */ "./collection/ProductCollectionFlow"
    )
)
const CourseCurriculumTab = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "product-curriculums" */ "@/product/course/curriculum/CourseCurriculumTab"
    )
)
const ProductEventsFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "product-events" */ "./events/ProductEventsFlow")
)
const ProductLandingPageFlow = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "product-landing-page" */ "./landing-page/ProductLandingPageFlow"
    )
)
const ProductMembersFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "product-members" */ "./members/ProductMembersFlow")
)
const ProductPostsFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "product-posts" */ "./posts/ProductPostsFlow")
)
const ProductReportsFlow = lazyWithRetry(
  () => import(/* webpackChunkName: "product-reports" */ "./reports/ProductReportsFlow")
)
const CheckoutFlows = lazyWithRetry(
  () => import(/* webpackChunkName: "checkout-page" */ "../../checkout/CheckoutFlows")
)
const CompleteProductApplicationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "complete-product-application-page" */ "../../../product/application/payment/CompleteProductApplicationPage"
    )
)
const OccurrenceRedirectPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "occurrence-redirect-page" */ "@/occurrence/page/OccurrenceRedirectPage"
    )
)
const WebViewContent = lazyWithRetry(
  () => import(/* webpackChunkName: "web-view-content" */ "@/webview/WebViewContent")
)

export interface RootProductRouteParams {
  productSlug: string
}

function ProductFlows() {
  const activeProduct = useActiveProduct()

  const location = useLocation()
  const { productSlug } = useParams<RootProductRouteParams>()
  const { isWaitingRoomEnabled } = useIsWaitingRoomEnabled()

  const { connectedApps } = useConnectedProductApps()
  const isCurriculumAppConnected = connectedApps.has("curriculum")
  const isEventsAppConnected = connectedApps.has("events")
  const isFeedAppConnected = connectedApps.has("posts")
  const isCollectionAppConnected = connectedApps.has("collection")

  useRedirectEventDrawerPaths()

  return (
    <Switch>
      {/* External Routes = Landing Page and others "outside" community with no side bar*/}
      {getExternalProductRoutes()}
      {/* Product routes */}
      <Route path={ROUTE_NAMES.PRODUCT.ROOT}>
        {isWaitingRoomEnabled ? (
          <WaitingRoomFlows />
        ) : (
          <ProductPageMetaDetails>{getProductRoutes()}</ProductPageMetaDetails>
        )}
      </Route>
    </Switch>
  )

  function getProductRoutes() {
    return (
      <Switch>
        {activeProduct && [
          ...getCommonProductRoutes(),
          ...(activeProduct.type === "course" ? getCourseProductRoutes() : []),
          ...getWebViewProductRoutes(),
        ]}

        {/* REDIRECT: /p/slug/admin -> /p/slug */}
        <Redirect
          from={"/p/:productSlug/admin"}
          to={location.pathname.replace(`/p/${productSlug}/admin`, `/p/${productSlug}`)}
        />

        {/* bad route */}
        <Route path={ROUTE_NAMES.PRODUCT.NOT_FOUND} component={NotFoundPageContent} />
      </Switch>
    )
  }

  function getCommonProductRoutes() {
    if (!activeProduct) return []
    return [
      // MEMBERS
      <PermissionedRoute
        key={`route:=${ROUTE_NAMES.PRODUCT.MEMBERS.ROOT}`}
        path={[
          ROUTE_NAMES.PRODUCT.MEMBERS.INVITES,
          ROUTE_NAMES.PRODUCT.MEMBERS.APPLICATIONS,
          ROUTE_NAMES.PRODUCT.MEMBERS.ROOT,
        ]}
        permission={"members.read"}
        entityKey={activeProduct}
      >
        <ProductMembersFlow />
      </PermissionedRoute>,
    ]
  }

  function getExternalProductRoutes() {
    if (!activeProduct) return []
    return [
      <Route
        key={`route:=${ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT}`}
        path={[
          ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT,
          ROUTE_NAMES.PRODUCT.REGISTRATION.GIFT.ROOT,
          ROUTE_NAMES.PRODUCT.EVENTS.REGISTRATION.ROOT,
        ]}
      >
        <CheckoutFlows />
      </Route>,
      <Route
        key={`route:=${ROUTE_NAMES.PRODUCT.COMPLETE_APPLICATION}`}
        path={[ROUTE_NAMES.PRODUCT.COMPLETE_APPLICATION]}
      >
        <CompleteProductApplicationPage />
      </Route>,
      <Route
        key={`route:=${ROUTE_NAMES.PRODUCT.LANDING_PAGE.EDIT}`}
        path={[
          ROUTE_NAMES.PRODUCT.LANDING_PAGE.EDIT,
          ROUTE_NAMES.PRODUCT.LANDING_PAGE.PREVIEW,
          ROUTE_NAMES.PRODUCT.LANDING_PAGE.SITE,
        ]}
        exact
      >
        <ProductLandingPageFlow />
      </Route>,
    ]
  }

  function getCourseProductRoutes() {
    return [
      // EXPERIENCE DASHBOARD
      <Route
        key={`route:=${ROUTE_NAMES.PRODUCT.DASHBOARD}`}
        path={ROUTE_NAMES.PRODUCT.DASHBOARD}
      >
        <ProductCourseDashboardFlow />
      </Route>,

      // POSTS
      ...ArrayUtils.spreadIf(
        <Route
          key={`route:=${ROUTE_NAMES.PRODUCT.FEED.ROOT}`}
          path={ROUTE_NAMES.PRODUCT.FEED.ROOT}
        >
          <ProductPostsFlow />
        </Route>,
        isFeedAppConnected
      ),

      // COLLECTIONS
      ...ArrayUtils.spreadIf(
        <Route
          key={`route:=${ROUTE_NAMES.PRODUCT.COLLECTION.ROOT}`}
          path={ROUTE_NAMES.PRODUCT.COLLECTION.ROOT}
        >
          <ProductCollectionFlow />
        </Route>,
        isCollectionAppConnected
      ),

      // EVENTS
      ...ArrayUtils.spreadIf(
        <Route
          key={`route:=${ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT}`}
          path={ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT}
        >
          <ProductEventsFlow />
        </Route>,
        isEventsAppConnected
      ),

      // OCCURENCE REDIRECT - DEPRECATED
      <Route
        key={`route:=${ROUTE_NAMES.PRODUCT.SHARE.OCCURRENCE.ROOT}`}
        path={ROUTE_NAMES.PRODUCT.SHARE.OCCURRENCE.ROOT}
      >
        <OccurrenceRedirectPage />
      </Route>,

      // CURRICULUM
      ...ArrayUtils.spreadIf(
        <Route
          key={`route:=${ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT}`}
          // Need to provide more specific routes for Breadcrumbs
          path={[
            ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW,
            ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT,
          ]}
        >
          <CourseCurriculumTab />
        </Route>,
        isCurriculumAppConnected
      ),

      // Reports
      <Route
        key={`route:=${ROUTE_NAMES.PRODUCT.REPORTS.ROOT}`}
        // Need to provide more specific routes for Breadcrumbs
        path={[
          ROUTE_NAMES.PRODUCT.REPORTS.PROGRESS.ROOT,
          ROUTE_NAMES.PRODUCT.REPORTS.ROOT,
        ]}
      >
        <ProductReportsFlow />
      </Route>,

      // App: CHATS
      <Route
        key={`route:=${ROUTE_NAMES.PRODUCT.CHAT.LIST}`}
        path={[ROUTE_NAMES.PRODUCT.CHAT.LIST, ROUTE_NAMES.PRODUCT.CHAT.DIRECT_MESSAGE]}
      >
        <ProductChatsFlow />
      </Route>,

      ...getProductRedirects(),
    ]
  }

  function getWebViewProductRoutes() {
    if (!activeProduct) return []
    return [
      <PermissionedRoute
        key={`route:=${ROUTE_NAMES.PRODUCT.WEBVIEW.BOOKMARKS}`}
        path={ROUTE_NAMES.PRODUCT.WEBVIEW.BOOKMARKS}
        permission={"content.read"}
        entityKey={activeProduct}
      >
        <WebViewContent type={"bookmarks"} />
      </PermissionedRoute>,

      <PermissionedRoute
        key={`route:=${ROUTE_NAMES.PRODUCT.WEBVIEW.PROFILE}`}
        path={ROUTE_NAMES.PRODUCT.WEBVIEW.PROFILE}
        permission={"members.read"}
        entityKey={activeProduct}
      >
        <WebViewContent type={"profileSettings"} />
      </PermissionedRoute>,
    ]
  }

  function getProductRedirects() {
    return [
      // Checkout redirects
      <Redirect
        key={`route:=CheckoutRemovalRedirects`}
        from={"/p/:productSlug/checkout"}
        to={{
          ...location,
          pathname: location.pathname.replace("/checkout", "/register"),
        }}
      />,
      // Removed all /admin routes from product
      <Redirect
        key={`route:=AdminRemovalRedirect`}
        from={"/p/:productSlug/admin"}
        to={{ ...location, pathname: location.pathname.replace("/admin", "") }}
      />,
      // Rename home -> dashboard
      <Redirect
        key={`route:=HomeToDashboardRdirect`}
        from={"/p/:productSlug/home"}
        to={{ ...location, pathname: location.pathname.replace("home", "dashboard") }}
      />,

      // Embedded lessons/assignments/custom-contents within curriculum
      <Redirect
        key={`route:=CurriculumNavChangeLessonRedirect`}
        from={"/p/:productSlug/lessons"}
        to={{
          ...location,
          pathname: location.pathname.replace(
            `/p/${productSlug}`,
            `/p/${productSlug}/curriculum`
          ),
        }}
      />,
      <Redirect
        key={`route:=CurriculumNavChangeAssignmentRedirect`}
        from={"/p/:productSlug/assignments"}
        to={{
          ...location,
          pathname: location.pathname.replace(
            `/p/${productSlug}`,
            `/p/${productSlug}/curriculum`
          ),
        }}
      />,
      <Redirect
        key={`route:=CurriculumNavChangeCustomContentsRedirect`}
        from={"/p/:productSlug/custom-contents"}
        to={{
          ...location,
          pathname: location.pathname.replace(
            `/p/${productSlug}`,
            `/p/${productSlug}/curriculum`
          ),
        }}
      />,
      // /posts -> /dashboard
      <Redirect
        key={`route:=RedesignPostsAndFeedsRedirect`}
        from={"/p/:productSlug/posts"}
        to={{
          ...location,
          pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug,
          }),
        }}
      />,
    ]
  }
}

export default ProductFlows

/** Redirect from pathname-based event drawer to query params */
function useRedirectEventDrawerPaths() {
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const { pathname, search } = useLocation()
  const history = useHistory()

  // If on a product route for a community event, redirect to
  // the events calendar and open the drawer
  useEffect(() => {
    // If not logged in, not a community event, or in checkout, do not redirect
    if (!activeOrganization?.viewerMembership?.id) return
    if (activeProduct?.type !== "community_event") return
    if (search.includes("registrationStep")) return

    const i = pathname.indexOf(`/p/${activeProduct.slug}/`)
    if (i === -1) return

    history.replace({
      pathname: ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT,
      search: setSearchParams(search, {
        // Pass the slug here to be handled in the drawer
        drawerEventSlug: activeProduct.slug,
      }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search, history])
}
