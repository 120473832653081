/**
 * @generated SignedSource<<9c978487cbcb069311730d50d0b90456>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateMemberGroupButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "CreateMemberGroupButtonFragment";
};
export type CreateMemberGroupButtonFragment$key = {
  readonly " $data"?: CreateMemberGroupButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateMemberGroupButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateMemberGroupButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};

(node as any).hash = "85ac0c7909bf970657c7f09b9d8f516a";

export default node;
