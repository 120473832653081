import { useUpdateCompleteProfileItemsMutation } from "@/product/course/curriculum/util/__generated__/useUpdateCompleteProfileItemsMutation.graphql"
import Relay from "@/relay/relayUtils"
import { graphql } from "relay-runtime"

function useUpdateCompleteProfileItems() {
  // Create mutation to update the completion records for all complete profile items for a user
  const updateCompleteProfileItems =
    Relay.useAsyncMutation<useUpdateCompleteProfileItemsMutation>(
      graphql`
        mutation useUpdateCompleteProfileItemsMutation(
          $input: UpdateCompleteProfileCurriculumItemsInput!
          $productId: ID!
        ) {
          response: updateCompleteProfileCurriculumItems(input: $input) {
            node {
              id
              productMembership(productId: $productId) {
                product {
                  id
                  curriculum {
                    completeProfileContentUsage {
                      ...ContentUsageUtils_ContentCompletionFragment
                    }
                  }
                }
              }
            }
          }
        }
      `
    )

  return updateCompleteProfileItems
}

export default useUpdateCompleteProfileItems
