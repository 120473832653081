import { ProductStatus } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { PRODUCT_STATUS_LABELS } from "@/product/util/productConstants"
import DiscoChip from "@disco-ui/chip/DiscoChip"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  status: ProductStatus
}

export default function ProductStatusChip(props: Props) {
  const { status, testid = "ProductStatusChip" } = props

  const labelColor = ["unpublished", "draft"].includes(status) ? "yellow" : "green"

  return (
    <DiscoChip
      color={labelColor}
      label={PRODUCT_STATUS_LABELS[status]}
      data-testid={`${testid}.ProductStatusChip.label`}
    />
  )
}
