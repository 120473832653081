import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  CloneCourseEvent,
  useActiveCloneProductModalContext,
} from "@/product/clone/sequence/CloneProductModalContext"
import Calendar from "@/core/ui/iconsax/linear/calendar.svg"
import DaySquare from "@components/date/day-square/DaySquare"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { format } from "date-fns-tz"
import utcToZonedTime from "date-fns-tz/utcToZonedTime/index"
import { observer } from "mobx-react-lite"

type EventPreviewProps = {
  event: CloneCourseEvent
  testid: string
}

function CloneProductEventPreview({ event, testid }: EventPreviewProps) {
  const { form } = useActiveCloneProductModalContext()!
  const classes = useStyles()
  return (
    <Grid
      key={event.eventId}
      container
      data-testid={testid}
      justifyContent={"space-between"}
      alignItems={"center"}
      className={classes.container}
    >
      <Grid item container xs alignItems={"center"}>
        <Grid item>
          <DaySquare
            testid={testid}
            date={utcToZonedTime(event.startDatetime, event.timezone)}
            formatterOptions={{
              timeZone: event.timezone,
            }}
          />
        </Grid>
        <Grid item xs>
          <DiscoText variant={"body-md-700"} marginLeft={1}>
            {event.name}
          </DiscoText>
          <DiscoText variant={"body-sm"} marginLeft={1} color={"text.secondary"}>
            {format(
              utcToZonedTime(event.startDatetime, event.timezone),
              DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
              { timeZone: event.timezone }
            )}
          </DiscoText>
        </Grid>
      </Grid>
      <Grid item>
        <DiscoButton
          color={"grey"}
          className={classes.iconButton}
          onClick={() => (form.state.editOccurrenceSourceId = event.occurrenceId)}
        >
          <Calendar className={classes.icon} />
        </DiscoButton>
      </Grid>
    </Grid>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(1.25, 0),
  },
  icon: {
    height: "24px",
    width: "24px",
  },
  iconButton: {
    padding: theme.spacing(0, 1.5),
  },
}))

export default observer(CloneProductEventPreview)
