import { OccurrenceHostsAvatarStackQuery } from "@/product/course/event/components/__generated__/OccurrenceHostsAvatarStackQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import UserAvatarStack, {
  UserAvatarStackSkeleton,
} from "@/user/common/avatar-stack/UserAvatarStack"
import ProfileListDrawer from "@/user/common/profile-list-drawer/ProfileListDrawer"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoTextSkeleton } from "@disco-ui"
import { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  occurrenceId: GlobalID
  viewOnly?: boolean
}

function OccurrenceHostsAvatarStack(props: Props) {
  const { occurrenceId, viewOnly } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = useStyles()

  const { occurrence } = useLazyLoadQuery<OccurrenceHostsAvatarStackQuery>(
    graphql`
      query OccurrenceHostsAvatarStackQuery($id: ID!) {
        occurrence: node(id: $id) {
          ... on Occurrence {
            hosts {
              totalCount
              edges {
                node {
                  id
                  user {
                    id
                    fullName
                    first_name: firstName
                    last_name: lastName
                    avatar
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: occurrenceId }
  )

  const hosts = Relay.connectionToArray(occurrence?.hosts)
  const hostUsers = hosts.map((host) => host.user)
  const hostCount = occurrence?.hosts?.totalCount

  if (!hosts.length) return null

  return (
    <>
      <UserAvatarStack
        testid={"OccurrenceHostsAvatarStack"}
        users={hostUsers}
        totalUsers={Number(hostCount)}
        onClick={handleOnClick}
        avatarSize={24}
        className={classes.avatars}
        customUsersLabel={{ plural: "Hosts", singular: "Host" }}
        singleUserCopy={hosts[0].user.fullName}
        disableHover={viewOnly}
      />

      <ProfileListDrawer
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        users={hostUsers}
        title={"Hosts"}
      />
    </>
  )

  function handleOnClick() {
    if (viewOnly) return
    setIsModalOpen(true)
  }
}

const useStyles = makeUseStyles({
  avatars: {
    alignItems: "flex-end",
    height: "100%",
  },
})

export function OccurrenceHostsAvatarStackSkeleton() {
  return (
    <>
      <UserAvatarStackSkeleton />
      <DiscoTextSkeleton width={40} />
    </>
  )
}

export default Relay.withSkeleton({
  component: OccurrenceHostsAvatarStack,
  skeleton: OccurrenceHostsAvatarStackSkeleton,
})
