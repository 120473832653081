import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MemberGroupMoreActionsDropdown from "@/product/common/member-group/common/more-actions-dropdown/MemberGroupMoreActionsDropdown"
import MemberGroupTag from "@/product/common/member-group/common/tag/MemberGroupTag"
import CourseGroupsListItemAvatarStack, {
  CourseGroupsListItemAvatarStackSkeleton,
} from "@/product/course/settings/groups-settings/list/item/CourseGroupsListItemAvatarStack"
import { CourseGroupsListItemFragment$key } from "@/product/course/settings/groups-settings/list/item/__generated__/CourseGroupsListItemFragment.graphql"
import { DiscoButtonSkeleton, DiscoTextSkeleton } from "@disco-ui"
import classNames from "classnames"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface CourseGroupsListItemProps {
  groupKey: CourseGroupsListItemFragment$key
  testid: string
  className?: string
}

const CourseGroupsListItem = ({
  groupKey,
  testid,
  className,
}: CourseGroupsListItemProps) => {
  const memberGroup = useFragment<CourseGroupsListItemFragment$key>(
    graphql`
      fragment CourseGroupsListItemFragment on MemberGroup {
        id
        name
        color
        ...CourseGroupsListItemAvatarStackFragment
        ...MemberGroupMoreActionsDropdownFragment
        ...MemberGroupTagFragment
      }
    `,
    groupKey
  )

  const classes = useStyles({ groupColour: memberGroup.color })

  if (!memberGroup) return null

  return (
    <li className={classNames(classes.itemContainer, className)} data-testid={testid}>
      <div className={classes.nameContainer}>
        <MemberGroupTag
          key={memberGroup.id}
          memberGroupKey={memberGroup}
          testid={testid}
        />
      </div>
      <div className={classes.avatarStackContainer}>
        <CourseGroupsListItemAvatarStack
          groupKey={memberGroup}
          testid={`${testid}.CourseGroupsListItem`}
        />
      </div>
      <MemberGroupMoreActionsDropdown
        groupKey={memberGroup}
        testid={`${testid}.CourseGroupsListItem`}
      />
    </li>
  )
}

type StyleProps = {
  groupColour?: string
}

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    borderRadius: theme.measure.borderRadius.big,
    background: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  nameContainer: {
    width: "30%",
  },
  groupName: ({ groupColour }: StyleProps) => ({
    width: "fit-content",
    background: groupColour,
    borderRadius: theme.measure.borderRadius.default,
    fontWeight: 600,
    padding: theme.spacing(0.5, 1),
    justifyContent: "flex-start",
  }),
  avatarStackContainer: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-start",
  },
}))

export const CourseGroupsListItemSkeleton: React.FC = () => {
  const classes = useStyles({ groupColour: "#FFF5D6" })

  return (
    <li className={classes.itemContainer}>
      <div className={classes.nameContainer}>
        <DiscoTextSkeleton className={classes.groupName} width={"125px"} />
      </div>
      <div className={classes.avatarStackContainer}>
        <CourseGroupsListItemAvatarStackSkeleton />
      </div>
      <DiscoButtonSkeleton width={"24px"} height={"24px"} />
    </li>
  )
}

export default CourseGroupsListItem
