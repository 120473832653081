import { useLabel } from "@/core/context/LabelsContext"
import { useActiveCloneProductModalContext } from "@/product/clone/sequence/CloneProductModalContext"
import CloneProductEditEventDetails from "@/product/clone/sequence/modal/events/CloneProductEditEventDetails"
import CloneProductEventPreview from "@/product/clone/sequence/modal/events/CloneProductEventPreview"
import { CloneProductModalStepEventsFragment$key } from "@/product/clone/sequence/modal/events/__generated__/CloneProductModalStepEventsFragment.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoButton, DiscoModal } from "@disco-ui"
import { getDifferenceInDaysBetweenDates } from "@utils/time/timeUtils"
import { addDays, addHours, differenceInMinutes, format, isBefore } from "date-fns"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect } from "react"
import { graphql, useFragment } from "react-relay"

type CloneProductModalStepEventsProps = {
  eventKey: CloneProductModalStepEventsFragment$key | null
}

function CloneProductModalStepEvents({ eventKey }: CloneProductModalStepEventsProps) {
  const { form, isOpen, handleClose, handleNext } =
    useActiveCloneProductModalContext("events")!
  const experienceLabel = useLabel("admin_experience")

  const sourceEvents = useFragment<CloneProductModalStepEventsFragment$key>(
    graphql`
      fragment CloneProductModalStepEventsFragment on Product {
        allOccurrences {
          totalCount
          edges {
            node {
              id
              eventId
              name
              datetimeRange
              timezone
            }
          }
        }
      }
    `,
    eventKey
  )

  useEffect(() => {
    if (!form.state.includeEvents) {
      form.state.events?.replace([])
      return
    }
    calculateAndAddEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.includeEvents, form.state.startDate])

  const eventCount = sourceEvents?.allOccurrences.totalCount || 0
  return (
    <>
      <DiscoModal
        isOpen={isOpen}
        onClose={handleClose}
        title={`Clone ${experienceLabel.singular}`}
        width={"550px"}
        subtitle={`${
          form.state.sourceProduct?.name || `This ${experienceLabel.singular}`
        } contains ${eventCount || "no "} ${pluralize("event", eventCount)}. ${
          eventCount ? getStepSubtitle() : "You can proceed to the next step, below."
        }`}
        testid={"CloneProductModalStepEvents"}
        modalContentLabel={`Clone ${experienceLabel.singular}`}
        body={
          !!eventCount &&
          !!form.state.events?.length && (
            <>
              {form.state.events.map((event, i) => (
                <CloneProductEventPreview
                  key={event.eventId}
                  event={event}
                  testid={`CloneProductModalStepEvents.item-${i}`}
                />
              ))}
            </>
          )
        }
        buttons={
          <>
            <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
              {"Cancel"}
            </DiscoButton>
            <DiscoButton
              type={"submit"}
              shouldDisplaySpinner={form.isSubmitting}
              onClick={() => handleNext("success")}
            >
              {"Clone"}
            </DiscoButton>
          </>
        }
      />
      <CloneProductEditEventDetails />
    </>
  )

  function calculateAndAddEvents() {
    const events = Relay.connectionToArray(sourceEvents?.allOccurrences)
    // The offset to use if the source experience is evergreen
    // For evergreen, assume the first event will start in 7 days and adjust the rest of the events based on that
    const evergreenEventDateOffset = format(addDays(new Date(), 7), "yyyy-MM-dd")
    const diff = getDifferenceInDaysBetweenDates(
      form.state.startDate || evergreenEventDateOffset,
      // If the source experience is evergreen, use the current date as the baseline for the day difference
      form.state.sourceProduct!.startDate || new Date().toISOString()
    )
    form.state.events?.replace(
      events.map(({ id, eventId, datetimeRange: [startsAt, endsAt], name, timezone }) => {
        let start = addDays(new Date(startsAt), diff)
        const duration = differenceInMinutes(new Date(endsAt!), new Date(startsAt!))
        // we should not generate any invalid datetimes. Events can only be created in future - 1 hour in advance for good measure
        if (isBefore(new Date(start), new Date())) start = addHours(new Date(), 1)
        return {
          occurrenceId: id,
          eventId,
          startDatetime: start.toISOString(),
          timezone,
          name: name!,
          duration,
        }
      })
    )
  }

  function getStepSubtitle() {
    return `We've scheduled ${eventCount > 1 ? "them" : "it"} ${
      form.state.sourceProduct?.startDate
        ? ` based on the start date of your new ${experienceLabel.singular}`
        : " for you"
    }. You can change the ${pluralize("date", eventCount)} now or update later.`
  }
}

export default observer(CloneProductModalStepEvents)
