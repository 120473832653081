import CloudsIcon from "@/core/ui/images/empty-state/clouds.svg"
import CreateMemberGroupButton from "@/product/common/member-group/common/create/button/CreateMemberGroupButton"
import { CourseGroupsListFragment$key } from "@/product/course/settings/groups-settings/list/__generated__/CourseGroupsListFragment.graphql"
import CourseGroupsListItem, {
  CourseGroupsListItemSkeleton,
} from "@/product/course/settings/groups-settings/list/item/CourseGroupsListItem"
import Relay from "@/relay/relayUtils"
import { DiscoButton, DiscoEmptyState } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface CourseGroupsListProps {
  productKey: CourseGroupsListFragment$key
  classes?: {
    itemContainer?: string
  }
  hideEmptyState?: boolean
}

function CourseGroupsList({
  productKey,
  classes,
  hideEmptyState = false,
}: CourseGroupsListProps) {
  const product = useFragment<CourseGroupsListFragment$key>(
    graphql`
      fragment CourseGroupsListFragment on Product {
        id
        defaultMemberGroup {
          ...CreateMemberGroupButtonFragment
        }
        memberGroups(kind: custom) {
          edges {
            node {
              id
              ...CourseGroupsListItemFragment
            }
          }
        }
        ...usePermissionsFragment
      }
    `,
    productKey
  )

  const memberGroups = Relay.connectionToArray(product?.memberGroups)
  const canCreate = usePermissions(product).has("member_groups.manage")

  return (
    <>
      {memberGroups.length > 0 ? (
        <ul>
          {memberGroups.map((group, i) => (
            <CourseGroupsListItem
              key={group.id}
              groupKey={group}
              testid={`CourseGroupsListItem-${i}`}
              className={classes?.itemContainer}
            />
          ))}
        </ul>
      ) : (
        !hideEmptyState && (
          <DiscoEmptyState
            testid={"CourseGroupsList"}
            title={"There are no groups yet"}
            icon={<CloudsIcon />}
            subtitle={
              "Break your cohort into small groups for better connection and learning."
            }
            buttons={
              canCreate ? (
                <CreateMemberGroupButton groupKey={product.defaultMemberGroup} isSubGroup>
                  <DiscoButton
                    data-testid={"CourseGroupsList.create-group-button"}
                    leftIcon={"add"}
                  >
                    {"New Group"}
                  </DiscoButton>
                </CreateMemberGroupButton>
              ) : null
            }
          />
        )
      )}
    </>
  )
}

export const CourseGroupsListSkeleton: React.FC = () => {
  return (
    <ul>
      <CourseGroupsListItemSkeleton />
      <CourseGroupsListItemSkeleton />
      <CourseGroupsListItemSkeleton />
    </ul>
  )
}

export default Relay.withSkeleton<CourseGroupsListProps>({
  component: CourseGroupsList,
  skeleton: CourseGroupsListSkeleton,
})
