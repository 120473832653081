/**
 * @generated SignedSource<<53aa280a5fd7374dd98144f00250379f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendCustomizablePreviewEmailInput = {
  kind: string;
  productId: string;
  subject: string;
  title: string;
  richEditorBody: string;
  ctaText: string;
  ctaUrl: string;
  notificationsUrl: string;
};
export type EditEmailTemplateFormPreviewMutation$variables = {
  input: SendCustomizablePreviewEmailInput;
};
export type EditEmailTemplateFormPreviewMutation$data = {
  readonly sendCustomizablePreviewEmail: boolean | null;
};
export type EditEmailTemplateFormPreviewMutation = {
  variables: EditEmailTemplateFormPreviewMutation$variables;
  response: EditEmailTemplateFormPreviewMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "sendCustomizablePreviewEmail",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEmailTemplateFormPreviewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditEmailTemplateFormPreviewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d88e8c2b9fd93c1e160a7e083ae296ca",
    "id": null,
    "metadata": {},
    "name": "EditEmailTemplateFormPreviewMutation",
    "operationKind": "mutation",
    "text": "mutation EditEmailTemplateFormPreviewMutation(\n  $input: SendCustomizablePreviewEmailInput!\n) {\n  sendCustomizablePreviewEmail(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "69bdb5d8b9c2488d5d1ba7e873e5ea3d";

export default node;
