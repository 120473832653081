import { useActiveProduct } from "@/core/context/ActiveProductContext"
import MemberGroupTag from "@/product/common/member-group/common/tag/MemberGroupTag"
import { useMemberGroupTagsList_OrganizationMembershipFragment$key } from "@/product/common/member-group/common/tag/__generated__/useMemberGroupTagsList_OrganizationMembershipFragment.graphql"
import { useMemberGroupTagsList_ProductMembershipFragment$key } from "@/product/common/member-group/common/tag/__generated__/useMemberGroupTagsList_ProductMembershipFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  // Use organizationMembershipKey for both organization & active product group tags
  organizationMembershipKey?: useMemberGroupTagsList_OrganizationMembershipFragment$key | null
  // Use productMembershipKey for just product group tags
  productMembershipKey?: useMemberGroupTagsList_ProductMembershipFragment$key | null
  testid?: string
  linkToMembers?: boolean
}

function useMemberGroupTagsList({
  organizationMembershipKey,
  productMembershipKey,
  testid = "MemberGroupTagList",
  linkToMembers,
}: Props) {
  const organizationMembership =
    useFragment<useMemberGroupTagsList_OrganizationMembershipFragment$key>(
      graphql`
        fragment useMemberGroupTagsList_OrganizationMembershipFragment on OrganizationMembership
        @argumentDefinitions(first: { type: "Int" }) {
          id
          groupMemberships(first: $first, groupKinds: [custom])
            @connection(
              key: "useMemberGroupTagsList_OrganizationMembershipFragment_groupMemberships"
            ) {
            edges {
              node {
                id
                productId
                memberGroup {
                  id
                  parentMemberGroupId
                  visibility
                  ...MemberGroupTagFragment
                }
              }
            }
          }
        }
      `,
      organizationMembershipKey || null
    )

  const productMembership =
    useFragment<useMemberGroupTagsList_ProductMembershipFragment$key>(
      graphql`
        fragment useMemberGroupTagsList_ProductMembershipFragment on ProductMembership
        @argumentDefinitions(first: { type: "Int" }) {
          id
          memberGroupMemberships(first: $first, groupKinds: [custom])
            @connection(
              key: "useMemberGroupTagsList_ProductMembershipFragment_memberGroupMemberships"
            ) {
            edges {
              node {
                id
                productId
                memberGroup {
                  id
                  parentMemberGroupId
                  visibility
                  ...MemberGroupTagFragment
                }
              }
            }
          }
        }
      `,
      // Product group memberships are included in the org membership
      organizationMembershipKey ? null : productMembershipKey || null
    )

  const activeProduct = useActiveProduct()
  const memberGroupMemberships = Relay.connectionToArray(
    organizationMembership?.groupMemberships || productMembership?.memberGroupMemberships
  ).filter((mgm) => !mgm.productId || mgm.productId === activeProduct?.id)

  // Nest child groups into their parent groups if present. If the parent isn't
  // present because it's a system group, show the child group on its own.
  const childGroups: Record<
    GlobalID,
    Array<(typeof memberGroupMemberships)[0]["memberGroup"]>
  > = {}
  for (const { memberGroup } of memberGroupMemberships) {
    if (!memberGroup.parentMemberGroupId) childGroups[memberGroup.id] = []
  }
  const parentGroups = []
  for (const { memberGroup } of memberGroupMemberships) {
    const { parentMemberGroupId } = memberGroup
    if (parentMemberGroupId && childGroups[parentMemberGroupId]) {
      childGroups[parentMemberGroupId].push(memberGroup)
    } else {
      parentGroups.push(memberGroup)
    }
  }

  // Also sort the tags by visibility. There will never be any adminOnly tags when using
  // productMembershipKey instead of organizationMembershipKey
  const everyoneTags = [],
    adminOnlyTags = []
  for (const mg of parentGroups) {
    const tag = (
      <MemberGroupTag
        key={mg.id}
        memberGroupKey={mg}
        testid={testid}
        childGroups={childGroups[mg.id]}
        linkToMembers={linkToMembers}
        maxWidth={150}
      />
    )
    if (mg.visibility === "admin_only") {
      adminOnlyTags.push(tag)
    } else {
      everyoneTags.push(tag)
    }
  }
  return { everyoneTags, adminOnlyTags }
}

export default useMemberGroupTagsList
