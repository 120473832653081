import AddMembersToGroupsModal from "@/product/common/member-group/modal/AddMembersToGroupsModal"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"

type AddMembersToGroupsButtonProps = {
  memberships: { id: GlobalID; memberId: GlobalID }[]
  children: OverridableDiscoButtonChildren
  disabled?: boolean
  handleCloseFloatingBar: () => void
}

const AddMembersToGroupsButton: React.FC<AddMembersToGroupsButtonProps> = ({
  memberships,
  children,
  disabled,
  handleCloseFloatingBar,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
        {children}
      </OverridableDiscoButton>

      {isModalOpen && (
        <AddMembersToGroupsModal
          memberships={memberships}
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAdd={handleAdd}
        />
      )}
    </>
  )

  function handleAdd() {
    setIsModalOpen(false)

    handleCloseFloatingBar()
  }
}

export default AddMembersToGroupsButton
