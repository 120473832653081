import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useConfettiCannon } from "@/core/context/ConfettiContext"
import { useLabels } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { PublishExperienceButtonMutation } from "@/product/buttons/PublishProduct/__generated__/PublishExperienceButtonMutation.graphql"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { observer } from "mobx-react-lite"
import { ConnectionHandler } from "react-relay"
import { graphql } from "relay-runtime"

type PublishExperienceButtonProps = OverridableDiscoButtonProps & {
  onPublish?: VoidFunction
  skipConfirmation?: boolean
}

function PublishExperienceButton({
  children,
  onPublish,
  skipConfirmation = false,
  ...rest
}: PublishExperienceButtonProps) {
  const activeProduct = useActiveProduct()!
  const labels = useLabels()
  const confettiCannon = useConfettiCannon()

  const form = useFormStore<PublishExperienceButtonMutation>(
    graphql`
      mutation PublishExperienceButtonMutation($input: UpdateProductVisibilityInput!) {
        response: updateProductVisibility(input: $input) {
          node {
            id
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: activeProduct.id,
      status: "published",
    }
  )

  if (skipConfirmation) {
    return (
      <OverridableDiscoButton
        {...rest}
        onClick={publishProduct}
        shouldDisplaySpinner={form.isSubmitting}
      >
        {children}
      </OverridableDiscoButton>
    )
  }

  return (
    <>
      <OverridableDiscoButton
        modal={({ isOpen, onClose }) => (
          <DiscoWarningModal
            testid={"PublishExperienceButton.publish-confirmation-modal"}
            isOpen={isOpen}
            onClose={onClose}
            variant={"primary"}
            title={`Do you want to publish this ${activeProduct.typeLabel}?`}
            description={
              activeProduct.registrationAvailability === "public"
                ? `Publishing this ${activeProduct.typeLabel.toLowerCase()} will make it visible to ${
                    labels.organization_member.plural
                  } and allow you to start taking registrations.`
                : `Publishing this ${activeProduct.typeLabel.toLowerCase()} will allow you to start inviting ${
                    labels.organization_member.plural
                  } and taking registrations.`
            }
            confirmationButtonProps={{
              onClick: publishProduct,
              shouldDisplaySpinner: form.isSubmitting,
              children: `Publish ${activeProduct.typeLabel}`,
            }}
            modalContentLabel={"Publish Product Dialog"}
          />
        )}
        {...rest}
      >
        {children}
      </OverridableDiscoButton>
    </>
  )

  async function publishProduct() {
    try {
      const { response, didSave } = await form.submit(
        {
          id: activeProduct.id,
          status: "published",
        },
        {
          updater: (store) => {
            const productRecord = store.get(activeProduct.id)
            if (!productRecord) return

            // Invalidate product admin actions list so relevant ones can be refetched
            const actionsConnection = ConnectionHandler.getConnection(
              productRecord,
              "ProductAdminDashboardActions__productAdminActions"
            )
            actionsConnection?.invalidateRecord()
          },
        }
      )

      if (!didSave || !response) return
      displaySuccessToast({
        message: `Your ${activeProduct.typeLabel.toLowerCase()} has been published!`,
        testid: "PublishExperienceButton.success-toast",
      })
      confettiCannon()
      onPublish?.()
    } catch (error) {
      displayErrorToast({
        message: `There was an issue publishing your ${activeProduct.typeLabel.toLowerCase()}`,
        testid: "PublishExperienceButton.error-toast",
      })
    }
  }
}

export default observer(PublishExperienceButton)
