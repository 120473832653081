import ContentUtils from "@/content/util/contentUtils"
import { useLabels } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { FlagContentFormFragment$key } from "@/product/common/page/content/flagging/__generated__/FlagContentFormFragment.graphql"
import {
  ContentFlaggingReason,
  FlagContentFormMutation,
} from "@/product/common/page/content/flagging/__generated__/FlagContentFormMutation.graphql"
import Form from "@components/form/Form"
import { displayToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoInput,
  DiscoSelect,
  DiscoText,
} from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  contentKey: FlagContentFormFragment$key
  onClose(): void
}

function FlagContentForm(props: Props) {
  const { contentKey, onClose } = props

  const content = useFragment<FlagContentFormFragment$key>(
    graphql`
      fragment FlagContentFormFragment on Content {
        id
        type
        product {
          id
        }
      }
    `,
    contentKey
  )

  const form = useFormStore<FlagContentFormMutation>(
    graphql`
      mutation FlagContentFormMutation($input: FlagContentInput!) {
        response: flagContent(input: $input) {
          node {
            id
            content {
              ...FlagContentButtonFragment
              ...ContentFlaggingsChipFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentId: content.id,
      reason: "community_policy",
      reasonText: "",
    },
    { requireChangeToSubmit: false }
  )

  const labels = useLabels()
  const adminLabel = content.product?.id
    ? labels.product_admin.plural
    : labels.organization_admin.plural
  const parentLabel = content.product?.id ? labels.experience.singular : "Community"

  return (
    <Form
      id={"FlagContentForm"}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton form={form} id={"FlagContentForm"}>
            {"Flag Post"}
          </Form.SubmitButton>
        </>
      }
    >
      <DiscoText marginBottom={2}>
        {`The ${adminLabel} of this ${parentLabel} will be notified of your concerns.`}
      </DiscoText>
      <DiscoFormControl label={"What is wrong with this post?"}>
        <DiscoSelect
          value={form.state.reason}
          onChange={(v) => (form.state.reason = v as ContentFlaggingReason)}
          options={ContentUtils.FLAG_REASON_OPTIONS.map((v) => ({
            value: v,
            title: ContentUtils.getFlagReasonLabel(v),
          }))}
          disableClearable
          testid={"FlagContentForm.reason"}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Anything else to add?"}>
        <DiscoInput
          placeholder={"Explain why you think this post should be removed"}
          value={form.state.reasonText}
          onChange={(e) => (form.state.reasonText = e.target.value)}
          multiline
          minRows={3}
          data-testid={"FlagContentForm.reason-text"}
        />
      </DiscoFormControl>
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) return
    displayToast({ message: "Post flagged", testid: "FlagContentForm.success-toast" })
    onClose()
  }
}

export default observer(FlagContentForm)
