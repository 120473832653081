namespace EmailTemplate {
  export enum KIND {
    WELCOME = "welcome",
    STARTS_TOMORROW = "starts-tomorrow",
    WAITING_ROOM = "waiting-room",
    COMMUNITY_EVENT_PUBLISHED = "community-event-published",
    ASSIGNMENT_DUE_REMINDER = "assignment-due-reminder",
  }
}

export default EmailTemplate
