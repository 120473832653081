import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { withDiscoDefaultTheme } from "@/core/context/CustomThemeProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { useStopViewAsMember } from "@/core/route/util/routeUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Toolbar } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"

function ViewAsAppBar() {
  const classes = useStyles()
  const isViewAsMember = useIsAdminViewingAsMember()
  const isMobile = useIsMobile()
  const activeProduct = useActiveProduct()
  const memberLabel = useLabel(activeProduct ? "product_member" : "organization_member")
  const experienceLabel = useLabel("experience")
  const stopViewAsMember = useStopViewAsMember()
  const isWebView = useIsWebView()

  if (!isViewAsMember || isWebView) return null

  return (
    <Toolbar
      classes={{
        root: classes.toolbarRoot,
      }}
    >
      <div>
        <DiscoText testid={"ViewAsAppBar.viewing-as-member"} color={"common.white"}>
          {`Viewing as ${memberLabel.singular}`}
        </DiscoText>
        {!isMobile && (
          <DiscoText variant={"body-xs-600"} color={"groovy.grey.200"}>
            {`You are currently viewing this ${
              activeProduct ? experienceLabel.singular : "Community"
            } as a ${memberLabel.singular} and will see the ${
              experienceLabel.singular
            } a ${memberLabel.singular} sees when engaging in your Disco ${
              activeProduct ? experienceLabel.singular : "Community"
            }.`}
          </DiscoText>
        )}
      </div>
      <DiscoButton testid={"ViewAsAppBar.turn-off-button"} onClick={stopViewAsMember}>
        {"Exit Preview"}
      </DiscoButton>
    </Toolbar>
  )
}

export default withDiscoDefaultTheme(ViewAsAppBar)

const useStyles = makeUseStyles((theme) => ({
  toolbarRoot: {
    display: "flex",
    width: "100%",
    minHeight: `${theme.mixins.toolbar.minHeight}px`,
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.groovy.grey[700],
    borderBottom: `2px solid ${theme.palette.groovy.onDark[400]}`,
    // same horizontal padding as PageContent component
    padding: theme.spacing(2, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}))
