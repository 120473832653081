import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { CreateMemberGroupButtonFragment$key } from "@/product/common/member-group/common/create/button/__generated__/CreateMemberGroupButtonFragment.graphql"
import CreateMemberGroupForm from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import CreateSubGroupForm from "@/product/common/member-group/common/create/form/CreateSubGroupForm"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

type CreateMemberGroupButtonProps = {
  groupKey: CreateMemberGroupButtonFragment$key
  isSubGroup?: boolean
  children: OverridableDiscoButtonChildren
  onCreate?: () => void
  selectedMembershipIds?: GlobalID[]
} & OverridableDiscoButtonProps

function CreateMemberGroupButton(props: CreateMemberGroupButtonProps) {
  const activeProduct = useActiveProduct()
  const membersLabel = useLabel(activeProduct ? "product_member" : "organization_member")
  const {
    children,
    groupKey,
    onCreate,
    isSubGroup = false,
    selectedMembershipIds = [],
    ...rest
  } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const memberGroup = useFragment<CreateMemberGroupButtonFragment$key>(
    graphql`
      fragment CreateMemberGroupButtonFragment on MemberGroup {
        id
      }
    `,
    groupKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} {...rest}>
        {children}
      </OverridableDiscoButton>
      <DiscoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        width={"720px"}
        testid={"CreateMemberGroupButtonModal"}
        modalContentLabel={"CreateMemberGroupButtonModal"}
        title={isSubGroup ? "Create a Sub-group" : "Create a Group"}
        subtitle={
          isSubGroup
            ? `Sub-groups help to foster deeper connections among ${membersLabel.plural}, encourage more focused and relevant discussions, and provide a more personalized and engaging experience.`
            : `Groups are used to group a collection of ${membersLabel.plural} and provide access to specific events, assignments and community experiences.`
        }
        buttons
        body={
          isSubGroup ? (
            <CreateSubGroupForm
              memberGroupId={memberGroup.id}
              onCancel={closeModal}
              onSave={onCreateGroup}
              selectedMembershipIds={selectedMembershipIds}
            />
          ) : (
            <CreateMemberGroupForm
              memberGroupId={memberGroup.id}
              onCancel={closeModal}
              onSave={onCreateGroup}
              selectedMembershipIds={selectedMembershipIds}
            />
          )
        }
      />
    </>
  )

  function onCreateGroup() {
    closeModal()

    if (onCreate) {
      onCreate()
    }
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

export default CreateMemberGroupButton
