import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useActiveCloneProductModalContext } from "@/product/clone/sequence/CloneProductModalContext"
import { GlobalID } from "@/relay/RelayTypes"
import useUserTimezone from "@/user/util/useUserTimezone"
import Calendar from "@/core/ui/iconsax/linear/calendar.svg"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { format } from "date-fns-tz"
import { observer } from "mobx-react-lite"

type CurriculumModulePreviewProps = {
  module: {
    sourceId: GlobalID
    name: string
    releasedAt?: string | null
  }
  testid: string
}

function CloneProductCurriculumModulePreview({
  module,
  testid,
}: CurriculumModulePreviewProps) {
  const { form } = useActiveCloneProductModalContext()!
  const classes = useStyles()
  const userTimezone = useUserTimezone()
  return (
    <Grid
      key={module.sourceId}
      container
      data-testid={testid}
      justifyContent={"space-between"}
      alignContent={"center"}
      className={classes.container}
    >
      <Grid item>
        <DiscoText variant={"body-md-700"} marginBottom={0.5}>
          {module.name}
        </DiscoText>
        <DiscoText variant={"body-sm"} color={"groovy.grey.400"}>
          {module.releasedAt
            ? format(
                new Date(module.releasedAt),
                DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
                {
                  timeZone: userTimezone,
                }
              )
            : "Releases immediately"}
        </DiscoText>
      </Grid>
      <Grid item>
        <DiscoButton
          color={"grey"}
          className={classes.iconButton}
          onClick={() => (form.state.editModuleSourceId = module.sourceId)}
        >
          <Calendar className={classes.icon} />
        </DiscoButton>
      </Grid>
    </Grid>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(1.25, 0),
  },
  icon: {
    height: "24px",
    width: "24px",
  },
  iconButton: {
    padding: theme.spacing(0, 1.5),
  },
}))

export default observer(CloneProductCurriculumModulePreview)
