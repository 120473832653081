import ContentFlaggingListPopover from "@/product/common/page/content/flagging/popover/ContentFlaggingListPopover"
import { ContentFlaggingsChipFragment$key } from "@/product/common/page/content/flagging/popover/__generated__/ContentFlaggingsChipFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoChip, DiscoIcon } from "@disco-ui"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { TestIDProps } from "@utils/typeUtils"
import { useCallback, useEffect, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  contentKey: ContentFlaggingsChipFragment$key
  autoOpen?: boolean
}

function ContentFlaggingsChip(props: Props) {
  const { contentKey, autoOpen, testid = "ContentFlaggingsChip" } = props

  const content = useFragment<ContentFlaggingsChipFragment$key>(
    graphql`
      fragment ContentFlaggingsChipFragment on Content {
        flaggings {
          edges {
            node {
              id
            }
          }
        }
        ...ContentFlaggingListPopoverFragment
      }
    `,
    contentKey
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  // Look for a ?flaggingId param set to show a specific flagging automatically.
  const [{ flaggingId }, setParams] = useQueryParamState<{ flaggingId: GlobalID }>()
  const flaggings = Relay.connectionToArray(content.flaggings)
  const flaggingById = flaggings.find((f) => f.id === flaggingId)

  useEffect(() => {
    if (flaggingById && autoOpen) {
      setIsOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flaggingById?.id])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    if (flaggingById) {
      setParams({ flaggingId: undefined })
    }
  }, [flaggingById, setIsOpen, setParams])

  if (!flaggings.length) return null

  return (
    <>
      <DiscoChip
        ref={setAnchorEl}
        color={"red"}
        icon={<DiscoIcon icon={"flag"} width={16} height={16} />}
        label={"Flagged"}
        onClick={(e) => {
          // Stop propagation so that clicking the chip doesn't open post drawer
          e.stopPropagation()
          setIsOpen(true)
        }}
        testid={`${testid}.flagged-chip`}
      />
      {anchorEl && (
        <ContentFlaggingListPopover
          isOpen={isOpen}
          onClose={handleClose}
          anchorEl={anchorEl}
          contentKey={content}
        />
      )}
    </>
  )
}

export default ContentFlaggingsChip
