import { useLabel } from "@/core/context/LabelsContext"
import { useActiveCloneProductModalContext } from "@/product/clone/sequence/CloneProductModalContext"
import CloneProductCurriculumModulePreview from "@/product/clone/sequence/modal/curriculum-modules/CloneProductCurriculumModulePreview"
import CloneProductEditModuleDetails from "@/product/clone/sequence/modal/curriculum-modules/CloneProductEditModuleDetails"
import { CloneProductModalStepCurriculumModulesFragment$key } from "@/product/clone/sequence/modal/curriculum-modules/__generated__/CloneProductModalStepCurriculumModulesFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import { DiscoButton, DiscoModal } from "@disco-ui"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { getDifferenceInDaysBetweenDates } from "@utils/time/timeUtils"
import { addDays } from "date-fns"
import { format } from "date-fns-tz"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect } from "react"
import { graphql, useFragment } from "react-relay"

type CloneProductModalStepCurriculumModulesProps = {
  curriculumKey: CloneProductModalStepCurriculumModulesFragment$key | null
}

function CloneProductModalStepCurriculumModules({
  curriculumKey,
}: CloneProductModalStepCurriculumModulesProps) {
  const { form, isOpen, handleClose, handleNext } =
    useActiveCloneProductModalContext("curriculum_modules")!
  const userTimezone = useUserTimezone()
  const experienceLabel = useLabel("admin_experience")

  const sourceCurriculum =
    useFragment<CloneProductModalStepCurriculumModulesFragment$key>(
      graphql`
        fragment CloneProductModalStepCurriculumModulesFragment on Curriculum {
          id
          modules {
            totalCount
            edges {
              node {
                id
                releasedAt
                content {
                  id
                  name
                  children {
                    edges {
                      node {
                        id
                        dueAt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      curriculumKey
    )

  useEffect(() => {
    if (!form.state.includeCurriculum) {
      form.state.curriculumContentModuleUsages?.replace([])
      return
    }
    calculateAndAddCurriculumModules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.includeCurriculum, form.state.startDate])

  const moduleCount = sourceCurriculum?.modules.totalCount
  const curriculumModules = Relay.connectionToArray(sourceCurriculum?.modules)

  const items = curriculumModules
    .map((cm) => Relay.connectionToArray(cm.content.children))
    .flat(1)
  const itemsWithDueDate: { id: GlobalID; dueAt: string }[] = items
    .filter((a) => Boolean(a.dueAt))
    .map((a) => ({ ...a, dueAt: a.dueAt! }))

  return (
    <>
      <DiscoModal
        isOpen={isOpen}
        onClose={handleClose}
        title={`Clone ${experienceLabel.singular}`}
        width={"550px"}
        subtitle={`${
          form.state.sourceProduct?.name || `This ${experienceLabel.singular}`
        } contains ${moduleCount || "no curriculum"} ${pluralize(
          "module",
          moduleCount
        )}. ${
          moduleCount
            ? `We've scheduled ${moduleCount > 1 ? "them" : "it"} ${
                form.state.sourceProduct?.startDate
                  ? ` based on the start date of your new ${experienceLabel.singular}`
                  : " for you"
              }. You can change the ${pluralize(
                "date",
                moduleCount
              )} now or update them later.`
            : "You can proceed to the next step, below."
        }`}
        testid={"CloneProductModalStepCurriculumModules"}
        modalContentLabel={`Clone ${experienceLabel.singular}`}
        body={
          !!moduleCount && form.state.curriculumContentModuleUsages?.length ? (
            <>
              {form.state.curriculumContentModuleUsages.map((cm, i) => (
                <CloneProductCurriculumModulePreview
                  key={cm.sourceId}
                  module={{
                    sourceId: cm.sourceId,
                    releasedAt: cm.releasedAt,
                    name: cm.name,
                  }}
                  testid={`CloneProductModalStepCurriculumModules.item-${i}`}
                />
              ))}
            </>
          ) : null
        }
        buttons={
          <>
            <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
              {"Cancel"}
            </DiscoButton>
            <DiscoButton
              testid={"CloneProductModalStepCurriculumModules.submit"}
              shouldDisplaySpinner={form.isSubmitting}
              onClick={handleOnNextClick}
            >
              {getContinueButtonText()}
            </DiscoButton>
          </>
        }
      />
      <CloneProductEditModuleDetails />
    </>
  )

  function handleOnNextClick() {
    if (itemsWithDueDate.length) {
      handleNext("item_due_dates")
    } else if (form.state.includeEvents) {
      handleNext("events")
    } else {
      handleNext("success")
    }
  }

  function getContinueButtonText() {
    if (items.length || form.state.includeEvents) {
      return "Continue"
    }
    return "Clone"
  }

  function calculateAndAddCurriculumModules() {
    const sourceModules = Relay.connectionToArray(sourceCurriculum?.modules)
    const diff =
      form.state.startDate && form.state.sourceProduct!.startDate
        ? getDifferenceInDaysBetweenDates(
            form.state.startDate,
            form.state.sourceProduct!.startDate
          )
        : 0

    form.state.curriculumContentModuleUsages?.replace(
      sourceModules.map(({ id, releasedAt, content }) => {
        const start = releasedAt ? addDays(new Date(releasedAt), diff) : null
        return {
          sourceId: id,
          // Don't set releasedAt if the source curriculum section doesn't have a start date
          releasedAt: start
            ? format(start, DATE_FORMAT.DATETIME_API_FORMAT, {
                timeZone: userTimezone,
              })
            : null,
          name: content.name!,
          contentId: content.id,
        }
      })
    )
  }
}

export default observer(CloneProductModalStepCurriculumModules)
