import { useLabels } from "@/core/context/LabelsContext"
import { AdminMemberGroupTableQuery$data } from "@/product/common/member-group/common/__generated__/AdminMemberGroupTableQuery.graphql"
import { UnassignedMemberGroupTableQuery$data } from "@/product/common/member-group/common/__generated__/UnassignedMemberGroupTableQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import UserDropdownItem from "@/user/common/UserDropdownItem"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoInputSkeleton } from "@disco-ui"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
} from "@disco-ui/select/DiscoMultiSelect"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useMemo } from "react"

interface MemberGroupMultiSelectProps extends TestIDProps {
  memberGroup:
    | AdminMemberGroupTableQuery$data["memberGroup"]
    | UnassignedMemberGroupTableQuery$data["memberGroup"]
  placeholder?: string
  values: GlobalID[]
  onChange: (values: GlobalID[]) => void
  hideUserIds?: GlobalID[]
}

function MemberGroupMembershipMultiSelect(props: MemberGroupMultiSelectProps) {
  const { testid, placeholder, memberGroup, values, onChange } = props
  const labels = useLabels()
  const classes = useStyles()

  const visibleMemberships = useMemo(() => {
    const memberships = Relay.connectionToArray(memberGroup?.memberGroupMemberships)
    const hideUserIds = new Set(props.hideUserIds)
    return hideUserIds.size > 0
      ? memberships.filter((m) => !hideUserIds.has(m.user.id))
      : memberships
  }, [memberGroup?.memberGroupMemberships, props.hideUserIds])

  const membershipsByUserId = useMemo(
    () => ArrayUtils.mapBy(visibleMemberships, "userId"),
    [visibleMemberships]
  )

  if (!memberGroup) return null

  return (
    <DiscoMultiSelect
      testid={testid || "MemberGroupMembershipMultiSelect"}
      placeholder={placeholder || labels.admin_member.plural}
      values={values}
      onChange={handleChange}
      renderOption={renderOption}
      renderTags={() => null}
      classes={{ inputRoot: classes.input }}
      options={visibleMemberships.map((membership) => ({
        key: membership.id,
        value: membership.user.id,
        title: membership.user.fullName,
        searchable: [membership.user.fullName, membership.organizationMembership.email],
      }))}
      additionalSubmitKeys={[","]}
    />
  )

  function handleChange(userIds: string[]) {
    onChange(userIds)
  }

  function renderOption(option: DiscoMultiSelectOption) {
    const membership = membershipsByUserId[option.value]
    if (!membership) return <></>
    return (
      <UserDropdownItem
        testid={`MemberGroupMembershipMultiSelect.option.${option.title}`}
        userKey={membership.user}
        privateUserKey={null}
      />
    )
  }
}

const useStyles = makeUseStyles({
  input: {
    "& input[type=text]": {
      height: "40px",
    },
  },
})

export default Relay.withSkeleton<MemberGroupMultiSelectProps>({
  component: MemberGroupMembershipMultiSelect,
  skeleton: () => <DiscoInputSkeleton />,
})
