import { useActiveProduct } from "@/core/context/ActiveProductContext"
import PermissionedRoute from "@/core/route/permissioned-route/PermissionedRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { Switch } from "react-router"

const ProductPostDetailPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "product-post-detail-page" */ "@/content/post/ProductPostDetailPage"
    )
)
const Waitroom = lazyWithRetry(
  () => import(/* webpackChunkName: "waitroom" */ "@/product/course/waitroom/Waitroom")
)

function WaitingRoomFlows() {
  const activeProduct = useActiveProduct()!
  return (
    <Switch>
      {/* Routes users can access even when waiting room is enabled */}
      {getEarlyAccessRoutes()}
      {/* Waiting Room - All routes */}
      <PermissionedRoute
        path={`${ROUTE_NAMES.PRODUCT.ROOT}/*`}
        permission={"curriculum.read"}
        entityKey={activeProduct}
        metaTitle={`${activeProduct.name} - Waiting Room`}
      >
        <Waitroom />
      </PermissionedRoute>
    </Switch>
  )

  function getEarlyAccessRoutes() {
    return [
      <PermissionedRoute
        key={ROUTE_NAMES.PRODUCT.FEED.POSTS.DETAIL}
        path={ROUTE_NAMES.PRODUCT.FEED.POSTS.DETAIL}
        exact
        entityKey={activeProduct}
        permission={"posts.read"}
        metaTitle={`${activeProduct.name} - Post`}
      >
        <ProductPostDetailPage />
      </PermissionedRoute>,
    ]
  }
}

export default WaitingRoomFlows
