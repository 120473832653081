/**
 * @generated SignedSource<<d050aae78845baa24b6cbb57c2be25a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditEmailTemplateFormFragment$data = {
  readonly id: string;
  readonly subject: string;
  readonly title: string;
  readonly body: string;
  readonly richEditorBody: string | null;
  readonly kind: string;
  readonly ctaText: string;
  readonly " $fragmentType": "EditEmailTemplateFormFragment";
};
export type EditEmailTemplateFormFragment$key = {
  readonly " $data"?: EditEmailTemplateFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditEmailTemplateFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditEmailTemplateFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorBody",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaText",
      "storageKey": null
    }
  ],
  "type": "EmailTemplate",
  "abstractKey": null
};

(node as any).hash = "754d52e4219790a854dcda51aef7de0e";

export default node;
