import { MemberGroupKind } from "@/admin/members/groups/__generated__/AdminGroupsListPageQuery.graphql"
import { useLabels } from "@/core/context/LabelsContext"
import { ArrayElement } from "@/core/context/NotificationsContext"
import MemberGroupTag from "@/product/common/member-group/common/tag/MemberGroupTag"
import { MemberGroupsMultiSelect_AppFragment$key } from "@/product/common/member-group/modal/components/__generated__/MemberGroupsMultiSelect_AppFragment.graphql"
import {
  MemberGroupsMultiSelect_OrganizationFragment$data,
  MemberGroupsMultiSelect_OrganizationFragment$key,
} from "@/product/common/member-group/modal/components/__generated__/MemberGroupsMultiSelect_OrganizationFragment.graphql"
import { MemberGroupsMultiSelect_ProductFragment$key } from "@/product/common/member-group/modal/components/__generated__/MemberGroupsMultiSelect_ProductFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
} from "@disco-ui/select/DiscoMultiSelect"
import { PopperProps } from "@material-ui/core"
import { AutocompleteGetTagProps } from "@material-ui/lab"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useMemo } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type MemberGroupData = ArrayElement<
  NonNullable<
    NonNullable<MemberGroupsMultiSelect_OrganizationFragment$data>["memberGroups"]
  >["edges"]
>["node"]

export type MemberGroupsMultiSelectProps = TestIDProps & {
  productKey?: MemberGroupsMultiSelect_ProductFragment$key | null
  organizationKey?: MemberGroupsMultiSelect_OrganizationFragment$key | null
  appKey?: MemberGroupsMultiSelect_AppFragment$key | null
  selectedGroupIds: GlobalID[]
  setSelectedGroupIds: (groupIds: GlobalID[]) => void
  disabled?: boolean
  /** The viewer must be in the group to select it */
  requireGroupMembership?: boolean
  /** a setState callback that tracks whether the user has a membership to any of the selected groups */
  setHasAtleastOneMGMForSelected?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >
  /** Disable ability to select system groups */
  allowGroupSelection?: MemberGroupKind[]
  disabledTooltip?: string
  disableMemberGroupIds?: GlobalID[]
  disableAdminOnlyGroups?: boolean
  hideCommunityGroups?: boolean
  filterGroups?: (mgs: MemberGroupData[]) => MemberGroupData[]
  customInputButton?: React.ReactNode
  onMemberGroupsChange?: (memberGroups: MemberGroupData[]) => void
  isOpen?: boolean
  handleClose?: () => void
  customPopper?: React.ComponentType<PopperProps>
  resetOption?: string
}

function MemberGroupsMultiSelect({
  selectedGroupIds,
  setSelectedGroupIds,
  productKey = null,
  organizationKey = null,
  appKey = null,
  disabled,
  requireGroupMembership = false,
  setHasAtleastOneMGMForSelected,
  allowGroupSelection = ["default", "custom", "role"],
  disabledTooltip,
  disableMemberGroupIds = [],
  testid = "MemberGroupsMultiSelect",
  disableAdminOnlyGroups = false,
  hideCommunityGroups = false,
  filterGroups,
  customInputButton,
  onMemberGroupsChange,
  isOpen,
  handleClose,
  customPopper,
  resetOption,
}: MemberGroupsMultiSelectProps) {
  const { admin_member: membersLabel } = useLabels()

  const app = useFragment<MemberGroupsMultiSelect_AppFragment$key>(
    graphql`
      fragment MemberGroupsMultiSelect_AppFragment on ProductApp {
        id
        visibilityGroups {
          edges {
            node {
              id
              ...MemberGroupsMultiSelect_TagFragment @relay(mask: false)
            }
          }
        }
      }
    `,
    appKey
  )

  const product = useFragment<MemberGroupsMultiSelect_ProductFragment$key>(
    graphql`
      fragment MemberGroupsMultiSelect_ProductFragment on Product {
        id
        memberGroups(kind: custom) {
          edges {
            node {
              id
              ...MemberGroupsMultiSelect_TagFragment @relay(mask: false)
            }
          }
        }
      }
    `,
    productKey
  )

  const organization = useFragment<MemberGroupsMultiSelect_OrganizationFragment$key>(
    graphql`
      fragment MemberGroupsMultiSelect_OrganizationFragment on Organization {
        memberGroups(allMemberGroups: true) {
          edges {
            node {
              id
              ...MemberGroupsMultiSelect_TagFragment @relay(mask: false)
            }
          }
        }
      }
    `,
    organizationKey
  )

  const data = app
    ? Relay.connectionToArray(app.visibilityGroups)
    : organization
    ? Relay.connectionToArray(organization.memberGroups)
    : Relay.connectionToArray(product?.memberGroups)
  const mgs = filterGroups ? filterGroups(data) : data
  const memberGroups = generateOptions()
  const memberGroupsById = useMemo(
    () => ArrayUtils.mapBy(memberGroups, "id"),
    [memberGroups]
  )

  useEffect(() => {
    setHasAtleastOneMGMForSelected?.(() =>
      selectedGroupIds.length
        ? memberGroups.some((g) => g.viewerIsMember && selectedGroupIds.includes(g.id))
        : // if there are no groups selected, we can't evaluate whether the user has a membership to any of them so set the state as undefined
          undefined
    )
  }, [memberGroups, selectedGroupIds, setHasAtleastOneMGMForSelected])

  useEffect(() => {
    onMemberGroupsChange?.(memberGroups)
  }, [memberGroups, onMemberGroupsChange])

  const classes = useStyles()

  return (
    <DiscoMultiSelect
      testid={testid}
      placeholder={"Select groups or sub-groups"}
      values={selectedGroupIds}
      options={[
        ...(resetOption && memberGroups.length > 0
          ? [
              {
                value: "reset_groups",
                title: resetOption,
                disabled: false,
              },
            ]
          : []),
        ...memberGroups.map((g) => {
          let isDisabled = disabled
          if (requireGroupMembership) isDisabled = !g.viewerIsMember
          if (!allowGroupSelection.includes(g.kind)) isDisabled = true
          if (disableMemberGroupIds.includes(g.id)) isDisabled = true
          if (disableAdminOnlyGroups && g.visibility === "admin_only") isDisabled = true

          return {
            value: g.id,
            title: renderName(g),
            color: g.color,
            disabled: isDisabled,
          }
        }),
      ]}
      onChange={handleSelect}
      disabled={disabled}
      renderOption={renderOption}
      renderTags={renderTags}
      groupBy={groupBy}
      filterOptions={{ maxVisible: null }}
      customInputButton={customInputButton}
      isOpen={isOpen}
      handleClose={handleClose}
      customPopper={customPopper}
    />
  )

  function groupBy(option: DiscoMultiSelectOption) {
    if (!organization) return ""
    const group = memberGroupsById[option.value]!
    // System groups
    if (group.kind === "default" || group.kind === "role") return "System"
    if (group.product && group.parentMemberGroupId) return "System"
    // Custom groups
    return "Custom"
  }

  function handleSelect(values: string[]) {
    if (values.includes("reset_groups")) {
      setSelectedGroupIds([])
      return
    }
    // If adding the parent group, remove the children
    const newValues = values.filter((v) => {
      const group = memberGroupsById[v]!
      return !values.includes(group.parentMemberGroupId!)
    })
    setSelectedGroupIds(newValues)
  }

  function generateOptions() {
    // If app level, return all groups visible to the app
    if (app) return mgs

    // If product level, return all groups
    if (!organization && product) return mgs

    const options: MemberGroupData[] = []

    // System groups
    const sgs = mgs.filter((mg) => mg.kind === "default" || mg.kind === "role")
    for (const sg of sgs) {
      if (hideCommunityGroups && !sg.product) continue // Skip community level groups within product apps
      if (sg.kind === "default" && !sg.product) continue // Skip the "Everyone" group for the community
      if (product && sg.product && sg.product.id !== product.id) continue // Skip system groups that are not in the product
      options.push(sg) // Add the system group
      const children = Relay.connectionToArray(sg.childrenGroups)
      options.push(...(children as MemberGroupData[])) // Add any children of that group
    }

    // Custom groups
    const cgs = mgs.filter((mg) => mg.kind === "custom")
    for (const cg of cgs) {
      if (hideCommunityGroups && !cg.product) continue // Skip community level groups within product apps
      if (!product && cg.product) continue // Skip product level groups
      options.push(cg) // Add the custom group
      const children = Relay.connectionToArray(cg.childrenGroups)
      options.push(...(children as MemberGroupData[])) // Add any children of that group
    }

    return options
  }

  function renderName(group: MemberGroupData) {
    // If an everyone system group, return `All: ${product.name}`
    if (!group.parentMemberGroupId && group.kind === "default" && group.product)
      return `All: ${group.product.name}`
    // If a custom group, or not the everyone group, return the name
    return group.name
  }

  function renderOption(option: DiscoMultiSelectOption) {
    if (option.value === "reset_groups") {
      const isSelected = selectedGroupIds.length === 0
      return (
        <>
          <DiscoDropdownItem
            testid={`${testid}.option.reset_groups`}
            disabled={option.disabled}
            className={classes.resetOption}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <DiscoText variant={"body-sm-500"}>{"All Members"}</DiscoText>
              {isSelected && (
                <DiscoIcon icon={"iconsax.custom-check"} height={20} width={20} />
              )}
            </div>
          </DiscoDropdownItem>
        </>
      )
    }

    const group = memberGroupsById[option.value]!
    const childGroups = "childrenGroups" in group ? (group.childrenGroups as any) : []

    // If the parent group is already selected, remove from the list
    if (selectedGroupIds.includes(group.parentMemberGroupId!)) return null

    let tooltip
    if (requireGroupMembership && !group.viewerIsMember) {
      tooltip = `Cannot select a group you are not a ${membersLabel.singular} of`
    }
    if (!allowGroupSelection.includes(group.kind) && disabledTooltip) {
      tooltip = disabledTooltip
    }
    if (disableAdminOnlyGroups && group.visibility === "admin_only") {
      tooltip = (
        <>
          <div className={classes.tooltipTitle}>
            <DiscoIcon icon={"eye-off"} height={16} width={16} color={"inherit"} />
            <DiscoText variant={"body-xs-600"}>
              {`You can't select this group.`}
            </DiscoText>
          </div>
          <DiscoText
            variant={"body-xs"}
          >{`This group is only visible to admins. ${membersLabel.plural} don't know if they are added to this group.`}</DiscoText>
        </>
      )
    }

    return (
      <DiscoDropdownItem
        testid={`${testid}.option.${option.title}`}
        tooltip={tooltip}
        tooltipPlacement={"left"}
        disabled={option.disabled}
      >
        {group.parentMemberGroupId && organization && <DiscoIcon icon={"nesting"} />}

        <MemberGroupTag
          memberGroupKey={group}
          showFullDetails
          childGroups={childGroups.totalCount}
        />
      </DiscoDropdownItem>
    )
  }

  function renderTags(
    value: DiscoMultiSelectOption[],
    getTagProps: AutocompleteGetTagProps
  ) {
    const groups = value
      .map((v) => {
        const group = memberGroupsById[v.value]
        if (!group) return null
        return {
          id: group.id,
          name: v.title,
          childGroups:
            "childrenGroups" in group && group.childrenGroups
              ? group.childrenGroups.totalCount
              : 0,
        }
      })
      .filter((group): group is NonNullable<typeof group> => group !== null)

    return groups.map((mg, index) => (
      <MemberGroupTag
        key={mg.id}
        {...getTagProps({ index })}
        memberGroupKey={memberGroupsById[mg.id]!}
        testid={`${testid}.option.remove.${mg.name}`}
        showFullDetails
        childGroups={mg.childGroups}
      />
    ))
  }
}

const useStyles = makeUseStyles((theme) => ({
  tooltipTitle: {
    display: "flex",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  resetOption: {
    margin: theme.spacing(0.5, 0, 0.5, 0),
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}))

export default MemberGroupsMultiSelect

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment MemberGroupsMultiSelect_TagFragment on MemberGroup {
    id
    name
    color
    viewerIsMember
    kind
    parentMemberGroupId
    visibility
    product {
      id
      name
      type
    }
    ...MemberGroupTagFragment
    childrenGroups {
      totalCount
      edges {
        node {
          id
          name
          color
          viewerIsMember
          kind
          parentMemberGroupId
          visibility
          product {
            id
            name
          }
          ...MemberGroupTagFragment
        }
      }
    }
  }
`
