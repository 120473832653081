import FormStore from "@/core/form/store/FormStore"
import GroupFormTable, {
  GroupFormTableSkeleton,
} from "@/product/common/member-group/common/GroupFormTable"

import { AdminMemberGroupTableQuery } from "@/product/common/member-group/common/__generated__/AdminMemberGroupTableQuery.graphql"
import { CreateMemberGroupFormState } from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import { EditMemberGroupFormState } from "@/product/common/member-group/util/MemberGroupUtils"
import Relay from "@/relay/relayUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

interface AdminMemberGroupTableProps {
  form: FormStore<CreateMemberGroupFormState> | FormStore<EditMemberGroupFormState>
}

function AdminMemberGroupTable({ form }: AdminMemberGroupTableProps) {
  const { memberGroup } = useLazyLoadQuery<AdminMemberGroupTableQuery>(
    graphql`
      query AdminMemberGroupTableQuery($id: ID!) {
        memberGroup: node(id: $id) {
          ... on MemberGroup {
            memberGroupMemberships {
              edges {
                node {
                  id
                  userId
                  productMembershipId
                  user {
                    id
                    fullName
                    ...UserDropdownItemFragment
                  }
                  organizationMembership {
                    id
                    email
                  }
                  ...AdminMemberGroupTableRowFragment
                }
              }
            }
          }
        }
      }
    `,
    { id: form.state.memberGroupId || "" }
  )
  return <GroupFormTable form={form} memberGroup={memberGroup} />
}

export default Relay.withSkeleton<AdminMemberGroupTableProps>({
  component: AdminMemberGroupTable,
  skeleton: GroupFormTableSkeleton,
})
