/**
 * @generated SignedSource<<40a6418bd48a0a8b2c3a77cb3d95defe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseGroupsListItemFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly color: string;
  readonly " $fragmentSpreads": FragmentRefs<"CourseGroupsListItemAvatarStackFragment" | "MemberGroupMoreActionsDropdownFragment" | "MemberGroupTagFragment">;
  readonly " $fragmentType": "CourseGroupsListItemFragment";
};
export type CourseGroupsListItemFragment$key = {
  readonly " $data"?: CourseGroupsListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseGroupsListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseGroupsListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseGroupsListItemAvatarStackFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberGroupMoreActionsDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberGroupTagFragment"
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};

(node as any).hash = "76679120b22e350005baadecf94b5668";

export default node;
