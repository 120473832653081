/**
 * @generated SignedSource<<5e7a58a489ad485ed1cb038ca8735151>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type pricingUtils_usePricingDisplayValue$data = {
  readonly frequency: PricingFrequency | null;
  readonly kind: PricingKind;
  readonly amountCents: number;
  readonly " $fragmentType": "pricingUtils_usePricingDisplayValue";
};
export type pricingUtils_usePricingDisplayValue$key = {
  readonly " $data"?: pricingUtils_usePricingDisplayValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pricingUtils_usePricingDisplayValue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountCents",
      "storageKey": null
    }
  ],
  "type": "Pricing",
  "abstractKey": null
};

(node as any).hash = "5fb1792c6053d1b4723ae8f63646ba6d";

export default node;
