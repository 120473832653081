import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { CheckoutFormOrganizationFormSection_Checkout$key } from "@/product/checkout/form/organization-form/__generated__/CheckoutFormOrganizationFormSection_Checkout.graphql"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoCheckbox, DiscoFormControl } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props {
  store: CheckoutStore
}

const CheckoutFormOrganizationFormSection = observer<Props>(({ store }) => {
  const classes = useStyles()
  const theme = useTheme()

  const checkout = useFragment<CheckoutFormOrganizationFormSection_Checkout$key>(
    graphql`
      fragment CheckoutFormOrganizationFormSection_Checkout on Checkout {
        organizationForm {
          questions {
            id
            richEditorBody
          }
        }
      }
    `,
    toJS(store.checkout || null)
  )

  if (!checkout?.organizationForm) return null

  return (
    <>
      {checkout.organizationForm.questions.map((question, i) => (
        <DiscoFormControl key={question.id} marginBottom={2}>
          <DiscoCheckbox
            classes={{ label: classes.checkboxLabel }}
            data-testid={`CheckoutFormOrganizationFormSection.checkbox-${i}`}
            label={
              <DiscoEditor
                defaultValue={question.richEditorBody}
                readOnly
                disableGutter
                textColor={theme.palette.text.secondary}
              />
            }
            checked={Boolean(store.organizationFormAnswers[i])}
            onChange={(checked) =>
              (store.organizationFormAnswers[i] = checked ? "1" : "")
            }
          />
        </DiscoFormControl>
      ))}
    </>
  )
})

const useStyles = makeUseStyles({
  checkboxLabel: {
    width: "100%",
  },
})

export default CheckoutFormOrganizationFormSection
