import { ProductTypeTagFragment$key } from "@/product/common/type-tag/__generated__/ProductTypeTagFragment.graphql"
import { Spacing } from "@assets/style/appMuiTheme"
import DiscoTag, { DiscoTagProps } from "@disco-ui/tag/DiscoTag"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  testid?: string
  productKey: ProductTypeTagFragment$key
  /** DiscoTag props */
  tagProps?: Pick<DiscoTagProps, keyof Spacing | "className">
}

export default function ProductTypeTag(props: Props) {
  const { productKey, testid, tagProps } = props

  const product = useFragment<ProductTypeTagFragment$key>(
    graphql`
      fragment ProductTypeTagFragment on Product {
        typeTag {
          label
          color
        }
      }
    `,
    productKey
  )

  if (!product.typeTag) return null

  return (
    <DiscoTag
      {...tagProps}
      name={product.typeTag.label}
      backgroundColor={product.typeTag.color}
      testid={testid}
    />
  )
}
