import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MemberGroupTag, {
  MemberGroupTagSkeleton,
} from "@/product/common/member-group/common/tag/MemberGroupTag"
import { MemberGroupEventTagListFragment$key } from "@/product/common/member-group/common/tag/__generated__/MemberGroupEventTagListFragment.graphql"
import Relay from "@/relay/relayUtils"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import classNames from "classnames"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface MemberGroupEventTagListProps {
  eventKey: MemberGroupEventTagListFragment$key
  hideDefault?: boolean
  testid?: string
  className?: string
  hideAfter?: number
}

function MemberGroupEventTagList({
  eventKey,
  hideDefault,
  testid,
  hideAfter = 3,
  className: customClassName,
}: MemberGroupEventTagListProps) {
  const event = useFragment<MemberGroupEventTagListFragment$key>(
    graphql`
      fragment MemberGroupEventTagListFragment on Event {
        id
        product {
          type
        }
        memberGroups {
          edges {
            node {
              id
              kind
              parentMemberGroupId
              name
              product {
                name
              }
              ...MemberGroupTagFragment
            }
          }
        }
      }
    `,
    eventKey
  )

  const classes = useStyles()

  if (!event) return null

  let memberGroups = Relay.connectionToArray(event.memberGroups)

  if (hideDefault && event.product.type === "course") {
    memberGroups = memberGroups.filter((mg) => mg.kind === "custom")
  }

  const tags = memberGroups.map((mg, index) => (
    <li key={mg.id}>
      <MemberGroupTag
        memberGroupKey={mg}
        testid={`${testid || "MemberGroupEventTagList"}.${index}`}
      />
    </li>
  ))

  return (
    <ul className={classNames(classes.listContainer, customClassName)}>
      {tags && tags.length <= hideAfter + 1 ? (
        tags
      ) : (
        <>
          {tags.slice(0, hideAfter)}
          <li>
            <DiscoTag name={`+ ${tags.length - hideAfter} more`} testid={testid} />
          </li>
        </>
      )}
    </ul>
  )
}

const useStyles = makeUseStyles((theme) => ({
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    overflow: "hidden",
    margin: theme.spacing(1, 3),
    "& > li": {
      listStyle: "none",
    },
  },
}))

const MemberGroupEventTagListSkeleton: React.FC = () => {
  const classes = useStyles()

  return (
    <ul className={classes.listContainer}>
      <MemberGroupTagSkeleton />
    </ul>
  )
}

export default Relay.withSkeleton<MemberGroupEventTagListProps>({
  component: MemberGroupEventTagList,
  skeleton: MemberGroupEventTagListSkeleton,
})
