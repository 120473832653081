import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  CloneCourseEvent,
  useActiveCloneProductModalContext,
} from "@/product/clone/sequence/CloneProductModalContext"
import TimeDropdown, { TimeDropdownOption } from "@components/dropdown/time/TimeDropdown"
import { generateTimeDropdownOptionFromStartTimeDate } from "@components/dropdown/time/util/timeDropdownUtils"
import TimezoneDropdown, {
  TimezoneDropdownOption,
} from "@components/dropdown/timezone/TimezoneDropdown"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoFormControl, DiscoModal, DiscoText } from "@disco-ui"
import DiscoDatePicker, { DateDetails } from "@disco-ui/date/DiscoDatePicker"
import DiscoTimeInput from "@disco-ui/date/DiscoTimeInput"
import { Grid } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { isBefore } from "date-fns"
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"

function CloneProductEditEventDetails() {
  const { form } = useActiveCloneProductModalContext()!
  const [eventState, setEventState] = useState<CloneCourseEvent | null>(null)

  const classes = useStyles()

  useEffect(() => {
    if (!form.state.events) return
    const sourceEvent = form.state.events.find(
      ({ occurrenceId }) => form.state.editOccurrenceSourceId === occurrenceId
    )
    setEventState(sourceEvent || null)
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.editOccurrenceSourceId])

  if (!eventState) return null

  const selectedTimezoneSubtitle = formatDateWithOptions({
    timeZone: eventState.timezone,
    format: DATE_FORMAT.UTC_OFFSET,
  })(new Date())

  return (
    <DiscoModal
      isOpen={Boolean(form.state.editOccurrenceSourceId)}
      title={"Edit Event Details"}
      width={"550px"}
      height={"650px"}
      subtitle={
        <>
          <DiscoText display={"inline"}>{"Changing date and time for "}</DiscoText>
          <DiscoText display={"inline"} variant={"body-md-700"}>
            {eventState.name}
          </DiscoText>
        </>
      }
      testid={"CloneProductEditEventDetails"}
      modalContentLabel={"Edit Event Details"}
      onClose={handleClose}
      body={
        <>
          <DiscoFormControl label={"Date"} className={classes.dateSelect}>
            <DiscoDatePicker
              testid={"CloneProductEventDetails.start-date-select"}
              onChange={handleDateChange}
              value={utcToZonedTime(eventState.startDatetime, eventState.timezone)}
              minDate={new Date()}
            />
          </DiscoFormControl>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DiscoFormControl label={"Start Time"}>
                <DiscoTimeInput
                  testid={"CloneProductEventDetails.start-time-dropdown"}
                  value={utcToZonedTime(eventState.startDatetime, eventState.timezone)}
                  onChange={handleStartTimeChange}
                />
              </DiscoFormControl>
            </Grid>
            <Grid item xs={6}>
              <DiscoFormControl label={"End Time"}>
                <TimeDropdown
                  testid={"CloneProductEventDetail.end-time-dropdown"}
                  selectedOption={generateTimeDropdownOptionFromStartTimeDate(
                    utcToZonedTime(eventState.startDatetime, eventState.timezone),
                    eventState.duration
                  )}
                  startTimeString={format(
                    utcToZonedTime(eventState.startDatetime, eventState.timezone),
                    DATE_FORMAT.LONG_TIME_FORMAT
                  )}
                  onSelect={handleEndTimeChange}
                />
              </DiscoFormControl>
            </Grid>
          </Grid>

          <DiscoFormControl label={"Timezone"}>
            <TimezoneDropdown
              selectedOption={{
                value: eventState.timezone,
                title: eventState.timezone,
                context: {
                  subtitle: selectedTimezoneSubtitle,
                },
              }}
              onSelect={handleTimezoneChange}
              testid={"CloneProductEditEventDetails.timezone-dropdown"}
            />
          </DiscoFormControl>
        </>
      }
      buttons={
        <>
          <DiscoButton onClick={handleClose} color={"grey"} variant={"outlined"}>
            {"Cancel"}
          </DiscoButton>
          <DiscoButton onClick={handleSubmit}>{"Save Changes"}</DiscoButton>
        </>
      }
    />
  )

  /**
   * accepts a Date that will be formatted as yyyy-MM-dd
   * see {@link DATE_FORMAT.API_FORMAT}
   */
  function handleDateChange(details: DateDetails) {
    if (!details.value || !eventState) return
    const newDate = details.formatted["yyyy-MM-dd"]
    const [_, time] = eventState.startDatetime.split("T")
    setEventState((prev) => ({
      ...prev!,
      startDatetime: new Date(`${newDate}T${time}`).toISOString(),
    }))
  }

  /** expects time arg in format 01:00:00
   * see {@link DATE_FORMAT.API_TIME_FORMAT}
   */
  function handleStartTimeChange(time: string) {
    if (!time || !eventState) return
    const [date] = eventState.startDatetime.split("T")

    setEventState((prev) => ({
      ...prev!,
      startDatetime: zonedTimeToUtc(`${date}T${time}`, prev!.timezone).toISOString(),
    }))
  }

  /** expects difference in minutes */
  function handleEndTimeChange(endTime: TimeDropdownOption | null) {
    if (!endTime?.context) return
    setEventState((prev) => ({
      ...prev!,
      duration: endTime!.context!.differenceInMinutes || 0,
    }))
  }

  /** convert ISO datetime to new timezone offset */
  function handleTimezoneChange(timezone: TimezoneDropdownOption | null) {
    if (!timezone?.value) return

    setEventState((prev) => ({
      ...prev!,
      startDatetime: new Date(
        format(
          utcToZonedTime(prev!.startDatetime, prev!.timezone),
          `${DATE_FORMAT.API_FORMAT} ${DATE_FORMAT.API_TIME_FORMAT}`,
          { timeZone: timezone.value }
        )
      ).toISOString(),
      timezone: timezone.value,
    }))
  }

  function handleSubmit() {
    const editIndex = form.state.events?.findIndex(
      ({ occurrenceId }) => form.state.editOccurrenceSourceId === occurrenceId
    )
    if (editIndex === undefined || !eventState) return
    if (isBefore(new Date(eventState.startDatetime), new Date())) {
      form.addError({ field: "*", message: "This event cannot be created in the past." })
      return
    }
    form.state.events!.splice(editIndex, 1, eventState)
    displaySuccessToast({
      message: `${eventState.name} has been updated.`,
      testid: "CloneProductEditEventDetails",
    })
    handleClose()
  }

  function handleClose() {
    form.state.editOccurrenceSourceId = undefined
  }
}

const useStyles = makeUseStyles((theme) => ({
  dateSelect: {
    marginTop: theme.spacing(2.5),
  },
}))

export default observer(CloneProductEditEventDetails)
