import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Format from "@/core/format/format"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Discount from "@/discount/Discount"
import { calculateNextBillingDate, usePricingDisplayValue } from "@/pricing/pricingUtils"
import { PaymentFormDiscountSection_Discount$key } from "@/product/checkout/form/discount/__generated__/PaymentFormDiscountSection_Discount.graphql"
import { PaymentFormDiscountSection_Pricing$key } from "@/product/checkout/form/discount/__generated__/PaymentFormDiscountSection_Pricing.graphql"
import CheckoutFormDiscountInput from "@/product/checkout/form/payment/CheckoutFormDiscountInput"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import { DiscoText } from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { Grid, useTheme } from "@material-ui/core"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { FC } from "react"
import { graphql, useFragment } from "react-relay"

// Only require store if showInput props is provided
type Props = {
  basePrice: number
  finalPrice: number
  testid: string
  discountKey: PaymentFormDiscountSection_Discount$key | null
  pricingKey?: PaymentFormDiscountSection_Pricing$key | null
} & (
  | { showInput?: undefined; store?: never }
  | { showInput: boolean; store: CheckoutStore }
)

const PaymentFormDiscountSection: FC<Props> = ({
  testid,
  basePrice,
  finalPrice,
  discountKey,
  pricingKey,
  showInput,
  store,
}) => {
  const discount = useFragment<PaymentFormDiscountSection_Discount$key>(
    graphql`
      fragment PaymentFormDiscountSection_Discount on Discount {
        ...Discount_displayValue
      }
    `,
    discountKey
  )

  const pricing = useFragment<PaymentFormDiscountSection_Pricing$key>(
    graphql`
      fragment PaymentFormDiscountSection_Pricing on Pricing {
        frequency
        ...pricingUtils_usePricingDisplayValue
      }
    `,
    pricingKey || null
  )

  const { currency } = useActiveOrganization()!
  const classes = useStyles()
  const theme = useTheme()

  const hasDiscount = Boolean(discount)
  const discountDisplayValue = Discount.useDisplayValue(discount, currency)

  const pricingValue = usePricingDisplayValue({
    pricingKey: pricing,
    currency,
    length: "short",
  })
  const nextBillingDate = pricing ? calculateNextBillingDate(pricing?.frequency) : null

  const total = Format.asCurrency(finalPrice, {
    currency,
  })

  return (
    <>
      {/* Original price when discounted */}
      {hasDiscount && (
        <DiscoText
          variant={"body-lg-600"}
          aria-label={"Original Price"}
          className={classes.originalPrice}
          data-testid={`${testid}.base-price`}
        >
          {Format.asCurrency(basePrice, {
            currency,
          })}
        </DiscoText>
      )}

      <Grid container spacing={2} justify={"space-between"}>
        {/* Final price */}
        <Grid item xs={"auto"}>
          <Grid container className={classes.finalPriceContainer}>
            <DiscoText
              variant={"heading-md"}
              color={"primary.main"}
              aria-label={"Final Purchase Price"}
              data-testid={`${testid}.final-price`}
            >
              {pricing?.frequency ? `Total Today: ${pricingValue}` : `Total: ${total}`}
            </DiscoText>
          </Grid>
        </Grid>
        {/* Discount value label */}
        {hasDiscount && (
          <Grid item xs={"auto"}>
            <DiscoTag
              name={`${discountDisplayValue} Discount`}
              color={theme.palette.primary.main}
              backgroundColor={theme.palette.primary.light}
            />
          </Grid>
        )}

        {/* Next payment if applicable */}
        {nextBillingDate && (
          <Grid item xs={"auto"}>
            <DiscoText
              color={"text.secondary"}
              display={"inline"}
            >{`Your next payment is on `}</DiscoText>
            <DiscoText
              color={"text.secondary"}
              display={"inline"}
              variant={"body-md-700"}
            >
              {formatDateWithOptions()(nextBillingDate)}
            </DiscoText>
            <DiscoText
              color={"text.secondary"}
              display={"inline"}
            >{` for a total of `}</DiscoText>
            <DiscoText
              color={"text.secondary"}
              display={"inline"}
              variant={"body-md-700"}
            >
              {total}
            </DiscoText>
          </Grid>
        )}

        {showInput && store && (
          <Grid item xs={12}>
            <CheckoutFormDiscountInput store={store} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  originalPrice: {
    color: theme.palette.groovy.grey[300],
    textDecoration: "line-through",
  },
  finalPriceContainer: {
    height: "100%",
    alignItems: "center",
  },
}))

export default PaymentFormDiscountSection
