import { EditMemberGroupButtonFragment$key } from "@/product/common/member-group/common/edit/button/__generated__/EditMemberGroupButtonFragment.graphql"
import EditMemberGroupForm from "@/product/common/member-group/common/edit/form/EditMemberGroupForm"
import EditSubGroupForm from "@/product/common/member-group/common/edit/form/EditSubGroupForm"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import React, { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  groupKey: EditMemberGroupButtonFragment$key
  children: OverridableDiscoButtonChildren
  isSubGroup?: boolean
}

const EditMemberGroupButton: React.FC<Props> = (props) => {
  const { children, groupKey, isSubGroup = false } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const memberGroup = useFragment<EditMemberGroupButtonFragment$key>(
    graphql`
      fragment EditMemberGroupButtonFragment on MemberGroup {
        id
      }
    `,
    groupKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>
      <DiscoModal
        isOpen={isModalOpen}
        onClose={closeModal}
        width={"720px"}
        testid={"EditMemberGroupButtonModal"}
        modalContentLabel={"EditMemberGroupButtonModal"}
        title={"Edit Group"}
        buttons
        body={
          isSubGroup ? (
            <EditSubGroupForm
              groupId={memberGroup.id}
              onCancel={closeModal}
              onSave={closeModal}
            />
          ) : (
            <EditMemberGroupForm
              groupId={memberGroup.id}
              onCancel={closeModal}
              onSave={closeModal}
            />
          )
        }
      />
    </>
  )

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

export default EditMemberGroupButton
